import { mdiCalendarClock, mdiChartMultiline, mdiCashUsdOutline, mdiTransitConnectionVariant, mdiFinance} from '@mdi/js'; 
import { getCachedCategories } from '../../app_gateway/category/CategoryAG';

const category = {
    aktuelles: {
        icon: mdiCalendarClock,
        color: '#537994',
        img: "assets/layout/images/aktuelles.png"
    },
    programmumsetzung: {
        icon: mdiTransitConnectionVariant,
        color: '#c36131',
        img: "assets/layout/images/programmumsetzung.png"
    },
    wirtschaftsaktivitaet_und_aktuelle_konjunkturindikatoren: {
        icon: mdiChartMultiline,
        color: '#d4a811',
        img: "assets/layout/images/wirtschaftsaktivitaet.png"
    },
    finanzmaerkte: {
        icon: mdiFinance,
        color: '#a03a69',
        img: "assets/layout/images/finanzmaerkte.png"
    },
    preise: {
        icon: mdiCashUsdOutline,
        color: '#429757',
        img: "assets/layout/images/preise.png"
    },
    destatis_statistiken: {
        icon: mdiCashUsdOutline,
        color: '#429757',
        img: "assets/layout/images/preise.png"
    },
    DEFAULT: {
        color: '#537994'
    }
}


export default class UIConstants{

    static getCategory(cat) {
        return getCachedCategories().then((cats) => {
            return cats.filter(c => c.name === cat)[0];
        })
        /*if(cat !== undefined && cat !== null)
        {
            cat = cat.toLowerCase();
            cat = cat.replace(new RegExp(' ', 'gi'), "_").replace(new RegExp('ä', 'gi'), "ae").replace(new RegExp('ö', 'gi'), "oe").replace(new RegExp('ü', 'gi'), "ue");
            if(category[cat])
                return category[cat];
        }*/
    }

    static getAllCategories() {
        return category;
    }
};