import { ProgressSpinner } from 'primereact/progressspinner'
import React from 'react'
import Icon from './Icon'

export default function IconButton(props) {
    return (
        <button aria-label={props.title} disabled={props.disabled} {...props} className={"p-link icon-button " + props.className + (props.disabled ? " disabled" : "")} onClick={props.onClick}>
            {props.textleft ? props.textleft : null}
            {(props.icon || props.mdi) &&< Icon style={props.style} className={props.className} icon={props.icon} mdi={props.mdi} />}
            {props.textright ? props.textright : null}
            {props.children}
            {props.loading && <div className="icon-button-loading"><ProgressSpinner className="progress-spinner-anim-disabled" style={{width: '25px', height: '25px'}} strokeWidth="5" animationDuration="1.5s"/></div>}
        </button>
    )
}
