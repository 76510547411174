import React, { useEffect, useState } from "react";
import { getUser } from "../../../app_gateway/authentication/AuthenticationStore";
import IconButton from "../../util/IconButton";
import { mdiBell, mdiBellOff } from "@mdi/js";
import {
  setEnableNotification,
  setDisableNotification,
  getNotifications,
} from "../../../app_gateway/notification/NotificationAG";
import { ProgressSpinner } from "primereact/progressspinner";
import Tooltip from "../../util/Tooltip";

export default function Notification(props) {
  const [enabled, setEnabled] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkEnabled();
  }, []);

  const checkEnabled = () => {
    let user = getUser();
    if (user !== undefined && user !== null) {
      setShow(true);
      getNotifications(getUser().mail, props.tileid).then(
        (notificationTiles) => {
          if (notificationTiles) {
            try {
              setEnabled(notificationTiles.includes(props.tileid));
            } catch (e) {}
          }
        }
      );
    }
  };

  const enableNotification = () => {
    setLoading(true);
    setTimeout(() => {
      setEnableNotification(getUser().mail, props.tileid).then((user) => {
        setLoading(false);
        checkEnabled();
      });
    }, 300);
  };

  const disableNotification = () => {
    setLoading(true);
    setTimeout(() => {
      setDisableNotification(getUser().mail, props.tileid).then((user) => {
        setLoading(false);
        checkEnabled();
      });
    }, 300);
  };

  return (
    <Tooltip
      onHover={true}
      disable={loading}
      bottom
      auto
      delay={700}
      label={
        enabled
          ? "Benachrichtungen ausschalten"
          : "Benachrichtigung einschalten"
      }
    >
      {show ? (
        loading ? (
          <ProgressSpinner
            className="progress-spinner-anim-disabled"
            style={{ width: "25px", height: "25px" }}
            strokeWidth="5"
            animationDuration="1.5s"
          />
        ) : enabled ? (
          <IconButton
            title={
              enabled
                ? "Benachrichtigungen ausschalten"
                : "Benachrichtigung einschalten"
            }
            style={{ color: "white" }}
            mdi={{ path: mdiBell, size: "23px" }}
            onClick={() => disableNotification()}
          />
        ) : (
          <IconButton
            title={
              enabled
                ? "Benachrichtigungen ausschalten"
                : "Benachrichtigung einschalten"
            }
            style={{ color: "white", opacity: "0.8" }}
            mdi={{ path: mdiBellOff, size: "23px" }}
            onClick={() => enableNotification()}
          />
        )
      ) : null}
    </Tooltip>
  );
}
