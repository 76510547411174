import React from 'react'
import IconButton from '../util/IconButton'
import Icon from '../util/Icon'

export default function PDFToolbar(props) {
    
    const { page, pages, file, scale} = props

    const { handlePrevClick, handleNextClick, handleZoomIn, handleZoomOut} = props

    return (
        <div className="pdf-toolbar">
            <div className="pdf-toolbar-left">
                <IconButton onClick={handleZoomOut} icon="zoom_out" disabled={scale <= 1} />
                <IconButton onClick={handleZoomIn} icon="zoom_in" disabled={scale > 2} />
            </div>
            <div className="pdf-toolbar-center">
                <IconButton onClick={handlePrevClick} icon="keyboard_arrow_left" disabled={page <= 1} />
                <span>{"Seite " + page + " / " + pages}</span>
                <IconButton onClick={handleNextClick} icon="keyboard_arrow_right" disabled={page >= pages} />
            </div>
            <div className="pdf-toolbar-right">
                <a href={file.content} download={file.name}>
                    <Icon icon="cloud_download" />
                </a>
            </div>
        </div>
    )
}
