import { mdiContentSave, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import React, {useEffect, useState} from 'react'

import '../../styles/dashboardtoolbar.css';
import IconButton from '../util/IconButton';
import SwitchButton from '../util/SwitchButton';

const iconSingleColumn = "m 4 6 v 2 h 16 v -2 z m 0 5 v 2 h 16 v -2 z m 0 5 v 2 h 16 v -2 z ";
const iconMultiColumn = "m 4 6 v 2 h 7 v -2 z m 0 5 v 2 h 7 v -2 z m 0 5 v 2 h 7 v -2 z m 9 -10 v 2 h 7 v -2 z m 0 5 v 2 h 7 v -2 z m 0 5 v 2 h 7 v -2 z";

export default function PrintToolbar(props) {

    const [hideAllTexts, setHideAllTexts] = useState(false);

    useEffect(() => {
        setHideAllTexts(props.hideAllTexts);
    }, [props.hideAllTexts])

    const commonWidth = (a, b) => {
        return props.commonWidth === 1 ? a : b
    }

    const isHided = (a, b) => {
        return hideAllTexts ? a : b;
    }
 
    return (
        <div className="dashboard-toolbar">
            <div className="dashboard-toolbar-left">
                {props.onGoNext ? <IconButton disabled={props.onPrintCancel} loading={props.goNextLoading} title="Weiter zur Vorschau" icon="keyboard_arrow_right" textleft="Weiter zur Vorschau" onClick={() => {props.onGoNext()}} disabled={props.onGoNextDisabled} /> : null}
                {props.onSort ? <IconButton disabled={props.onPrintCancel} title={props.onSort.label} icon="sort" textright={props.onSort.label} onClick={() => {props.onSort.onClick()}} /> : null}
                {props.onSetCommonWidth ? <IconButton
                    disabled={props.onPrintCancel}
                    title={commonWidth("Einspaltiges Layout", "Zweispaltiges Layout")}
                    mdi={{path: commonWidth(iconSingleColumn, iconMultiColumn), size:'20px'}}
                    textright={commonWidth("Einspaltiges Layout", "Zweispaltiges Layout")}
                    onClick={() => {props.onSetCommonWidth(commonWidth(2, 1))}}
                /> : null}
                {props.onHideAllTexts ? <IconButton disabled={props.onPrintCancel} title={isHided("Alle Beigleittexte anzeigen", "Alle Beigleittexte ausblenden")} mdi={{path: isHided(mdiEyeOutline, mdiEyeOffOutline), size:'20px'}} textright={isHided("Alle Beigleittexte anzeigen", "Alle Beigleittexte ausblenden")} onClick={() => {props.onHideAllTexts(!hideAllTexts);}} /> : null}
                {props.onPageLayout ? <SwitchButton disabled={props.onPrintCancel} title={props.pageLayout ? "Einfaches Seitenlayout auswählen: Ein Seitenlayout mit vereinfachten Seitenumbruch. Dieser wird automatisch an den Größen der Indikatoren ermittelt.": "Flexibles Seitenlayout auswählen: Ein Seitenlayout zur flexiblen Anordnung mit Seitenumbruch."} icon="insert_drive_file" textright={"Seitenlayout anpassen"} checked={props.pageLayout} onClick={() => {props.onPageLayout(!props.pageLayout)}} /> : null}
                {props.onSaveLayout ? <IconButton disabled={props.onPrintCancel} title={"Drucklayout speichern"} mdi={{path: mdiContentSave, size:'20px'}} textright={"Drucklayout speichern"} onClick={() => props.onSaveLayout()} /> : null}
            </div>
            <div className="dashboard-toolbar-center">
            </div>
            <div className="dashboard-toolbar-right">
                {props.onPrint ? <IconButton disabled={props.printDisabled} title={"Drucken"} icon="print" textright="Drucken" onClick={() => {props.onPrint()}} /> : null}
                {props.onPrintCancel ? <IconButton title={"Druckvorgang abbrechen"} icon="cancel" textright="Druckvorgang abbrechen" onClick={() => {props.onPrintCancel()}} /> : null}
            </div>
      </div>
    )
}
