import React, {useState} from 'react';
import Icon from '../../util/Icon';
import { mdiDotsHorizontal } from '@mdi/js';
import { l } from '../Main';

export default function CategoryExtensionButton(props) {

    const [hover, setHover] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [show, setShow] = useState(false);
    
    return (
        <div aria-label={l("Weitere Kategorien")}  className="sidebar-button" onFocus={e =>  setHover(true)} onBlur={e =>  setHover(false)}  onMouseEnter={(e) => setHover(true)} onMouseLeave={() => setHover(false)}>
            <button className={"p-link extension-button category-button "+(hover || show ? "hover" : "")} onClick={() => {
                if(hover) {
                    setHover(false); 
                    if(!props.disabled)
                        props.onClick()
                }
                else {
                    props.onShow(show ? "" : props.icon);
                }
                }}>
                <div className="extension-button-icon"><Icon mdi={{path: mdiDotsHorizontal , size: '30px'}} style={{color: 'white'}} /></div>
            </button>
            <div className={"hover-menu "+(hover || show ? "" : "hidden ") + (props.disabled? 'hover-menu-disabled ' : '') + (props.mobileMenuActive? 'mobile-menu-active ' : '')} onClick={() => {
                setHover(false); 
                if(!props.disabled)
                {
                    props.onClick()
                }}}>
                <div className="hover-menu-title top">{l("Weitere Kategorien")}</div>
            </div>
        </div>
    )
}
