import React, { useState } from 'react'
import IconButton from '../util/IconButton'
import Calendar from 'react-calendar';

export default function DashboardToolbarCalendarButton(props) {

    const [calendarVisible, setCalendarVisible] = useState(false)

    return (
        <IconButton style={{backgroundColor: (props.active ? props.color : "")}} textright={props.textright} className={"dashboard-toolbar-toggle dashboard-toolbar-calendar-button" + (props.active ? " dashboard-toolbar-toggle-active" : "")} onClick={() => {setCalendarVisible(true); props.onClick(props.buttonid)}}>
            <Calendar maxDate={new Date()} className={"fadeInDown dashboard-toolbar-calendar " + (!props.active || !calendarVisible ? " dashboard-toolbar-calendar-hidden" : "")} calendarType="ISO 8601" value={props.date} onChange={(e) => {setCalendarVisible(false); props.setDate(e)}}></Calendar>
        </IconButton>
    )
}
