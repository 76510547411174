import React, { Component } from 'react'

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import '../../styles/confirmdialog/confirmdialog.css'

const DEFAULT_TITLE = 'Diese Aktion durchführen';
const DEFAULT_TEXT = 'Möchten Sie diese Aktion wirklich durchführen?';

export default class ConfirmDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            title: DEFAULT_TITLE,
            text: DEFAULT_TEXT,
            onConfirm: e => { },
            onCancel: e => { },
            footer: <div></div>,
            icon: <div className="icon warning"></div>
        }

        this.onHide = this.onHide.bind(this);
        this.show = this.show.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.getYesNoButtons = this.getYesNoButtons.bind(this);
        this.getOkButtons = this.getOkButtons.bind(this);
    }

    onHide() {
        this.setState({ visible: false });
    }

    show() {
        this.setState({ visible: true });
    }



    showOk(title, text, onConfirm) {
        this.setState({
            title: title === null || title === undefined ? DEFAULT_TITLE : title,
            text: text === null || text === undefined ? DEFAULT_TEXT : text,
            footer: this.getOkButtons(),
            visible: true,
            onConfirm: onConfirm,
            content: false,
            icon: <div className="icon warning"></div>
        });
    }

    showOkWithContent(title, text, onConfirm) {
        this.setState({
            title: title === null || title === undefined ? DEFAULT_TITLE : title,
            text: text === null || text === undefined ? DEFAULT_TEXT : text,
            footer: this.getOkButtons(),
            visible: true,
            content: true,
            onConfirm: onConfirm,
            icon: <div className="icon warning"></div>
        });
    }

    showYesNo(title, text, onConfirm, onCancel) {
        this.setState({
            title: title === null || title === undefined ? DEFAULT_TITLE : title,
            text: text === null || text === undefined ? DEFAULT_TEXT : text,
            footer: this.getYesNoButtons(),
            visible: true,
            onConfirm: onConfirm,
            content: false,
            onCancel: onCancel,
            icon: <div className="icon question"></div>
        });
    }


    onConfirm() {
        this.onHide();
        if (this.state.onConfirm !== undefined && this.state.onConfirm != null)
            this.state.onConfirm();
    }


    onCancel() {
        this.onHide();
        if (this.state.onCancel !== undefined && this.state.onCancel != null)
            this.state.onCancel();
    }

    getYesNoButtons() {
        return (
            <div>
                <Button label="Bestätigen" icon="pi pi-check" onClick={this.onConfirm} />
                <Button label="Abbrechen" icon="pi pi-times" onClick={this.onCancel} />
            </div>
        )
    }

    getOkButtons() {
        return (
            <div>
                <Button label="Ok" icon="pi pi-check" onClick={this.onConfirm} />
            </div>
        )
    }

    render() {

        const footer = this.state.footer;

        return (
            <div>
                <div className={"dialog-overlay " + (this.state.visible ? "" : "hidden")}></div>
                <Dialog header={this.state.title} footer={footer} visible={this.state.visible} style={{ width: '50vw', zIndex: this.state.visible ? "999" : "-999" }} modal={false} onHide={this.onCancel}>
                    <div className="p-grid" >
                        <div className="p-col-fixed icon-container">
                            {this.state.icon}
                        </div>
                        <div className="p-col dialog-text">
                            {this.state.text}
                            {this.state.content ? <div className="dialog-content">{this.props.content}</div> : null}
                            
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}
