import React, {useEffect, useRef, useState} from 'react'
import CardHeaderMenuEntry from './CardHeaderMenuEntry';

export default function CardHeaderMenu(props) {

    const wrapperRef = useRef(null);

    const [showMenu, setShowMenu] = useState(false);

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    useEffect(() => {
      if(showMenu)
        document.addEventListener("mousedown", handleClickOutside);
      else
        document.removeEventListener("mousedown", handleClickOutside);

    }, [showMenu])

    return (
        <div className="btn-card-title-menu" ref={wrapperRef}>
            {props.onClose ?
              <button className={"p-link"} onClick={() => setShowMenu(!showMenu)}>
                <i className="material-icons">keyboard_arrow_down</i>
              </button>
            : null}
            
            <div className={"card-title-menu " + (showMenu ? "" : "hidden")}>
              {props.onClose ? <CardHeaderMenuEntry icon="delete" label="Entfernen" onClick={() => {setShowMenu(false); props.onClose();}}/> : null}
            </div>
        </div>
    )
}
