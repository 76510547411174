import React, { useEffect } from 'react'

export default function Datenschutz(props) {

    useEffect(() => {

        if(props.parentRef != null)
        {
        setTimeout(() => props.parentRef.current.scrollTop = 0,0)
        }
    }, [])


    return (
        <div className="datenschutz-content">
            <p><strong>Hinweise zum Datenschutz und Datenschutzerklärung</strong></p>
            <p><strong>Das Statistische Bundesamt nimmt den Schutz Ihrer Privat- und Persönlichkeitssphäre ernst und beachtet die datenschutzrechtlichen Bestimmungen. Ihr Vertrauen ist uns wichtig!</strong></p>
            <p>Mit diesen Hinweisen kommen wir auch unseren Informationspflichten nach Artikel 13 der Datenschutz-Grundverordnung (DS-GVO) bei der Erhebung personenbezogener Daten nach. Personenbezogene Daten erheben wir beim Zugriff auf unser Internet-Angebot.</p>
            <p>Wir haben technische und organisatorische Maßnahmen getroffen, die sicherstellen, dass die Vorschriften über den Datenschutz sowohl von uns als auch von unseren Dienstleistern beachtet werden. Diese Seite wird über Amazon Web Services (AWS) [410 Terry Avenue North, Seattle WA 98109, United States] auf Servern in der Europäischen Union betrieben. In diesem Zusammenhang weisen wir daraufhin, dass die Daten von AWS in einen Drittstaat (z. B. USA) transferiert werden können, wenn dies zur Einhaltung eines Gesetzes oder aufgrund einer verbindlichen Anordnung einer Regierungsbehörde erforderlich ist.</p>
            <p>Im Zuge der Weiterentwicklung unserer Webseite können auch Änderungen dieser Datenschutzerklärung erforderlich werden. Daher empfehlen wir Ihnen, sich diese Erklärung regelmäßig durchzulesen.</p>
            <p><strong>Personenbezogene Daten</strong></p>
            <p>Personenbezogene Daten sind Informationen zu Ihrer Person, wie zum Beispiel Ihr Name, Adresse, Postanschrift oder auch Ihr Nutzerverhalten, wenn dies nicht anonymisiert wird. Informationen, die nicht mit Ihrer Identität in Verbindung gebracht werden, fallen nicht darunter.</p>
            <p><strong>Zugriff auf das Internet-Angebot</strong></p>
            <p>Bei jedem Zugriff eines Nutzers auf das Internet-Angebot von Dashboard Deutschland (Statistisches Bundesamt) und bei jedem Abruf einer Datei werden Daten über diesen Vorgang anonymisiert gespeichert und verarbeitet.</p>
            <p>Im Einzelnen werden über einen Zugriff/Abruf folgende Daten gespeichert:</p>
            <ul>
                <li>Daten zur Steuerung des Internetangebotes</li>
                <li>Metadaten von Themenbereichen, Dashboards und Indikatoren</li>
            </ul>
            <p>Diese Daten werden zur Optimierung des Internetangebotes verwendet.</p>
            <p><strong>Einsatz von Cookies</strong></p>
            <p>Auf der Seite von Dashboard-Deutschland kommen Cookies zum Einsatz. Diese sind zur optimalen Bereitstellung unseres Internetangebots technisch erforderlich. Der Einsatz dieser Cookies erfolgt auf der Grundlage des Art. 6 Absatz 1 lit. e) DS-GVO in Verbindung mit § 3 Bundesdatenschutzgesetz (BDSG).</p>
            <p>Die meisten Internetbrowser sind so eingestellt, dass sie Cookies automatisch akzeptieren. Sie können das Speichern von Cookies jedoch deaktivieren oder Ihren Browser so einstellen, dass Cookies nur für die Dauer der jeweiligen Verbindung zum Internet gespeichert werden.</p>
            <p>Sollten Sie eine Browsererweiterung nutzen, die die Ablage unserer Cookies verhindert, respektieren wir dies selbstverständlich. Cookies werden in diesem Fall nicht abgelegt und die Benutzung dieser Website wird nicht nachverfolgt. Wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.</p>
            <p><strong>Anfragen über unseren Kunden- und Nutzerservice auf destatis.de</strong></p>
            <p>Neben der rein informatorischen Nutzung unserer Webseite bieten wir die Möglichkeit an, uns Anfragen über das <a target="_blank" href="https://www.destatis.de/DE/kontakt.html" rel="noreferrer">Kontaktformular</a> des Statistischen Bundesamtes (Destatis) zukommen zu lassen. Dazu müssen Sie in der Regel weitere personen­bezogene Daten angeben, die wir zur Erbringung der jeweiligen Leistung nutzen und für die die zuvor genannten Grundsätze zur Daten­verarbeitung gelten.</p>
            <p>Die von Ihnen im Kontaktformular angegebenen und von uns damit erhobenen personenbezogenen Daten werden elektronisch gespeichert. Diese Angaben benötigen wir zur Beantwortung der Anfrage in Erfüllung unserer öffentlichen Aufgaben nach Artikel 6 Absatz 1 lit. e) DS-GVO i. V. m. § 3 Bundesdatenschutzgesetz (BDSG). Die Daten Ihrer Anfrage werden anonymisiert für Nutzeranalysen zur Optimierung unserer Informationsangebote herangezogen.</p>
            <p>Darüber hinaus halten wir Ihre Daten zum Zwecke der Kundenpflege (also etwa zu optimalen Bearbeitung weiterer Anfragen) oder die Durchführung von Kundenbefragungen vor. Die Grundlage für die Verarbeitung Ihrer Daten zu diesen Zwecken ist die von Ihnen abgegebene Einwilligung gemäß Artikel 6 Absatz 1 lit. a) DS-GVO. Diese Einwilligung können Sie jederzeit mit Wirkung für die Zukunft widerrufen.</p>
            <p>Weitere Information zur Verarbeitung von Daten durch die Nutzung unserer Website destatis.de entnehmen Sie bitte der dortigen <a target="_blank" href="https://www.destatis.de/DE/Service/Datenschutz/datenschutzerklaerung.html" rel="noreferrer">Datenschutzerklärung</a>.</p>
            <p> </p>
            <p><strong>Registrierung der Beschäftigten von Ministerien</strong></p>
            <p>Für die Registrierung von Beschäftigten der Ministerien ist die Angabe der E-Mailadresse und eines selbstgewählten Passworts notwendig. Dies ermöglicht ihnen einen Zugriff auf unser erweitertes Angebot. Eine Verarbeitung dieser Daten erfolgt auf Grundlage der von Ihnen erteilten Einwilligung nach Artikel 6 Absatz 1 lit. a) DS-GVO i. V. m. § 3 BDSG.</p>
            <p><strong>Anfragen per E-Mail</strong></p>
            <p>Wenn Sie Ihre Anfragen per E-Mail an uns richten, werden Ihre Angaben zur Beantwortung der Anfrage in unserem Kundenmanagement gespeichert. Dies erfolgt in Erfüllung unserer öffentlichen Aufgaben nach Artikel 6 Absatz 1 lit. e) DS-GVO i. V. m. § 3 BDSG. Sollte eine Anfrage kostenpflichtig sein, werden Sie darauf per E-Mail hingewiesen, es sei denn Ihre Anfrage enthält im Einzelfall bereits eine konkrete Bestellung mit den erforderlichen Vertragsbestandteilen. Die Verarbeitung basiert sodann auf Artikel 6 Absatz 1 lit. b) DS-GVO. Ihnen stehen ebenso die jeweils einschlägigen untenstehenden Rechte zu.</p>
            <p> </p>
            <p><strong>Weitergabe personenbezogener Daten an Dritte</strong></p>
            <p>Daten, die beim Zugriff auf das Internet-Angebot von Destatis protokolliert worden sind, werden an Dritte nur übermittelt, soweit wir gesetzlich oder durch Gerichtsentscheidung dazu verpflichtet sind oder die Weitergabe im Falle von Angriffen auf die Internet-Infrastruktur von Destatis zu Rechts- oder Strafverfolgung erforderlich ist. Eine Weitergabe zu anderen nichtkommerziellen oder zu kommerziellen Zwecken erfolgt nicht.</p>
            <p>Im Falle der Bestellung von Publikationen verwenden wir die von Ihnen eingegebenen personenbezogenen Informationen nur innerhalb des Statistischen Bundesamtes und der mit dem Versand von Publikationen beauftragten Unternehmen. Wir geben sie nicht ohne Ihre ausdrückliche Einwilligung an Dritte weiter.</p>
            <p><strong>Minderjährigenschutz</strong></p>
            <p>Personen unter 18 Jahren sollten ohne Zustimmung der Eltern oder Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln. Wir fordern keine personenbezogenen Daten von Kindern und Jugendlichen an. Wissentlich sammeln wir solche Daten nicht und geben sie auch nicht weiter.</p>
            <p><strong>Ihre Rechte</strong></p>
            <p>Sie haben nach der Datenschutz-Grundverordnung hinsichtlich der Sie betreffenden personenbezogenen Daten gegenüber uns bei Vorliegen der Voraussetzungen die folgenden Rechte auf:</p>
            <ul>
                <li>Auskunft nach Artikel 15 DS-GVO</li>
                <li>Berichtigung nach Artikel 16 DS-GVO</li>
                <li>Löschung nach Artikel 17 DS-GVO</li>
                <li>Einschränkung der Verarbeitung nach Artikel 18 DS-GVO</li>
                <li>Widerspruch gegen die Verarbeitung nach Artikel 21 DS-GVO</li>
                <li>Datenübertragbarkeit nach Artikel 20 DS-GVO.</li>
            </ul>
            <p>Liegt aus Ihrer Sicht ein Verstoß gegen die datenschutzrechtlichen Bestimmungen vor, haben Sie zudem das Recht, sich bei der Datenschutzaufsichtsbehörde - Der Bundesbeauftragte für den Datenschutz und die Informationsfreiheit – Graurheindorfer Straße 153 - 53117 Bonn - über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren (Artikel 77 DS-GVO).</p>
            <p><strong>Kontaktdaten des Verantwortlichen</strong></p>
            <p>Statistisches Bundesamt - Gustav-Stresemann-Ring 11 - D-65189 Wiesbaden – vertreten durch den <a target="_blank" href="https://www.destatis.de/DE/Ueber-uns/Leitung-Organisation/_amtsleitung.html" rel="noreferrer">Präsidenten.</a></p>
            <p><strong>Kontaktdaten des/der behördlichen Datenschutzbeauftragten</strong></p>
            <p>Da uns Ihr Vertrauen wichtig ist, möchten wir Ihnen jederzeit Rede und Antwort bezüglich der Verarbeitung Ihrer personenbezogenen Daten stehen. Wenn Sie Fragen haben, die diese Datenschutzerklärung nicht beantworten konnte, wenden Sie sich bitte jederzeit an die behördliche Datenschutzbeauftragte.</p>
            <p>Datenschutzbeauftragte im Statistischen Bundesamt, 65180 Wiesbaden<br /> Telefon: +49 611 75 4449<br /><a href="mailto:datenschutzbeauftragter@destatis.de">datenschutzbeauftragter@destatis.de</a></p>
            <p> </p>
            <p>Wiesbaden im Dezember 2020</p>
            <p> </p>
        </div>
    )
}
