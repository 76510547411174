import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button'

/**
 * Diese Komponente repräsentiert einen Button.
 * 
 * @property {String} label Das Label.
 * @property {String} icon Das Icon des Buttons
 * @property {ButtonColor} color Die Farbe des Buttons.
 * @property {String} to Pfad-Angabe zur Navigation mit React-Routing.
 * @property {boolean} manualRedirect Die Redirecting-Funktion soll manual durchgeführt werden.
 * @property {*} style Das Styling
 * @property {boolean} disabled Der Button soll deaktiviert sein.
 * @property {*} linkprops Properties die bei der Verlinkung angehängt werden sollen.
 * @property {Function} onClick Eine Funktion die beim Click ausgelöst werden soll.
 * @property {String} tooltip Der Tooltip.
 */
export default class LinkButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            manualRedirect: this.props.manualRedirect,
            to: this.props.to,
            loading: false
        }
    }

    redirect() {
        this.setState({ redirect: true });
    }

    redirectTo(to) {
        this.setState({
            to: to,
            redirect: true
        });
    }

    /**
     * Setzt den Status des Ladens.
     * 
     * @param {boolean} b Der Status des Ladens.
     */
    setLoading(b) {
        if (b) {
            this.loadingTimeOut = setTimeout(() => this.setState({ loading: b }), 50);
        } else {

            if(this.loadingTimeOut !== undefined && this.loadingTimeOut !== null)
            {
                clearTimeout(this.loadingTimeOut);
                this.loadingTimeOut = null;
            }
            this.setState({ loading: b });
        }
    }

    /**
     * Setzt den Zustand, ob die Weiterleitung manuell durchgeführt werden soll.
     * 
     * @param {boolean} manualRedirect manuelle Weiterleitung.
     */
    manualRedirect(manualRedirect) {
        this.setState({ manualRedirect: manualRedirect });
    }

    getClass() {
        var cls = "";
        if (this.props.color !== undefined)
            cls += this.props.color + " ";
        if (this.props.className !== undefined)
            cls += this.props.className;
        return cls;
    }

    render() {
        return (
            !this.state.redirect || this.state.to === undefined ?
                <Button
                    tooltip={this.props.tooltip}
                    icon={this.props.icon}
                    className={this.getClass()}
                    style={this.props.style}
                    label={this.state.loading && this.props.icon !== undefined ? null : this.props.label}
                    onClick={(e) => {
                        if (this.props.onClick === undefined && this.state.manualRedirect !== true) {
                            this.setState({ redirect: true })
                        }
                        else if (this.props.onClick !== undefined && this.state.manualRedirect !== true) {
                            this.props.onClick(e, this);
                            this.setState({ redirect: true });
                        }
                        else if (this.props.onClick !== undefined && this.state.manualRedirect === true) {
                            this.props.onClick(e, this);
                        }
                    }}
                    disabled={this.props.disabled || this.state.loading} >
                    {this.state.loading ? <ProgressSpinner style={{ position: 'relative', display: 'contents' }} strokeWidth="8" animationDuration="2s" /> : <div />}
                </Button>
                : <Redirect to={{ pathname: this.state.to, linkprops: this.props.linkprops }} />
        )
    }
}

/**
 * Die Button-Farbe.
 * @enum {*} gray
 * @enum {*} white
 * @enum {*} green
 * @enum {*} blue
 * @enum {*} yellow
 * @enum {*} red
 */
export const ButtonColor = {
    gray: "",
    white: "p-button-secondary",
    green: "p-button-success",
    blue: "p-button-info",
    yellow: "p-button-warning",
    red: "p-button-danger"
}
