const cols = 10;
const rowHeight = 160;
const breakpoints={lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}
const layout = { lg: cols, md: 8, sm: 6, xs: 4, xxs: 2 };

export default class Layout 
{

    static get layout() {
        return layout;
    }

    static get breakpoints() {
        return breakpoints;
    }

    static get rowHeight() {
        return rowHeight;
    }

    static get cols() {
        return cols;
    }
}
