import React, { useState } from 'react'

import '../../../styles/sidebar.css';
import SidebarButton from './SidebarButton';

import {PATH_SEARCH_DASHBOARD, PATH_CREATE_DASHBOARD, PATH_NEW, PATH_MYDASHBOARDS} from '../Routing';
import DashboardButton from './DashboardButton';
import { getUser, removeUser } from '../../../app_gateway/authentication/AuthenticationStore';
import AddDashboardButton from './AddDashboardButton';
import DossierButton from './DossierButton';
import { mdiBell, mdiHeartOutline, mdiLoginVariant, mdiLogoutVariant, mdiNewBox, mdiNewspaper, mdiNewspaperVariant } from '@mdi/js';
import CategoryParent from './CategoryParent';
import ReactResizeDetector from 'react-resize-detector';
import { l } from '../Main';

export default function Sidebar(props) {

    const [update, setUpdate] = useState(0)
    const [show, setShow] = useState("")
    const [height, setHeight] = useState("")

    return (
      <ReactResizeDetector  handleHeight onResize={(width, height) => setHeight(height)}>
        <div className={"sidebar " + props.className}>

        {/*<button className="p-link" onClick={() => {}}>
        <div className="sidebar_logo" >

        </div>
        </button>*/}

        <div className="sidebar_buttons sidebar_top">
        <div style={{flexGrow: 1}}/>
          <SidebarButton mdi={{path: mdiBell}} title={l("Zuletzt aktualisierte Indikatoren")} onClick={() => { props.onToggleSidebar(); window.location = '#'+PATH_NEW}} show={show} onShow={id => setShow(id)} />
          <SidebarButton mdi={{path: mdiHeartOutline}} title={l("Meine Dashboards / Drucklayouts")} onClick={() => { props.onToggleSidebar(); window.location = '#'+PATH_MYDASHBOARDS}} show={show} onShow={id => setShow(id)} />
          {/* <DashboardButton icon="dashboard" title={"Meine Dashboards"} onClick={() => { props.onToggleSidebar();}} show={show} onShow={id => setShow(id)}/> */}
          <SidebarButton icon="search" title={l("Suchen")} onClick={() => { props.onToggleSidebar(); window.location = '#'+PATH_SEARCH_DASHBOARD}} show={show} onShow={id => setShow(id)} />
          {/* <DossierButton icon="dashboard" title={"Aktuelle Dossiers"} onClick={() => { props.onToggleSidebar();}} show={show} onShow={id => setShow(id)}/> */}
          
          <AddDashboardButton icon="add" title={l("Neues Dashboard erstellen")} onClick={() => window.location = '#'+PATH_CREATE_DASHBOARD} show={show} onShow={id => setShow(id)} />
          
          <CategoryParent {...props} show={show} onShow={id => setShow(id)} height={height}/>
          {getUser() === null ? 
          <SidebarButton mdi={{path: mdiLoginVariant, size: "30px"}} title={l("Anmelden")} onClick={() => { props.onToggleSidebar(); props.onShowLogin()}}  show={show} onShow={id => setShow(id)}/> :
          <SidebarButton mdi={{path: mdiLogoutVariant, size: "30px"}} title={l("Abmelden")} onClick={() => { props.onToggleSidebar();removeUser(); setUpdate(update + 1); window.location = "#/"; if(props.onLogoff !== undefined) props.onLogoff()}}  show={show} onShow={id => setShow(id)}/>}
        {/*</div>*/}
          </div>
        </div>
      </ReactResizeDetector>
        
    )
}
