import { getUser } from "../authentication/AuthenticationStore";
import { restRequest } from "../RestRequest";

export function findPrintlayoutsForUser(mail)
{
    return restRequest(
        {
            url: "/api/printlayout/get?mail="+mail,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function savePrintLayout(mail, layoutName, printlayout)
{
    return restRequest(
        {
            url: "/api/printlayout/save",
            method: 'POST',
            body: JSON.stringify({
                mail: mail,
                layoutName: layoutName,
                printLayout: JSON.stringify(printlayout)
            })
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function deletePrintLayout(id)
{
    return restRequest(
        {
            url: "/api/printlayout/delete",
            method: 'POST',
            body: JSON.stringify({id: id, mail: getUser().mail})
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}