import { restRequest } from "../RestRequest";

export function getNotifications(mail)
{
    return restRequest(
        {
            url: "/api/notification/get?mail="+mail,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function setEnableNotification(mail, tileid)
{
    return restRequest(
        {
            url: "/api/notification/enableNotification?userMail="+mail+"&tile="+tileid,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function setDisableNotification(mail, tileid)
{
    return restRequest(
        {
            url: "/api/notification/disableNotification?userMail="+mail+"&tile="+tileid,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}
