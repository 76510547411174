import React, { useState, useEffect } from 'react'
import { getCachedCategories } from '../../app_gateway/category/CategoryAG'

import '../../styles/category/categoryOverview.css';
import { PATH_TAGS } from '../main/Routing';
import CategoryIcon from './CategoryIcon';

export default function CategoryOverview(props) {

    const [cats, setCats] = useState([])

    useEffect(() => {
        getCachedCategories().then((cat) => setCats(cat));
    }, [])

    return (
        <div className="category-overview">
            <div className="category-overview-content">
                <div className="category-overview-title">Verfügbare Kategorien:</div>
                <div className="category-overview-content">
                    {cats.map((c) => 
                    <div key={c.name} className="category-overview-cat" onClick={() => window.location = "#"+PATH_TAGS+"/c:" + c.name}>
                        <div className="category-overview-icon" style={{backgroundColor: c.color}}>
                            <CategoryIcon category={c} size= '30px' />
                        </div>
                        <div className="category-overview-text">
                            {c.name}
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}
