import React, { useEffect, useState } from 'react'
import {InputSwitch} from 'primereact/inputswitch';
import { findDashboards } from '../../app_gateway/dashboard/DataAG'
import { PATH_DASHBOARD } from '../main/Routing'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import { getMyDashboards, addToMyDashboards, removeFromMyDashboards, getReleasedDashboards } from '../../app_gateway/store/MyDashboardsStore';
import Loading from '../util/Loading';
import { l } from '../main/Main';
import { getUser } from '../../app_gateway/authentication/AuthenticationStore';

export default function SearchContentDashboards(props) {

    const [dashboards, setDashboards] = useState(null);
    const [myDashboards, setMyDashboards] = useState(null);
    const [loading, setLoading] = useState([]);

    const [isDisplayModeCategory, setIsDisplayModeCategory] = useState(false);

    useEffect(() => {
        getMyDashboards().then(resolve => setMyDashboards(filterDashboards(resolve)));
    }, [])

    useEffect(() => {
        getReleasedDashboards().then(db => {
            setDashboards(db.filter(d => {
                return props.searchValue == null || props.searchValue.length === 0 || props.searchValue.find(sv => {
                    if(sv.type === "t") {
                        return d.tags?.find(t => t === sv.value)
                    }
                    else if(sv.type === "db") {
                        return d.title === sv.value
                    }
                    else if(sv.type === "ft") {
                        return d.title.toLowerCase().includes(sv.value.toLowerCase());
                    }
                    return false;
                }) != null
            }))
        });
    }, [props.searchValue])

    const filterDashboards = (dashboards) => {
      let mail = getUser().mail;
      return dashboards.filter(a => a.owner.mail !== mail);
    }

    
    const sortByCategory = (list) => {
        // var sortedList = list.sort((a, b) => (a.category === null || a.category === "") ? 1 : (a.category > b.category) ? 1 : (a.category === b.category) ? ((a.title > b.title) ? 1 : -1) : -1 );

        var sortedList = list.sort(function(a, b) {
            if(a.category < b.category) { return -1; }
            if(a.category > b.category) { return 1; }
            if(a.category === null || a.category === "")  { return -1; }
            if(a.category === b.category) { 
                if(a.title < b.title) { return -1; }
                if(a.title > b.title) { return 1; }
            }
            return 0;
        })

        var items = [];
        let categoryCounter = 0;
        let categoryChildCounter = 0;
        let currentCategory = "";
        let currentCategoryChildren = [];

        for (const [i, v] of sortedList.entries()) {
            if (currentCategory === "") {
                currentCategory = v.category;
            }

            if (currentCategory === v.category) {
                currentCategoryChildren.push({
                    "key": categoryCounter + "-" + categoryChildCounter,
                    "id": v.id,
                    "title": v.title,  
                    "tags": v.tags,
                    "url": PATH_DASHBOARD + "/" + v.id,
                });
                categoryChildCounter++;
            }

            if ((sortedList[i+1] === undefined) || (sortedList[i+1] !== undefined && sortedList[i+1].category !== currentCategory)) {

                let a = {
                    "key": categoryCounter + "",
                    "label": (currentCategory === null || currentCategory === "" ? (l("Keine Kategorie")) : (currentCategory)),
                    "children": currentCategoryChildren
                }
                items.push(a);

                categoryCounter++;
                categoryChildCounter = 0;
                currentCategory = "";
                currentCategoryChildren = [];
            }
          }
        return <Tree value={items} nodeTemplate={treeNodeTemplate} contentClassName="search-category-tree"/>
    }

    const treeNodeTemplate = (node) => {
        if (node.url) {
            return dashboardItem(node);
        }
        else {
            return (
                <span className="search-category-label">{node.label}</span>
            )
        }
    }

    const dashboardItem = (db) => {
        return (
            <div key={db.id} className="search-content-entry">
                <div className="search-content-entry-title" onClick={() => window.location = "#" + PATH_DASHBOARD + "/" + db.id}>
                    {(db.category ? (db.category + " / ") : "") + db.title}
                </div>
                <div className="search-content-entry-tags" onClick={() => window.location = "#" + PATH_DASHBOARD + "/" + db.id}>
                    {db.tags.map(tag => <div className="search-content-entry-tag">{tag}</div>)}
                </div>
                <div className="search-content-entry-abo">
                    {loading.includes(db.id) ? 
                        <ProgressSpinner className="progress-spinner-anim-disabled" style={{marginTop: '8px', marginRight: '5px', width: '25px', height: '25px'}} strokeWidth="5" animationDuration="1.5s"/>
                        : <InputSwitch checked={myDashboards !== null && myDashboards !== undefined && myDashboards.find(e => e.id === db.id) !== undefined} onChange={(e) => {
                            setLoading([...loading, db.id]);
                            setTimeout(() => {
                                if(e.value) {
                                    addToMyDashboards(db).then(e => {
                                        setMyDashboards(e);
                                        setLoading(loading.filter(e => e.id === db.id));
                                    });
                                }
                                else {
                                    removeFromMyDashboards(db).then(e => {
                                        setMyDashboards(e);
                                        setLoading(loading.filter(e => e.id === db.id));
                                    });
                                }
                            }, 300);
                            }}/>
                    }
                </div>
            </div>);
    }



    const sortByAlphabet = (list) => {
        var sortedList = list.sort(function(a, b) {
            if(a.title < b.title) { return -1; }
            if(a.title > b.title) { return 1; }
            return 0;
        })

        return <>
            {sortedList.map((db, i) => {
                return dashboardItem(db);
            })}
        </>
    }

    return (
        <div className="search-content">
            <div className="search-content-title">
                    <Button icon="pi pi-folder-open" tooltip={l("Ansicht nach Kategorie")} className={"search-content-display-mode-button " + (isDisplayModeCategory ? "button-active" : "button-inactive")} onClick={() => setIsDisplayModeCategory(true)}/>
                    <Button icon="pi pi-list" tooltip={l("Ansicht als Liste")} className={"search-content-display-mode-button " + (!isDisplayModeCategory ? "button-active" : "button-inactive")} onClick={() => setIsDisplayModeCategory(false)}/>
                    <span className="search-content-title-title">{l("Verfügbare Dashboards")}:</span>
                <div>{l("Abonnieren")}:</div>
            </div>
            {dashboards != null ? 
                (dashboards.length > 0) ?
                (
                    (isDisplayModeCategory ? 
                        (sortByCategory(dashboards)
                        ):
                        (
                        <div className="search-list-mode">
                            {sortByAlphabet(dashboards)}
                        </div>)
                    )
                ) : 
                (<span style={{fontWeight: '500', fontSize: '16px'}}>{l("Keine Dashboards vorhanden")}</span>) : 
            <Loading small horizontal/>
            }
        </div>
    )
}
