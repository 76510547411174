import React, {useState, useEffect} from 'react';
import Icon from '../../util/Icon';

export default function SidebarButton(props) {

    const [hover, setHover] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show === props.title);
    }, [props.show])

    return (
        <div aria-label={props.title} className="sidebar-button" onMouseEnter={(e) => {setHover(true)}} onMouseLeave={() => {setHover(false)}} onFocus={e =>  {setHover(true)}} onBlur={e =>  {setHover(false)}} > 
            <button aria-label={props.title} className={"p-link "+(hover || props.show === props.icon ? "hover" : "")} onClick={() => {
                
                if(hover) {
                    setHover(false); 
                    if(!props.disabled)
                        props.onClick()
                }
                else {
                    props.onShow(show ? "" : props.title);
                }
                }}>
                <Icon icon={props.icon} mdi={props.mdi} style={{color: props.disabled? 'gray' : 'white'}} />
            </button>
            <div className={"hover-menu "+(hover || show ? "" : "hidden ") + (props.disabled? 'hover-menu-disabled' : '')} onClick={() => {
                setHover(false); 
                if(!props.disabled)
                {
                    props.onClick()
                }}}>
                <div className="hover-menu-title top">{props.title}</div>
            </div>
        </div>
    )
}
