import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import Main from './de_destatis_dd/components/main/Main';
//import './locale'
import './de_destatis_dd/styles/responsive/maxwidth1100.css';
import './de_destatis_dd/styles/responsive/maxwidth800.css';
import "rc-tooltip/assets/bootstrap_white.css";




/**
 * Rendert die React-Komponente in das div-Element mit der ID 'root'
 */
ReactDOM.render(
    <Main />,
    document.getElementById('root')
);