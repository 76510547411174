import { restRequest } from "../RestRequest";

export function readTile(tileid)
{
    return restRequest(
        {
            url: "/api/tile/get?tileid="+tileid,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readAuditDates(tileid)
{
    return restRequest(
        {
            url: "/api/auditedtile/getdates?id="+tileid,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readAuditData(tileid, date)
{
    return restRequest(
        {
            url: "/api/auditedtile/dashboardformat?id="+tileid,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readTiles(tiles)
{
    return restRequest(
        {
            url: "/api/tile/getTiles?tiles="+tiles,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readTilesForCategory(cat)
{
    return restRequest(
        {
            url: "/api/tile/getTilesForCategory?cat="+cat,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}