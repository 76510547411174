import React, { useState, useEffect, useRef } from "react";
import NewCategoryButton from "./NewCategoryButton";
import CategoryExtensionButton from "./CategoryExtensionButton";
import {
  getCachedCategories,
  deleteCachedCategories,
  eventCacheCategories,
} from "../../../app_gateway/category/CategoryAG";
import { PATH_TAGS, PATH_CATEGORY_OVERVIEW } from "../Routing";
import { onChangeLanguage } from "../../util/OnChangeLanguage";

const PubSub = require("pubsub-js");

export default function CategoryParent(props) {
  const [categories, setCategories] = useState([]);
  const [allcategories, setAllCategories] = useState([]);
  const [useExtension, setUseExtension] = useState(false);
  const div = useRef(null);

  useEffect(() => {
    getCachedCategories().then((r) => {
      setCategories(r);
      //setAllCategories(r);
    });

    PubSub.subscribe(eventCacheCategories, () => {
      deleteCachedCategories();
      getCachedCategories().then((r) => {
        setCategories(r);
        //setAllCategories(r);
      });
    });

    onChangeLanguage(() => {
      deleteCachedCategories();
      getCachedCategories().then((r) => {
        setCategories(r);
        //setAllCategories(r);
      });
    });

    return () => {
      PubSub.unsubscribe(eventCacheCategories);
    };
  }, []);

  return (
    <div
      ref={div}
      className="sidebar-category "
      style={{ flexGrow: 8}}
    >
      {categories && categories.length > 0
        ? categories.map((cat) => (
            <NewCategoryButton
              mobileMenuActive={props.mobileMenuActive}
              key={cat.id}
              category={cat}
              onClick={() => {
                props.onToggleSidebar();
                window.location = "#" + PATH_TAGS + "/c:" + cat.name;
              }}
              show={props.show}
              onShow={props.onShow}
            />
          ))
        : null}
      {useExtension ? (
        <CategoryExtensionButton
          mobileMenuActive={props.mobileMenuActive}
          onClick={() => {
            props.onToggleSidebar();
            window.location = "#" + PATH_CATEGORY_OVERVIEW;
          }}
          show={props.show}
          onShow={props.onShow}
        />
      ) : null}
    </div>
  );
}
