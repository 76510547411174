import React, { useEffect, useState } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";

import DashboardTile from '../dashboard/DashboardTile';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "../../styles/dashboard.css";
import Layout from '../dashboard/Layout';
import {readTiles} from '../../app_gateway/dashboardtile/TileAG';
import { getCentralLayout } from '../util/CentralStore';
import { mdiCheckboxMarkedCircleOutline, mdiCancel, mdiPrinterAlert } from '@mdi/js'; 
import Icon from '../util/Icon';
import { PATH_PRINTVIEW } from '../main/Routing';
import PrintToolbar from './PrintToolbar';
      
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default function PrintSelection(props) 
{
    const [cards, setCards] = useState([]);
    const [layouts, setLayouts] = useState({});
    const [checked, setChecked] = useState([]);
    const [cannotPrintTile, setCannotPrintTile] = useState([]);
    const [tiles, setTiles] = useState( new Map());
    const [loaded, setLoaded] = useState([]);
    
    var layout = null;
    var cannotPrintTiles = [];
    var tmpLoaded = [];
  
    useEffect(() => {
      readMyDashboard();
    }, [])
  
    const readMyDashboard = () =>
    {
        layout = getCentralLayout();
        if(layout === null || layout === undefined)
            window.location = '#';
        else
        {
            setLayouts(layout)
            var tiles = layout.lg.map(tile => tile.i);
            setChecked(tiles);
            readTiles(tiles.join(",")).then(t => {
                for(var i = 0; i < t.length; i++)
                {
                    for(var y = 0; y < layout.lg.length; y++)
                    {
                        if(layout.lg[y].i === t[i].id)
                        {
                            layout.lg[y].tile = t[i];
                        }
                    }
                }
                setCards(layout.lg);
            });
        }
    }
    
    const getValidValue = (value, defaultVal) =>
    {
      if(value > 0)
        return value;
      return defaultVal;
    }

    const onClickTile = (id) =>
    {
        if(!cannotPrintTile.includes(id)) 
        {
            if(checked.includes(id))
            {
                const index = checked.indexOf(id);
                setChecked([...checked.slice(0, index), ...checked.slice(index + 1)]);
            }
            else
            {
                setChecked([...checked, id]);
            }
        }
    }

    const getChecked = () => {
        return cards.sort((a, b) => 
        {
            if(a.y !== b.y)
                return a.y - b.y;
            return a.x - b.x;
        }).filter(r => checked.includes(r.tile.id) && (!cannotPrintTile.includes(r.tile.id))).map(r => r.tile.id).join(",");
    }

    const getDashboardTile = (tile) =>{
        var mytiles = tiles;
        if(mytiles.has(tile.id))
        {
            return mytiles.get(tile.id);
        }
        var result = <DashboardTile id={tile.id} layout={tile.dashboardFormat} attachments={null} instantLoad date={tile.date} onCannotPrintTile={() => onCannotPrintTile(tile.id)} onLoad={() => onLoaded(tile.id)} isOnPrintView={true}/>
        mytiles.set(tile.id, result);
        setTiles(mytiles);
        return result;
    }

    const onCannotPrintTile = (id) => {
        cannotPrintTiles = [...cannotPrintTiles, id];
        setCannotPrintTile(cannotPrintTiles);
    }

    const onLoaded = (id) => {
        tmpLoaded = [...tmpLoaded, id];
        setLoaded(tmpLoaded);
    }

    return (
        <div>
            <PrintToolbar onGoNextDisabled={(checked.length - cannotPrintTile.length) === 0 || loaded.length !== cards.length} goNextLoading={loaded.length !== cards.length} onGoNext={() => {window.location = '#' + PATH_PRINTVIEW + "/" + getChecked()}} />
            <div className="printboard">
                <div className="printboard-title" style={{width:'100%'}}>
                    <div className="printboard-title-center">Bitte wählen Sie die zu druckenden Kacheln</div>
                    <div className={"printboard-title-right " + ((checked.length - cannotPrintTile.length) === 0? "disabled" : "")}>
            </div>
                </div>
            </div>
        <ResponsiveReactGridLayout id={"renderme"} className={"layout"} style={{zIndex:'0'}}
        onLayoutChange={(layout, ls) =>
            {
            setLayouts(ls)}
            }
        isResizable={false}
        layouts={layouts}
        rowHeight={Layout.rowHeight}
        breakpoints={Layout.breakpoints}
        cols={Layout.layout}
        {...props}
        >
        {cards.map((r, i) => 

            <div key={r.tile.id} data-grid={{ 
                x: r.x, 
                y: r.y, 
                w: getValidValue(r.w, 3), 
                h: getValidValue(r.h, 3), 
                i: r.tile.id,
                static: true }}
                style={{zIndex:'-1', cursor: 'pointer'}}
                onClick={() => onClickTile(r.tile.id)}>
                {cannotPrintTile.filter(t => t === r.tile.id).length === 0 ? checked.filter(t => t === r.tile.id).length > 0 ? 
                    <div className="dashboard-tile-overlay"><Icon mdi={{path: mdiCheckboxMarkedCircleOutline, color: 'white', size: '100px'}} /></div> : 
                    <div className="dashboard-tile-overlay unchecked"><Icon mdi={{path: mdiCancel, color: 'white', size: '100px'}} /></div> :
                    <div className="dashboard-tile-overlay unchecked"><Icon mdi={{path: mdiPrinterAlert, color: 'white', size: '100px'}} />Dieser Indikator kann nicht gedruckt werden.</div>}
                {getDashboardTile(r.tile)}
            </div>)}
        </ResponsiveReactGridLayout>
    </div>
    );
  }