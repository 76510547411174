import React, { useState } from 'react'

import '../../styles/selectbutton.css'

export default function SelectButton(props) {
    
    return (
        <div className="selectbutton-root">
            <div className="selectbutton-content">
                {props.options.map((val, index) => {
                    return <button 
                        key={val[props.optionLabel]} 
                        className={"selectbutton " + 
                            (props.value != null && props.value[props.optionLabel] === val[props.optionLabel] ? "selected" : 
                            (props.value != null && index > 0 && props.options[index - 1][props.optionLabel] === props.value[props.optionLabel] ? "selected-before" : "" ))} 
                        onClick={() => props.onChange(val)} >
                        <div className="selectbutton-label">{val[props.optionLabel]}</div>
                    </button>
                })}
            </div>
        </div>
    )
}
