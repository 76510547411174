import React, {useState, useEffect} from 'react'
import { PATHNAME_DASHBOARD, PATHNAME_SEARCH } from './Routing';
import { Button } from 'primereact/button';

import {readOnlyDashboard} from '../../app_gateway/dashboard/DataAG';

import '../../styles/topbar.css';
import { HOME_DASHBOARD } from '../../app_gateway/Constants';
import { l } from './Main';

export default function Topbar(props) {
    const [dashboardName, setDashboardName] = useState("");
    const [dashboardIcon, setDashboardIcon] = useState("");

    useEffect(() => {
        var path = props.location.pathname.split("/");
        if(path.length === 3 && path[1] === PATHNAME_DASHBOARD)
        {
            var dashboardId = path[2];
            if(dashboardId === HOME_DASHBOARD)
            {
                setDashboardName(HOME_DASHBOARD);
            }
            else
            {
                readOnlyDashboard(dashboardId).then(response => {
                    var name = "";
                    if(response.title)
                    {
                        /*if(response.category !== null && response.category.length > 0)
                        {
                            name += response.category + " / ";
                        }*/
            
                        name+= response.title;
                    }
                    setDashboardName(name);
                });
                
            }
            setDashboardIcon("dashboard")
        }
        else if(path[1] === PATHNAME_SEARCH)
        {
            setDashboardName(l("Suchen"));
            setDashboardIcon("search")
        }
        else
        {
            setDashboardName("");
            setDashboardIcon("")
        }
    }, [props.location.pathname])

    return (
        <div className="topbar" aria-label={"Topbar"}>
            <div className="topbar-item">
                <div className="topbar-path">
                    <i className="topbar-path-icon material-icons">{dashboardIcon}</i>
                    {dashboardName}
                </div>
                <div aria-label={"Menü einblenden"} className="topbar-burger-menu">
                    <Button title="Menü einblenden" aria-label={"Menü einblenden"} className="topbar-burger-menu-button" icon="pi pi-md-menu" onClick={() => props.onToggleSidebar()} />
                </div>

                {/* <div className="topbar-add-dashboard-tile">
                    <Link to={"" + PATH_ADD_DASHBOARD_TILE}><Button className="topbar-add-dashboard-tile-button" icon="pi pi-md-plus"/></Link>
                </div> */}
            </div>

            <div className="topbar-item topbar-item-titel tt-fadeInDown">
                <div aria-label={"Dashboard Deutschland Logo - Zur Startseite"} tabIndex={0} className="topbar-title" onClick={() => window.location = '#/'}>
                    <div className="topbar-logo" aria-label={"Dashboard Deutschland Logo"}>
                        <img src="assets/layout/images/DashboardDeutschlandLogo.png" alt="Dashboard Deutschland Logo"/>
                    </div>
                    {/*<span className="topbar-title-text">Dashboard Deutschland</span>*/}
                </div>
            </div>
            <a aria-label={"Destatis Logo - Zur Website www.destatis.de"} href="http://www.destatis.de" style={{position: 'relative', right: '0', width: '150px'}}>
                <div className="topbar-item tt-fadeInRight">
                    <div className="topbar-logo destatis-logo">
                            <img src="assets/layout/images/DestatisLogo.svg" alt="Destatis Logo" />
                    </div>
                </div>
            </a>
        </div>
    )
}
