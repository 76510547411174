import React, { useEffect, useState, useRef } from 'react'
import IconEditor from './IconEditor'
import { getUser } from '../../app_gateway/authentication/AuthenticationStore'
import { getAllCategories, saveAllCategories } from '../../app_gateway/category/CategoryAG'
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import '../../styles/category/category.css'
import LinkButton, { ButtonColor } from '../util/LinkButton';
import CategoryIcon from './CategoryIcon';


export default function Category() 
{
    const [newCat, setNewCat] = useState(0);
    const [categories, setCategories] = useState([]);
    const saveButton = useRef(null)

    useEffect(() => {
        loadCategories();
    }, [])

    const loadCategories = () => {
        getAllCategories().then(r => {
            setCategories(r);
        })
    }

    const saveCategories = () => {
        saveButton.current.setLoading(true);
        setTimeout(() => {
            let cats = []
            for(let i = 0; i < categories.length; i++)
            {
                let cat = categories[i];
                cat.catIndex = i;
                cats.push(cat);
            }
            saveAllCategories(cats).then(() => 
            {
                loadCategories();
                saveButton.current.setLoading(false);
                window.location = "#/adminize/category"
            })
        }, 500);
    }

    const deleteCategory = (id) => {
        let cat = categories.filter(c => c.id !== id);
        setCategories(cat);
    }

    const addCategory = () => {
        setCategories([...categories, 
             {
                id: "unbenannt" + newCat,
                name: "unbenannt", 
                color: "#d10714", 
                iconType: "singlesvg", 
                viewBox: "0 0 24 24",
                icon: "M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
            }])
        setNewCat(newCat + 1);
    }

    
    const iconBody = (rowData) => {
        return <div className="category-icon" style={{backgroundColor: rowData.color}}>
        <CategoryIcon category={rowData} size= '30px' /></div>;
    }
    
    const deleteRow = (rowData) => {
        return <LinkButton icon="pi-md-delete" color={ButtonColor.white} onClick={() => deleteCategory(rowData.id)}/>
    }
    
    const iconEditor = (props) => {
        return <IconEditor {...props.rowData} onChange={(prop, value) => onIconChange(props, prop, value)} />;
    }

    const editorForRowEditing = (props, field) => {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => onEditorValueChange(props, e.target.value)} />;
    }

    const onIconChange = (props, prop, value) => {
        let cats = [...categories];
        if(prop.startsWith("viewBox"))
        {
            let vb = cats[props.rowIndex]["viewBox"].split(" ");
            switch(prop.split(".")[1]) {
                case "top": vb[0] = value; break;
                case "left": vb[1] = value; break;
                case "w": vb[2] = value; break;
                case "h": vb[3] = value; break;
                default: break;
            }
            cats[props.rowIndex]["viewBox"] = vb.join(" ");
        }
        else
        {
            cats[props.rowIndex][prop] = value;
        }
        setCategories(cats);
    }

    const onEditorValueChange = (props, value) => {
        let cats = [...categories];
        cats[props.rowIndex][props.field] = value;
        setCategories(cats);
    }

    let user = getUser();
    if(!(user && user.mail && user.mail.includes("destatis")))
    {
       window.location = '#';
       return null;
    }
    else{
        return (
            <div className="category-root">
                
                <div className="category-header">Verwaltung der Kategorien</div>
                <div className="category-bar">
                    <LinkButton label="Kategorien erstellen" icon="pi-md-plus" color={ButtonColor.gray} manualRedirect={true} onClick={addCategory} />
                </div>
                <div className="category-content">
                    <DataTable value={categories} reorderableColumns={true} onRowReorder={(e) => setCategories(e.value)}>
                        <Column rowReorder={true} style={{width: '3em', verticalAlign: 'middle', paddingTop: '5px'}} />
                        <Column field="id" header="ID" style={{verticalAlign: 'middle'}} editor={(props) => editorForRowEditing(props, 'id')} />
                        <Column field="name" header="Name" style={{verticalAlign: 'middle'}} editor={(props) => editorForRowEditing(props, 'name')}/>
                        <Column field="icon" header="Icon" style={{verticalAlign: 'middle'}} body={iconBody} editor={iconEditor} />
                        <Column style={{width: '4em', verticalAlign: 'middle', paddingTop: '5px'}} body={deleteRow}/>
                    </DataTable>
                </div>
                <div className="category-footer">
                    <LinkButton ref={saveButton} label="Kategorien speichern" icon="pi-md-save" color={ButtonColor.blue} manualRedirect={true} onClick={saveCategories} />
                </div>
            </div>
        )
    }
}
