import { getUser } from "../authentication/AuthenticationStore";
import { restRequest } from "../RestRequest";

export function readData(resource)
{
    return restRequest(
        {
            url: "/api/tile/dashboardformat?id="+resource,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readDashboard(resource)
{
    return restRequest(
        {
            url: "/api/dashboard/get?id="+resource + (getUser() !== null ? "&user=" + getUser().mail : ""),
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function deleteDashboard(id)
{
    return restRequest(
        {
            url: "/api/dashboard/delete?id="+id + "&user=" + getUser().mail,
            method: 'DELETE'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function findDashboards(title)
{
    return restRequest(
        {
            url: "/api/dashboard/findDashboards?title="+title + (getUser() !== null ? "&user=" + getUser().mail : ""),
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readAllLastTiles(time, cats)
{
    return restRequest(
        {
            url: "/api/tile/last?time="+time+"&categories="+cats,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function findDashboardsForUser(mail)
{
    return restRequest(
        {
            url: "/api/dashboard/findDashboardsForUser?mail="+mail,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function findReleased(mail)
{
    return restRequest(
        {
            url: "/api/dashboard/getReleased?mail="+mail,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function findDossiers(mail)
{
    return restRequest(
        {
            url: "/api/dashboard/findDossiers?mail="+mail,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function removeDashboardsForUser(dbid, mail)
{
    return restRequest(
        {
            url: "/api/dashboard/removeDashboardsForUser?dashboardId="+dbid+"&mail="+mail,
            method: 'POST'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function addDashboardsForUser(dbid, mail)
{
    return restRequest(
        {
            url: "/api/dashboard/addDashboardsForUser?dashboardId="+dbid+"&mail="+mail,
            method: 'POST'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function saveDashboardSettings(dashboard)
{
    return restRequest(
        {
            url: "/api/dashboard/saveDashboardSettings",
            method: 'POST',
            body: JSON.stringify(dashboard)
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readOnlyDashboard(resource)
{
    return restRequest(
        {
            url: "/api/dashboard/getDashboardOnly?id="+resource,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readHomeDashboard()
{
    return restRequest(
        {
            url: "/api/dashboard/gethome",
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function removeTileFromDashboard(dashboardid, tileid)
{
    return restRequest(
        {
            url: "/api/dashboard/removeTile?dashboardid="+dashboardid+"&tileid="+tileid,
            method: 'POST'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}