import React, { useEffect, useState } from 'react'
import { readFile, readMeta } from '../../app_gateway/attachment/AttachmentAG';
//import { PDFViewer as PDF } from 'react-view-pdf';
import PDFViewer from 'pdf-viewer-reactjs'
import PDFToolbar from './PDFToolbar';

import '../../styles/pdfviewer.css';
import { ProgressSpinner } from 'primereact/progressspinner';

export default function MyPDFViewer(props) {
    
    const [file, setFile] = useState(null);

    useEffect(() => {
        readPdfFile();
    }, [props.location.pathname])

    const readPdfFile = () =>
    {
        var file = props.match.params.id;
        if(file)
        {
        readFile(file).then(r1 => {
            readMeta(file).then(r2 => {

                var f = r2;
                f.content = r1;
                setFile(f);
            })
        });
        }
    }

    return (
        <div className="pdf-content">
            {file !== null ?
            <PDFViewer 
                navigation={(props) => <PDFToolbar file={file} {...props} />}  
                navbarOnTop={true} 
                document={{url: file.content}} 
                scale={1} 
                scaleStep={0.3} 
                loader={<div className="process-spinner"><div><ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration="1.5s"/><div>Lade Daten...</div></div></div>}/> 
                
            : <div className="process-spinner"><div><ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration="1.5s"/><div>Lade Daten...</div></div></div>}
        </div>
    )
}
