import React, { useEffect, useState, useRef } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';

import { readTilesForCategory } from '../../app_gateway/dashboardtile/TileAG';

import '../../styles/tilechooser.css';
import { InputText } from 'primereact/inputtext';
import Icon from '../util/Icon';
import UIConstants from '../util/UIConstants';
import { getCachedCategories } from '../../app_gateway/category/CategoryAG';
import CategoryIcon from '../category/CategoryIcon';

export default function TileChooser(props) {
    
    const wrapperRef = useRef(null);
    const [categories, setCategories] = useState(null);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [tiles, setTiles] = useState(new Map());
    const [selectedTiles, setSelectedTiles] = useState([]);
    const [searchtext, setSearchtext] = useState("");

    useEffect(() => {
        //getFromCache(CACHE_CATEGORIES, 5000, readCategories).then(r => {
            getCachedCategories().then(cat => {
                setCategories(cat);
                cat.forEach(c => readCategoryTiles(c.name));
            })
        //});
    }, [])

    useEffect(() => {
        setSelectedTiles(props.selectedTiles);
    }, [props.selectedTiles])

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && event.target.id !== "dashboardtoolbar.addtile") {
        props.onHide();
      }
    }

    useEffect(() => {
      if(props.visible)
        document.addEventListener("mousedown", handleClickOutside);
      else
        document.removeEventListener("mousedown", handleClickOutside);

    }, [props.visible])


    const readCategoryTiles = (cat) => {
        readTilesForCategory(cat).then(t => {
            var ti = tiles;
            ti.set(cat, t)
            setTiles(ti);
        })
    }

    const getTiles = (cat) => {
        let result = []
        let t = tiles.get(cat)
        if(t !== null && t !== undefined)
        {
            t.forEach(e => 
            {
                if(((!selectedTiles.includes(e.id) && (e.title.toLowerCase().includes(searchtext.toLowerCase()))) || 
                    e.tags.find(tag => tag.toLowerCase().includes(searchtext.toLowerCase()))) !== undefined || cat.toLowerCase().includes(searchtext.toLowerCase()))
                result.push(e);
            })
        }
        return result;
    }

    

    const getAllTiles = () => {
        let result = []
        for(let i = 0; i < categories.length; i++)
        {
            let t = getTiles(categories[i].name);
            if(t !== null)
                result.push(...t);
        }
        return result;
    }

    return (
        <div ref={wrapperRef} className={"tilechooser " + (props.visible ? "" : " hidden")} onMouseLeave={() => props.onMouseLeave()} onMouseEnter={() => props.onMouseEnter()}>
            <div className={"tilechooser-search"}>
                <InputText value={searchtext} onChange={e => setSearchtext(e.target.value)} />
                <Icon icon="search"/>    
            </div>
            {searchtext !== "" ? 
                getAllTiles().filter(e => e.hidden === false && !selectedTiles.includes(e.id)).map((e, i) => <TileChooserTile key={"tile"+i + "" +e.id} tilekey={"tile"+e.id} tile={e} onClick={tile => props.onChoose(tile)} />)
            :<Accordion tabIndex={0} activeIndex={activeIndex} onTabChange={(e) => {
                    setActiveIndex(e.index);
                    setSelectedTiles(props.selectedTiles); 
                }}>
                {categories !== null ? categories.filter(cat => getTiles(cat.name).length > 0).map((cat, i) => {
                        return <AccordionTab className="Hugo" key={"tab" + cat.name} tabIndex={0}
                        header={
                        <div className="tilechooser-category-accordion">
                            <div className="tilechooser-category-accordion-text">{cat.name}</div>
                            <div className="tilechooser-category-accordion-icon" style={{backgroundColor: cat.color}}>
                                <CategoryIcon category={cat} size= '25px' />
                            </div>
                            </div>} >

                            {getTiles(cat.name).filter(e => e.hidden === false && !selectedTiles.includes(e.id)).map((e, i) => <TileChooserTile key={"tile"+cat.name+i} tilekey={"tile"+e.id} tile={e} onClick={tile => props.onChoose(tile)} />)}
                        </AccordionTab>
                    }) : null}
                    
                
            </Accordion>}
        </div>
    )
}

export function TileChooserTile(props) {

    const [cat, setCat] = useState({})

    useEffect(() => {
        UIConstants.getCategory(props.tile.category).then(c => setCat(c));
    }, [])

    return (
        <div key={props.tilekey}  tabIndex={0} className="tilechooser-tile droppable-element" onClick={() => props.onClick(props.tile)} draggable={false} unselectable="on" onDragStart={e => e.dataTransfer.setData("text/plain", "")}>
            <div className="tilechooser-tile-title">
                <div className="card-title-link btn-card-title-menu card-title-category-icon" style={{backgroundColor: cat.color}}>
                        <CategoryIcon category={cat} />
                        
                </div>
                {props.tile.title}
            </div>
            <div className="tilechooser-tile-tags">
                {props.tile.tags.map((tag, i) => <div key={"tag-"+i} className="tilechooser-tile-tag">{tag}</div>)}
            </div>
        </div>
    )
}