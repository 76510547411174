import React, { useState, useEffect, useRef } from "react";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

import { ProgressSpinner } from "primereact/progressspinner";
import {
  registerNewUser,
  loginUser,
  validateUser,
  forgotPassword,
  changePassword,
} from "../../app_gateway/authentication/AuthenticationAG";
import {
  setLoginData,
  rememberMyLoginData,
} from "../../app_gateway/authentication/AuthenticationStore";
import SuccessIcon from "../util/SuccessIcon";
import "../../styles/login.css";
import { l } from "../main/Main";
import {VERSION } from "../../app_gateway/Constants";
import CookieBanner from "../privacy/CookieBanner";
import Impressum from "../privacy/Impressum";
import Datenschutz from "../privacy/Datenschutz";

const MODE_LOGIN = 0;
const MODE_REGISTER = 1;
const MODE_FORGOTPW = 2;
const MODE_CHANGEPW = 3;

// eslint-disable-next-line no-useless-escape
const re =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function Login(props) {
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(null);
  const [mode, setMode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [forgotPasswordForMail, setForgotPasswordForMail] = useState("");
  const [rememberLogin, setRememberLogin] = useState(false);
  const [showImpressum, setShowImpressum] = useState(false);
  const [showDatenschutz, setShowDatenschutz] = useState(false);
  const [cookieBannerActive, setCookieBannerActive] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);

  const wrapperRef = useRef(null);
  const impressumRef = useRef(null);
  const datenschutzRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    if (props.url && props.url.startsWith("/usr/v/")) {
      let param = props.url.substring(7, props.url.length);
      let split = param.split(":");
      let params = { [split[0]]: split[1] };

      if (params.validate) validate(params.validate);
      else if (params.forgotpw) {
        setForgotPasswordForMail(params.forgotpw);
        setMode(MODE_CHANGEPW);
      }
    }

    if (props.loginParams) {
      if (props.loginParams.validate) validate(props.loginParams.validate);
      else if (props.loginParams.forgotpw) {
        setForgotPasswordForMail(props.loginParams.forgotpw);
        setMode(MODE_CHANGEPW);
      }
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const validate = (validate) => {
    setLoading(true);
    setTimeout(() => {
      validateUser(validate).then((response) => {
        if (response && response.mail) {
          showSuccess(
            0,
            l(
              "Ihre E-Mail Adresse wurde erfolgreich bestätigt. Sie können sich nun an der Anwendung anmelden."
            )
          );
        } else {
          setError(
            l(
              "Ihre E-Mail Adresse konnte nicht bestätigt werden oder wurde bereits bestätigt."
            )
          );
          setLoading(false);
        }
      });
    }, 1000);
  };

  const keyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  const buttonPress = (event) => {
    handleSubmit(event);
  };

  const checkPassword = () => {
    if (password.length < 6) {
      setError(l("Das Passwort muss mindestens 6 Stellen lang sein"));
      setLoading(false);
      return false;
    }
    return true;
  };

  const checkMail = () => {
    if (!re.test(mail.toLowerCase())) {
      setError(l("Keine gültige E-Mail-Adresse"));
      setLoading(false);
      return false;
    }
    return true;
  };

  const resetPassword = () => {
    setPassword2("");
    setPassword("");
  };

  const handleSubmit = (event) => {
    setError(null);
    setLoading(true);
    event.preventDefault();
    if (mode === MODE_REGISTER) {
      if (!checkMail()) return;
      if (!checkPassword()) return;
      if (password !== password2) {
        setError(l("Wiederholung stimmt nicht mit Passwort überein"));
        setLoading(false);
        return;
      }
      createNewUser();
    } else if (mode === MODE_FORGOTPW) {
      if (!checkMail()) return;
      forgotPw();
    } else if (mode === MODE_LOGIN) {
      if (!checkMail()) return;
      if (!checkPassword()) return;
      login();
    } else if (mode === MODE_CHANGEPW) {
      if (password !== password2) {
        setError(l("Wiederholung stimmt nicht mit Passwort überein"));
        setLoading(false);
        return;
      }
      changePW();
    }
  };

  const createNewUser = () => {
    var user = {
      mail: mail,
      password: password,
    };
      registerNewUser(user).then(response => {
        if (response === "Registrierung erfolgreich") {
          showSuccess(
            0,
            l(
              "Die Registrierung wurde erfolgreich durchgeführt. Bitte bestätigen Sie Ihre E-Mail Adresse"
            )
          );
        } else if (response === "Ungueltige Mail-Domain") {
          setError(l("Die angegebene E-Mail-Domain ist leider nicht zulässig."));
        }else {
          setError(l("Registrierung fehlgeschlagen"));
        }
      }).catch(err => {
        setError(l("Registrierung fehlgeschlagen"));
      }).finally(() => setLoading(false));
  };

  const changePW = () => {
    var body = {
      uuid: forgotPasswordForMail,
      newPassword: password,
    };
    setTimeout(() => {
      changePassword(body).then((user) => {
        if (user) {
          showSuccess(0, l("Ihr Passwort wurde erfolgreich geändert."));
        } else {
          setError(l("Ihr Passwort konnte nicht geändert werden."));
          setLoading(false);
        }
      });
    }, 1000);
  };

  const forgotPw = () => {
    setTimeout(() => {
      forgotPassword(mail).then(() => {
        showSuccess(
          0,
          l("Es wurde eine E-Mail an die angegebene Adresse verschickt!")
        );
      });
    }, 1000);
  };

  const login = () => {
    var user = {
      mail: mail,
      password: password,
    };
    setTimeout(() => {
      loginUser(user).then((response) => {
        if (response.error) {
          setError(response.msg);
          setLoading(false);
        } else if (response === false) {
          setError(
            "Es konnte keine Verbindung zum Authentisierungsserver hergestellt werden."
          );
          setLoading(false);
        } else {
          rememberMyLoginData(rememberLogin);
          setLoginData({ token: response.token, mail: mail });
          showSuccess(1500, "").then(() => {
            props.onClose();
          });
        }
      });
    }, 1000);
  };

  const showSuccess = (timeout, msg) => {
    setError(null);
    setLoading(false);
    setSuccess(true);
    setSuccessMessage(msg);
    setPassword2("");
    setPassword("");

    return new Promise((resolve, reject) => {
      if (timeout > 0) {
        setTimeout(() => {
          setSuccess(false);
          resolve();
        }, timeout);
      } else {
        resolve();
      }
    });
  };

  if (showImpressum) {
    return (
      <div className="login-page impressum">
        <div ref={impressumRef} className="login impressum">
          <div className="login-topbar">
            <div className="topbar-logo">
              <img
                src="assets/layout/images/DashboardDeutschlandLogo.png"
                alt="Daten Dashboard Deutschland Logo"
              />
            </div>
          </div>
          <Impressum
            parentRef={impressumRef}
            onClickDatenschutz={() => {
              setShowImpressum(false);
              setShowDatenschutz(true);
            }}
          />

          <div className="backtologin-button">
            <Button
              label={"Zurück zum Login"}
              aria-label={"Zurück zum Login"}
              onClick={() => setShowImpressum(false)}
            />
          </div>
        </div>
        <CookieBanner onActive={() => {}} />
      </div>
    );
  }

  if (showDatenschutz) {
    return (
      <div className="login-page">
        <div ref={datenschutzRef} className="login datenschutz">
          <div className="login-topbar">
            <div className="topbar-logo">
              <img
                src="assets/layout/images/DashboardDeutschlandLogo.png"
                alt="Daten Dashboard Deutschland Logo"
              />
            </div>
          </div>
          <Datenschutz parentRef={datenschutzRef} />

          <div className="backtologin-button">
            <Button
              label={"Zurück zum Login"}
              aria-label={"Zurück zum Login"}
              onClick={() => setShowDatenschutz(false)}
            />
          </div>
        </div>
        <CookieBanner
          onActive={() => {}}
          onClickDatenschutz={() => setShowDatenschutz(true)}
        />
      </div>
    );
  }

  return (
    <div className="login-page">
      <div
        aria-label={"Overlay Cookiebanner"}
        className={
          (cookieBannerActive ? "overlay-cookiebanner " : "") + "overlay-fadeIn"
        }
      ></div>
      <div
        ref={wrapperRef}
        className={"login " + (cookieBannerActive ? "login-blur" : "")}
      >
        <div className="login-topbar">
          <div className="topbar-logo">
            <img
              src="assets/layout/images/DashboardDeutschlandLogo.png"
              alt="Daten Dashboard Deutschland Logo"
            />
          </div>
        </div>

        {mode === MODE_LOGIN ? (
          <div className="login-content">
            <div className="login-content-title">{l("Anmeldung")}</div>
            <div className="p-col-12">
              <span className="md-inputfield">
                <InputText
                  aria-label={l("E-Mail-Adresse")}
                  onKeyPress={keyPress}
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
                <label>{l("E-Mail-Adresse")}</label>
              </span>
            </div>
            <div className="p-col-12">
              <span className="md-inputfield">
                <Password
                  aria-label={l("Passwort")}
                  feedback={false}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyPress={keyPress}
                />
                <label>{l("Passwort")}</label>
              </span>
            </div>
            {/* <div className="p-col-12" style={{marginTop: '-10px'}}>
                                <Checkbox aria-label={l("Anmeldedaten merken")} inputId="cb1" onChange={e => setRememberLogin(e.checked)} checked={rememberLogin}></Checkbox>
                                <label htmlFor="cb1" className="p-checkbox-label">{l("Anmeldedaten merken")}?</label>
                        </div> */}
            <div className="p-col-12">
              <Button
                className="btn-login p-button-info"
                label={l("Anmelden")}
                icon="pi-md-person"
                disabled={password.length >= /*8*/ 1 ? undefined : true}
                onClick={buttonPress}
              />
            </div>
            <div className="p-col-12" style={{ marginTop: "-17px" }}>
              <button
                className="link-button"
                onClick={() => {
                  resetPassword();
                  setMode(MODE_REGISTER);
                }}
              >
                {l("Registrieren")}
              </button>{" "}
              |{" "}
              <button
                className="link-button"
                onClick={() => {
                  resetPassword();
                  setMode(MODE_FORGOTPW);
                }}
              >
                {l("Passwort vergessen")}?
              </button>
            </div>
          </div>
        ) : mode === MODE_REGISTER ? (
          <div className="login-content">
            <div className="login-content-title">{l("Registrieren")}</div>
            <div className="p-col-12">
              <span className="md-inputfield">
                <InputText
                  onKeyPress={keyPress}
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
                <label>{l("E-Mail-Adresse")}</label>
              </span>
            </div>
            <div className="p-col-12">
              <span className="md-inputfield">
                <Password
                  feedback={false}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={keyPress}
                />
                <label>{l("Passwort")}</label>
              </span>
            </div>
            <div className="p-col-12">
              <span className="md-inputfield">
                <Password
                  feedback={false}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  onKeyPress={keyPress}
                />
                <label>{l("Passwort wiederholen")}</label>
              </span>
            </div>
            <div className="p-col-12">
              <Checkbox
                aria-label={
                  "Ich habe die datenschutzrechtlichen Hinweise [Verlinkung auf die Datenschutzerklärung] gelesen und bin mit der dort beschriebenen Verarbeitung meiner personenbezogenen Daten einverstanden"
                }
                inputId="cb1"
                onChange={(e) => setAcceptPrivacy(e.checked)}
                checked={acceptPrivacy}
              ></Checkbox>
              <label htmlFor="cb1" className="p-checkbox-label">
                Ich habe die datenschutzrechtlichen Hinweise{" "}
                <button
                  className="link-button"
                  onClick={() => setShowDatenschutz(true)}
                >
                  (Datenschutzerklärung)
                </button>{" "}
                gelesen und bin mit der dort beschriebenen Verarbeitung meiner
                personenbezogenen Daten einverstanden
              </label>
            </div>
            <div className="p-col-12">
              <Button
                className="btn-login p-button-info"
                label={l("Registieren")}
                icon="pi-md-person"
                disabled={
                  password.length >= /*8*/ 6 && acceptPrivacy
                    ? undefined
                    : "disabled"
                }
                onClick={buttonPress}
              />
            </div>
            <div className="p-col-12" style={{ marginTop: "-17px" }}>
              <button
                className="link-button"
                onClick={() => {
                  resetPassword();
                  setMode(MODE_LOGIN);
                }}
              >
                {l("Anmelden")}
              </button>{" "}
              |{" "}
              <button
                className="link-button"
                onClick={() => {
                  resetPassword();
                  setMode(MODE_FORGOTPW);
                }}
              >
                {l("Passwort vergessen")}?
              </button>
            </div>
          </div>
        ) : mode === MODE_FORGOTPW ? (
          <div className="login-content">
            <div className="login-content-title">{l("Passwort vergessen")}</div>
            <div className="p-col-12">
              <span className="md-inputfield">
                <InputText
                  onKeyPress={keyPress}
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
                <label>{l("E-Mail-Adresse")}</label>
              </span>
            </div>
            <div className="p-col-12">
              <Button
                className="btn-login p-button-info"
                label={l("Passwort zurücksetzen")}
                icon="pi-md-person"
                onClick={buttonPress}
              />
            </div>
            <div className="p-col-12" style={{ marginTop: "-17px" }}>
              <button
                className="link-button"
                onClick={() => {
                  resetPassword();
                  setMode(MODE_LOGIN);
                }}
              >
                {l("Anmelden")}
              </button>{" "}
              |{" "}
              <button
                className="link-button"
                onClick={() => {
                  resetPassword();
                  setMode(MODE_REGISTER);
                }}
              >
                {l("Registrieren")}
              </button>
            </div>
          </div>
        ) : mode === MODE_CHANGEPW ? (
          <div className="login-content">
            <div className="login-content-title">{l("Passwort ändern")}</div>
            <div className="p-col-12">
              <span className="md-inputfield">
                <Password
                  feedback={false}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={keyPress}
                />
                <label>{l("Passwort")}</label>
              </span>
            </div>
            <div className="p-col-12">
              <span className="md-inputfield">
                <Password
                  feedback={false}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  onKeyPress={keyPress}
                />
                <label>{l("Passwort wiederholen")}</label>
              </span>
            </div>
            <div className="p-col-12">
              <Button
                className="btn-login p-button-info"
                label={l("Passwort ändern")}
                icon="pi-md-person"
                onClick={buttonPress}
              />
            </div>
            <div className="p-col-12" style={{ marginTop: "-17px" }}>
              <button
                className="link-button"
                onClick={() => {
                  resetPassword();
                  setMode(MODE_LOGIN);
                }}
              >
                {l("Anmelden")}
              </button>{" "}
              |{" "}
              <button
                className="link-button"
                onClick={() => {
                  resetPassword();
                  setMode(MODE_REGISTER);
                }}
              >
                {l("Registrieren")}
              </button>
            </div>
          </div>
        ) : null}

        {error !== null ? (
          <div className="p-col-12 login-error fadeIn">
            <center>
              <p>{error}</p>
            </center>
          </div>
        ) : null}

        {loading === true ? (
          <div className="login-loading-screen login-fadeIn login-absolute-center">
            <ProgressSpinner
              className="login-progress-spinner"
              animationDuration="1.5s"
            />
          </div>
        ) : null}

        {success === true ? (
          <div
            className="login-absolute-center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSuccess(false);
              setMode(MODE_LOGIN);
            }}
          >
            <SuccessIcon className="fadeIn" />
            {successMessage !== "" ? (
              <div className="login-success-message fadeIn">
                {successMessage}
              </div>
            ) : (
              <div />
            )}
          </div>
        ) : null}

        <div className="impressumdatenschutz">
          <button
            className="link-button"
            onClick={() => setShowImpressum(true)}
          >
            Impressum
          </button>
          <button
            className="link-button"
            onClick={() => setShowDatenschutz(true)}
          >
            Datenschutzerklärung
          </button>
        </div>
        <div className="login-version">{"Version: " + VERSION}</div>
      </div>
      <CookieBanner
        onActive={(active) => setCookieBannerActive(active)}
        onClickDatenschutz={() => setShowDatenschutz(true)}
      />
    </div>
  );
}
