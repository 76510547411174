import React, { useEffect, useState, useRef } from 'react';
import { WidthProvider, Responsive } from "react-grid-layout";

import { readHomeDashboard, readDashboard, deleteDashboard } from '../../app_gateway/dashboard/DataAG';
import DashboardTile from './DashboardTile';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "../../styles/dashboard.css";
import { PATH_DASHBOARD_HOME } from '../main/Routing';
import Layout from './Layout';
import { setFuncToGetLayout, setLastDashboard, getLastDashboard } from '../util/CentralStore';
import DashboardTopbar from './DashboardToolbar';
import TileChooser from './TileChooser';
import { HOME_DASHBOARD } from '../../app_gateway/Constants';
import { getUser } from '../../app_gateway/authentication/AuthenticationStore';
import ConfirmDialog from '../util/ConfirmDialog';
      
const ResponsiveReactGridLayout = WidthProvider(Responsive);

var timeout = null;
const keycodes = [38, 37, 40, 39];

export default function Dashboard(props) //extends React.Component 
{
  const [layouts, setLayouts] = useState({});
  const [tileChooserVisible, setTileChooserVisible] = useState(false)
  const [layout, setLayout] = useState([])
  const [toggle, setToggle] = useState(false);
  const [tiles, setTiles] = useState([]);
  const [renderIndex, setRenderIndex] = useState(0)
  const [editable, setEditable] = useState(true);
  const [owner, setOwner] = useState(true);
  const [activeElement, setActiveElement] = useState(null);

  const confirmDialog = useRef(null);

  var dashboard = null;

  useEffect(() => {
    readMyDashboard();
    if(props.innerContent !== null)
    {
      setTimeout(() => props.innerContent.scrollTop = 0,1)
    }
  }, [props.location.pathname])

  /*componentDidMount()
  {
    this.readMyDashboard(id);
  }*/

  const readMyDashboard = () =>
  {
    var dashboardToRead = props.match.params.id;

    if(dashboardToRead === HOME_DASHBOARD)
    {
      readHomeDashboard().then(response => {
        dashboard = response;
        if(dashboard.layout !== undefined && dashboard.layout !== null)
        {
          setLastDashboard(dashboard);
          setLayouts({lg: dashboard.layout.map((r, i) => {return {
            x: r.x,
            y: r.y,
            w: r.width,
            h: r.height,
            i: r.tile.id,
            isDraggable: dashboard.editable,
            isResizable: dashboard.editable
          }})})
          setLayout(dashboard.layout);
          setTiles(dashboard.layout.map(r => r.tile.id));
          setEditable(dashboard.editable);
          setOwner(dashboard.owner);
          //createTiles(dashboard.layout)
        }
      });
    }
    else
    {
      readDashboard(dashboardToRead).then(response => {
        dashboard = response;
        if(dashboard.layout !== undefined && dashboard.layout !== null)
        {
          setLastDashboard(dashboard);
          setLayouts({lg: dashboard.layout.map((r, i) => {return {
            x: r.x,
            y: r.y,
            w: r.width,
            h: r.height,
            i: r.tile.id,
            isDraggable: dashboard.editable,
            isResizable: dashboard.editable
          }})})
          setLayout(dashboard.layout);
          setTiles(dashboard.layout.map(r => r.tile.id));
          setEditable(dashboard.editable);
          setOwner(dashboard.owner);
          //createTiles(dashboard.layout)
        }
        else
        {
          props.history.push(PATH_DASHBOARD_HOME);
        }
      });
    }
  }
  
  setFuncToGetLayout(() => {
    return layouts;
  });

  const removeTile = (id) =>
  {
    var l = layout.filter(c => c.tile.id !== id);
    setTiles(l.map(r => r.tile.id));
    //createTiles(l);
    setLayout(l);
  }  
  
  const getValidValue = (value, defaultVal) =>
  {
    if(value > 0)
      return value;
    return defaultVal;
  }

  /*const createTiles = (l) => 
  {
    var c = l.map((r, i) => <div aria-label={r.tile.title} onFocus={() => setActiveElement(r.tile.id)} tabIndex={0} className="fadeIn" key={r.tile.id} index={renderIndex} data-grid={{ 
      x: r.x, 
      y: r.y, 
      w: getValidValue(r.width, 3), 
      h: getValidValue(r.height, 3), 
      i: r.tile.id,
      //minW: getValidValue(r.tile.minWidth, undefined), 
      //minH: getValidValue(r.tile.minHeight, undefined), 
      //maxW: getValidValue(r.tile.maxWidth, undefined), 
      //maxH: getValidValue(r.tile.maxHeight, undefined), 
      static: !getLastDashboard().editable }}
      style={{zIndex:'-1'}}>
        <DashboardTile id={r.tile.id} onClose={editable ? (id) => removeTile(id, l) : undefined} attachments={r.tile.attachments} date={r.tile.date} enableNotification />
    </div>);
    setRender(c);

  }*/


  const onDrop = (layout, layoutItem, event) => {
  };

  const addTile = (tile) => {
    var l = layout;
    var freeSpace = findNextFreeSpace(tile.width, tile.height);
    var newLayout = {
      x: freeSpace.x,
      y: freeSpace.y,
      width: tile.width,
      height: tile.height,
      tile: tile
    }
    l.push(newLayout);
    var t = tiles;
    t.push(tile.id);
    setTiles(t);
    setLayout(l);

    setRenderIndex(renderIndex+1);
  }

  const findNextFreeSpace = (width, height) => {
    if(width > Layout.cols)
      width = Layout.cols;
    for(var y = 0; y <= 100; y++)
    {
      for(var x = 0; x <= Layout.cols-width; x++)
      {

        let tile = getTile(x, y);

        if(!tile)
        {
          let newTile;
            for(var yy = y; yy <= y + height-1; yy++)
            {
              for(var xx = x; xx <= x+width-1; xx++)
              {
                newTile = getTile(xx, yy);
                if(newTile || xx > 10)
                {
                  newTile = true;
                  break;
                }
              }
              if(newTile)
                break;
            }
            if(newTile === undefined)
            {
              return {x: x, y: y};
            }
          
        }
        else
        {
          x+= tile.x - x + tile.w - 1;
        }
      }
    }
    return {x: 0, y: 100};
  }

  const getTile = (x, y) => {
    for(var i = 0; i < layouts.lg.length; i++)
    {
      let tile = layouts.lg[i];
      if((tile.x <= x && tile.y <= y) && //Gleiche Position
        (tile.x + tile.w > x && tile.y + tile.h > y) //Innerhalb
      )
      {
        return tile;
      }
    }
  }

  const onMouseLeave = () =>
  {
      /*timeout = setTimeout(() => {
        setTileChooserVisible(false);
        setToggle(false);
      }, 1000);*/
  }

  const onMouseEnter = () =>
  {
      clearInterval(timeout);
  }

  const onDelete = () => {
    confirmDialog.current.showYesNo("Dashboard löschen", 
      <div>Möchten Sie das Dashboard wirklich löschen?</div>, 
      () => {
        deleteDashboard(getLastDashboard().id).then(r => {
          if(r === true)
            window.location = "#" + PATH_DASHBOARD_HOME;
        }) 
      });
  }

  const isDeleteable = () => 
  {
    if(editable)
    {
      let user = getUser();
      if(user !== undefined && user !== null)
      {
        if(user.mail === owner.mail)
        {
          return true;
        }
      }
    }
    return false;
  }

  const setKeyDown = (e, id) => {
    let s = e.shiftKey;
    let c = e.ctrlKey;
    if(id && id !== null && keycodes.includes(e.keyCode) && (s || c))
    {
      e.preventDefault();
      for(let breakpoint in layouts)
      {
        let tile = layouts[breakpoint].find(e => e.i === id);
        if(tile)
        {
          switch(e.keyCode)
          {
            //UP
            case 38: s ? (tile.h > 1 ? tile.h = tile.h - 1 : tile.h = 1) : tile.y > 0 ? tile.y = tile.y - 1 : tile.y = 0; break;
            //LEFT
            case 37: s ? (tile.w > 1 ? tile.w = tile.w - 1 : tile.w = 1) : tile.x > 0 ? tile.x = tile.x - 1 : tile.x = 0;  break;
            //DOWN
            case 40: s ? tile.h = tile.h + 1 : tile.y = tile.y + 1; break;
            //RIGHT
            case 39: s ? tile.w = tile.w < Layout.cols ? tile.w + 1 : tile.w = Layout.cols : tile.x + tile.w < Layout.cols ? tile.x = tile.x + 1 : tile.x = Layout.cols - tile.w; break;
            default: break;
          }
        }
        let l = layouts[breakpoint].filter(e => e.id !== id);
        l.push(tile);
        layouts[breakpoint] = l;
      }
  setLayouts({...layouts})
    }
  }

  let l = JSON.parse(JSON.stringify(layouts));

    return (
      <div onKeyDown={(e) => setKeyDown(e, activeElement)}>
        <ConfirmDialog ref={confirmDialog} />
        <DashboardTopbar pathid={props.match.params.id} printDisabled={layout === null || layout.length === 0} addTile={editable ? {onClick: () => setTileChooserVisible(!tileChooserVisible), toggle: toggle, setToggle: () => setToggle(!toggle)} : undefined} onDelete={isDeleteable() ? onDelete : undefined} />
        <TileChooser visible={tileChooserVisible} onChoose={tile => addTile(tile)} selectedTiles={tiles} onHide={() => {setTileChooserVisible(false); setToggle(false)}}  onMouseLeave={() => onMouseLeave()} onMouseEnter={() => onMouseEnter()} />
        <ResponsiveReactGridLayout id={"renderme"} className={"layout"} style={{zIndex:'0'}}
          onLayoutChange={(layout, ls) =>
            {
              window.dispatchEvent(new Event('resize'));
              setLayouts(ls)}
            }
          layouts={l}
          measureBeforeMount={false}
          compactType={'vertical'}
          rowHeight={Layout.rowHeight}
          breakpoints={Layout.breakpoints}
          cols={Layout.layout}
          onDrop={onDrop}
          isDroppable={false}
          draggableHandle=".sticky-header"
          draggableCancel={".card-title-functions,.card-title-category-icon"}
          {...props}
        >
          {layout.filter(r => r.tile.hidden === false).map((r, i) => <div aria-label={r.tile.title} onFocus={() => setActiveElement(r.tile.id)} tabIndex={0} key={r.tile.id} index={renderIndex} data-grid={{
            x: r.x,
            y: r.y,
            w: getValidValue(r.width, 3),
            h: getValidValue(r.height, 3),
            i: r.tile.id,
            //minW: getValidValue(r.tile.minWidth, undefined),
            //minH: getValidValue(r.tile.minHeight, undefined),
            //maxW: getValidValue(r.tile.maxWidth, undefined),
            //maxH: getValidValue(r.tile.maxHeight, undefined),
            static: !getLastDashboard().editable }}
            style={{zIndex:'-1'}}>
              <DashboardTile id={r.tile.id} onClose={editable ? (id) => removeTile(id) : undefined} attachments={r.tile.attachments} date={r.tile.date} enableNotification />
          </div>)}
        </ResponsiveReactGridLayout>
      </div>

    );
}

