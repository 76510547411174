import React, { useEffect, useState, useLayoutEffect } from 'react'
import { readTiles } from '../../app_gateway/dashboardtile/TileAG';
import DashboardTile from '../dashboard/DashboardTile';
import RGL, { WidthProvider } from "react-grid-layout";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import Legend from './Legend';

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "../../styles/dashboard.css";
import "../../styles/printboard.css";
import "jspdf/dist/polyfills.es.js";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import PrintToolbar from './PrintToolbar';
import { getCachedCategories } from '../../app_gateway/category/CategoryAG';
import {ProgressBar} from 'primereact/progressbar';
import { savePrintLayout } from '../../app_gateway/printlayout/PrintLayoutAG';
import { getUser } from '../../app_gateway/authentication/AuthenticationStore';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { getMyPrintlayouts } from '../../app_gateway/store/MyDashboardsStore';
import { showGrowlError, showGrowlSuccess } from '../main/Main';

const ReactGridLayout = WidthProvider(RGL);
const formatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
const formatOptionsFooter = { year: 'numeric', month: '2-digit', day: '2-digit' };

var cancelPrinting = false;
var nextY1 = 0;
var nextY2 = 0;

export default function PrintPreview(props) {

    const [cards, setCards] = useState(null);
    const [layout, setLayout] = useState([{i: "data_aktuelles", w: 2, h: 5, x: 0, y: 0, hideComponents: []}]);
    const [loading, setLoading] = useState(false);
    const [printLoading, setPrintLoading] = useState(false);
    const [showOverrideLabel, setShowOverrideLabel] = useState(false);
    const [renderReady, setRenderReady] = useState(true);
    const [sortByCat, setSortByCat] = useState(false);
    const [allHideComponents, setAllHideComponents] = useState(new Map());
    const [commonWidth, setCommonWidth] = useState(2);
    const [printMax, setPrintMax] = useState(0);
    const [autoTileHeight, setAutoTileHeight] = useState([]);
    const [tileRefs, setTileRefs] = useState(new Map())
    const [printState, setPrintState] = useState(0);
    const [simplePageLayout, setSimplePageLayout] = useState(true);
    const [hideAllTexts, setHideAllTexts] = useState(false);
    const [layoutName, setLayoutName] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [title, setTile] = useState(null);
    const [myPrintLayouts, setMyPrintLayouts] = useState([]);
    const [myPrintTitles, setMyPrintTitles] = useState(new Map());
    const [printTile, setPrintTile] = useState("");
    const [printDesc, setPrintDesc] = useState("Erstelle Druckversion:");

    useEffect(() => {
        readMyTiles();
    }, [props.match.params.id])

    useEffect(() => {
        if(!renderReady) {
                if(layout !== null && layout.length > 0)
                {
                    var reflow = false;
                    var newLayout = layout.map(card => {
                        var div = document.getElementById(card.i);
                        if(div !== null)
                        {
                            var hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
                            if(hasVerticalScrollbar && card.h < 60)
                            {
                                card = {...card, h: card.h + 4};
                                reflow = true;
                            }
                        }
                        return card;
                    })
                    if(reflow)
                    {
                        setLoading(true)
                        setLayout([...newLayout]);
                    }
                    else
                    {
                        setLoading(false);
                        setRenderReady(true);
                    }
                }
        }
    }, [layout])

    const readMyTiles = () =>
    {
        getMyPrintlayouts().then(response => {
            if(response) {
                setMyPrintLayouts(response);
                let titles = new Map();
                response.forEach(a => titles.set(a.name.toUpperCase(), a));
                setMyPrintTitles(titles);
            }
            else {
                setMyPrintTitles(new Map());
                setMyPrintLayouts([]);
            }
            var tilesToRead = props.match.params.id
            if(tilesToRead.startsWith("storedLayout:")){
                var foundPrintLayout = response.find(a => a.id == tilesToRead.split(":")[1]);
                if(foundPrintLayout != null) {
                    loadLayout(foundPrintLayout.name, JSON.parse(foundPrintLayout.printLayout))
                }
                else {

                }
            }
            else {
                readTiles(tilesToRead).then(response => {
                    var ts =props.match.params.id.split(",");
                    response = response.sort((a, b) => ts.findIndex(e => e === a.id) - ts.findIndex(e => e === b.id))
                    setNewCards(response);
                });
            }
        });
    }

    const getValidValue = (value, defaultVal) =>
    {
        if(value > 0)
        return value;
        return defaultVal;
    }

    const setElementsIgnorePrinting = (elementsToIgnore) => {
        elementsToIgnore.forEach((el) => {
            let list = document.getElementsByClassName(el);
            for (let item of list) {
                item.setAttribute("data-html2canvas-ignore", true);
            }
        })
    }

    const doPrint = () => {
        setPrintLoading(true);
        setTimeout(() => print(), 0);
    }

    const print = async () =>
    {
        setElementsIgnorePrinting(["highcharts-reset-zoom", "highcharts-exporting-group"])

        layout.sort((a, b) => a.y - b.y);
        var tilesToPrint = [{i: "print-legend", w: 2, x: 0, y:-1}, ...layout];
        setPrintMax(tilesToPrint.length);
        setPrintState(0);
        setPrintTile("Legende");
        let categories = [...new Set(cards.map(c => c.category))];
        let legendHeight = Math.round(categories.length / 2);
        let firstPageHeight = 26 - legendHeight;
        
        const pdf = new jsPDF('p', 'mm', 'a4', 2);
        const pageHeight= pdf.internal.pageSize.height;
        var y1 = 10;
        var y2 = 10;
        var pageY1 = 1;
        var pageY2 = 1;
        var pageNumber = 1;

        let addHeader = () => {
            var img = new Image()
            img.src = 'assets/layout/images/DashboardDeutschlandLogo.png'
            pdf.addImage(img, 'PNG', 82.5, y1, 45, 15, undefined, 'FAST');
            //var img2 = new Image()
            //img2.src = 'assets/layout/images/3dTitle.png'
            //pdf.addImage(img2, 'PNG', 65, y, 100, 10, undefined, 'FAST');
            
            pdf.setTextColor(68,68,68);
            pdf.setFont("helvetica");
            pdf.setFontSize(9);
            pdf.text(87, 29, "Stand: "+new Date().toLocaleDateString('de-DE', formatOptions));
            y1 += 25;
            y2 += 25;
        }

        let addFooter = (pageNumber) => {
            pdf.setDrawColor("#AAA")
            pdf.line(10, 281, 200, 281);

            var img = new Image()
            img.src = 'assets/layout/images/DashboardDeutschlandLogo.png'
            pdf.addImage(img, 'PNG', 99, 282, 12, 4, undefined, 'FAST');

            pdf.setTextColor(170,170,170);
            pdf.setFont("helvetica");
            pdf.setFontSize(8);
            pdf.text(pageNumber > 99 ? 185 : pageNumber > 9 ? 187 : 190, 284, "Seite "+pageNumber);
            pdf.text(10, 284, new Date().toLocaleDateString('de-DE', formatOptionsFooter));
        }

        let nextPage = () => {
            if(pdf.getNumberOfPages() > pdf.getCurrentPageInfo().pageNumber)
            {
                pdf.setPage(pdf.getCurrentPageInfo().pageNumber + 1)
            }
            else
            {
                pdf.addPage();
                pageNumber += 1;
                addFooter(pageNumber);

            }
        }

        let setPage = (number) => {
            let numberOfPages = pdf.getNumberOfPages();
            if(numberOfPages >= number)
            {
                pdf.setPage(number)
            }
            else
            {   
                let start = numberOfPages + 1;
                let addPages = number - numberOfPages;
                for(let i = 0; i < addPages; i++) {
                    pdf.addPage();
                    addFooter(start + i);
                }
                pdf.setPage(number);
            }
        }

        let checkNextPage = (y, height) => {
            if (y + height >= pageHeight - 16)
            {
                nextPage();
                return true;
            }
            return false;
        }

        let checkSplitImage = (tileToPrint, y, height) => {
            if(height >= pageHeight - 16)
            {
                return true;
            }
            return false;
        }

        let getCalculatedPageAndY = (y) => {
            let page = 1;
            let pageY = (y + 4 + legendHeight) % 30;
            page = Math.floor((y + 4 + legendHeight) / 30) + 1;
            let pxY = pageY * 9.2 + 5;  
            return {page: page, y: pxY, pageY: pageY};
        }

        let addY1Indicator = async (tileToPrint) => {
            let image = await grabImage(tileToPrint);
            pdf.setPage(pageY1);
            if(checkSplitImage(tileToPrint, y1, image.height))
            {
                let grabHeight = pageHeight - y1 - 30;
                let image1 = await grabImage(tileToPrint, null, grabHeight * 5.6);
                pdf.addImage(image1.img, 'PNG', 17, y1, 88, image1.height, undefined, 'FAST');
                nextPage();
                y1 = 5;
                pageY1++;
                let image2 = await grabImage(tileToPrint, grabHeight * 5.6, null);
                pdf.addImage(image2.img, 'PNG', 17, y1, 88, image2.height, undefined, 'FAST');
                y1+=(image2.height + 2);
            }
            else
            {
                if (checkNextPage(y1, image.height))
                {
                    pageY1++;
                    y1 = 5;
                }
                pdf.addImage(image.img, 'PNG', 17, y1, 88, image.height, undefined, 'FAST');
                y1+=(image.height + 2);
            }
            
        }
        
        let addY2Indicator = async (tileToPrint) => {
            let image = await grabImage(tileToPrint);
            pdf.setPage(pageY2);
            // tileToPrint.y;
            if(checkSplitImage(tileToPrint, y2, image.height))
            {
                let grabHeight = pageHeight - y2 - 30;
                let image1 = await grabImage(tileToPrint, null, grabHeight * 5.6);
                pdf.addImage(image1.img, 'PNG', 17, y2, 88, image1.height, undefined, 'FAST');
                nextPage();
                y2 = 5;
                pageY2++;
                let image2 = await grabImage(tileToPrint, grabHeight * 5.6, null);
                pdf.addImage(image2.img, 'PNG', 17, y2, 88, image2.height, undefined, 'FAST');
                y2+=(image2.height + 2);
            }
            else
            {
                if (checkNextPage(y2, image.height))
                {
                    pageY2++;
                    y2 = 5;
                }
                pdf.addImage(image.img, 'PNG', 109, y2, 88, image.height, undefined, 'FAST');
                y2+=(image.height + 2);
            }
        } 
        
        let addSimpleIndicator = async (tileToPrint) => {
            let image = await grabImage(tileToPrint);
            let page = pageY1 > pageY2 ? pageY1 : pageY2;
            let y = pageY1 > pageY2 ? y1 : pageY1 < pageY2 ? y2 : y1 > y2 ? y1 : y2;
            // tileToPrint.y;
            if (checkNextPage(y, image.height))
            {
                page++;
                y = 5;
            }
            pdf.addImage(image.img, 'PNG', 17, y, 180, image.height, undefined, 'FAST');
            y+=(image.height + 2);
            y1=y;
            y2=y;
            pageY1=page;
            pageY2=page;
        } 
        
        let addIndicator = async (tileToPrint, myImage, myHeight) => {

            let pageY = getCalculatedPageAndY(tileToPrint.y);
            let page = pageY.page;
            setPage(page);
            let width = tileToPrint.w === 1 ? 88 : 180; 
            let x = tileToPrint.x === 1 ? 109 : 17; 

            if(pageY.pageY + tileToPrint.h > 30) {
                let pages = Math.floor((pageY.pageY + tileToPrint.h) / 30) + 1;
                let grabY = 0;
                let grabHeight = 30 - pageY.pageY;
                let image = await grabImage(tileToPrint, null, grabHeight * 49);
                pdf.addImage(image.img, 'PNG', x, pageY.y, width, image.height, undefined, 'FAST');
                setPage(page+1);
                grabY = grabHeight;
                grabHeight = tileToPrint.h;
                image = await grabImage(tileToPrint, grabY * 49, null);
                pdf.addImage(image.img, 'PNG', x, 3, width, image.height, undefined, 'FAST');
                if(pages > 2)
                {
                }
                
            }
            else {
                // let usePage = tileToPrint.y % page === 1 ? 26 : 30;
                // let page = pageY1 > pageY2 ? pageY1 : pageY2;
                let image = await grabImage(tileToPrint);

                // let y = pageY1 > pageY2 ? y1 : pageY1 < pageY2 ? y2 : y1 > y2 ? y1 : y2;
                // if (checkNextPage(y, myHeight))
                // {
                //     page++;
                //     y1 = 5;
                //     y2 = 5;
                //     y = 5;
                // }
                pdf.addImage(image.img, 'PNG', x, pageY.y, width, image.height, undefined, 'FAST');
                // y+=(myHeight + 2);
                // y1=y;
                // y2=y;
                // pageY1=page;
                // pageY2=page;
            }
            
        } 

        let addLegend = async (tileToPrint) => {
            let image = await grabImage(tileToPrint);
            pdf.setPage(1);
            let y = y1;
            pdf.addImage(image.img, 'PNG', 17, 35, 180, image.height, undefined, 'FAST');
            y+=(image.height + 2);
            y1=y;
            y2=y;
        } 

        let grabImage = async (tileToPrint, y, height) => {
            const tile = document.getElementById(tileToPrint.i);
            let split = {};
            if(height != null) {
                split = {
                    height: height,
                }
            }
            if(y != null) {
                split = {
                    y: tile.getBoundingClientRect().top + y,
                    height: height ? height : tile.getBoundingClientRect().height - (y)
                }
            }
            const canvas = await html2canvas(tile, {...split,
                // width: tileToPrint.w === 1 ? 490 : 1001,
                // height: 100,
                useCORS: true, 
                imageTimeout:0,
                scale: 2,
                letterRendering: 1, 
                allowTaint: true,
                taintTest: false,
                backgroundColor: "white",
                onclone: function(document) {
                    var list = document.getElementsByClassName("card-title");
                    for (let item of list) {
                        item.style.fontWeight = 'bold'
                        item.style.borderTop = '1px solid gray';
                    }
                    list = document.getElementsByClassName("card");
                    for (let item of list) {
                        item.style.borderLeft = '1px solid gray';
                        item.style.borderBottom = '1px solid gray';
                        item.style.borderRight = '1px solid gray';
                        item.classList.remove("fadeIn");
                    }
                    
                    list = document.getElementsByClassName("tile-source-upload-icon");
                    for (let item of list) {
                        item.style.marginRight = '2px';
                        item.style.paddingBottom = '2px';
                        item.width = '20px'
                    }
                }});

            var myImage = canvas.toDataURL();
            return {img: myImage, height: canvas.height / 10.867};
        }

        addHeader();
        addFooter(1);
        for (let i = 0; i < tilesToPrint.length; i++) {
            const tileToPrint = tilesToPrint[i];
            setPrintState(i + 1);
            var printTile = cards.find(e => e.id === tileToPrint.i);
            if(printTile === undefined)
                printTile = "Legende";
            else
                printTile = printTile.title;
            setPrintTile(printTile);
            setPrintDesc("Erstelle Druckversion:");
            if(cancelPrinting)
                break;
            // const tile = document.getElementById(tileToPrint.i);
            // const canvas = await html2canvas(tile, {
            //     width: tileToPrint.w === 1 ? 490 : 1001,
            //     height: 50,
            //     useCORS: true,
            //     imageTimeout:0,
            //     scale: 2,
            //     letterRendering: 1, 
            //     allowTaint: true,
            //     taintTest: false,
            //     backgroundColor: "white",
            //     onclone: function(document) {
            //         var list = document.getElementsByClassName("card-title");
            //         for (let item of list) {
            //             item.style.fontWeight = 'bold'
            //             item.style.borderTop = '1px solid gray';
            //         }
            //         list = document.getElementsByClassName("card");
            //         for (let item of list) {
            //             item.style.borderLeft = '1px solid gray';
            //             item.style.borderBottom = '1px solid gray';
            //             item.style.borderRight = '1px solid gray';
            //             item.classList.remove("fadeIn");
            //         }
                    
            //         list = document.getElementsByClassName("tile-source-upload-icon");
            //         for (let item of list) {
            //             item.style.marginRight = '2px';
            //             item.style.paddingBottom = '2px';
            //             item.width = '20px'
            //         }
            //     }});

            // var myImage = canvas.toDataURL();
            //window.open(myImage);
            // var myHeight =  canvas.height / 10.867;
            if(tileToPrint.i === "print-legend")
                await addLegend(tileToPrint)
            else {
                if(simplePageLayout) {
                    if(tileToPrint.w === 1) {
                        if(tileToPrint.x === 1) {
                            await addY2Indicator(tileToPrint);
                        } 
                        else {
                            await addY1Indicator(tileToPrint);
                        }
                    }
                    else {
                        await addSimpleIndicator(tileToPrint);
                    }
                }
                else {
                    await addIndicator(tileToPrint);
                }

                // if(tileToPrint.w === 1) {
                //     if(tileToPrint.x === 1) {
                //         await addY2Indicator(tileToPrint);
                //     } 
                //     else {
                //         await addY1Indicator(tileToPrint);
                //     }
                // }
                // else {
                // }
            }
        }
        setPrintDesc("PDF-Dokument fertigstellen");
        if(!cancelPrinting)
            pdf.save('Dossier-' + new Date().toLocaleDateString('de-DE', formatOptionsFooter) + '.pdf');
        setPrintLoading(false);
        setPrintState(0);
        cancelPrinting = false;
    }

    const onLayoutChange = (layout) => {
        setLayout(layout);
    }

    const sort = () => {
        var tmp = cards;
        if(sortByCat)
        {
            setTimeout(() => {
                var ts =props.match.params.id.split(",");
                tmp = tmp.sort((a, b) => ts.findIndex(e => e === a.id) - ts.findIndex(e => e === b.id))
                setNewCards(tmp)
            }, 0)
        }
        else
        {
            getCachedCategories().then((cats) => {
                tmp = tmp.sort((a, b) => getCat(cats, a.category).catIndex - getCat(cats, b.category).catIndex);
                setNewCards(tmp);
            })
        }
        setSortByCat(!sortByCat);
    }

    const getCat = (cats, name) => {
        return cats.filter(c => c.name === name)[0];
    }

    const setWidthForAllTiles = (width) => {
        let newLayout = [...layout];
        newLayout.forEach((l, i) => {
            l.w = width;
            l.x = width === 2 ? 0 : i % 2
        })
        setCommonWidth(width);
        setNewLayout(newLayout);
        window.dispatchEvent(new Event('resize'));
        setTimeout(() => {
            tileRefs.forEach((k, v) => checkHeight(v, k))
        }, 300)
    }

    const getTileHeight = (tile) => {
        let autoHeight = autoTileHeight.find(t => t.id === tile.id);
        if(autoHeight != null)
            return autoHeight.height;
        return getValidValue(tile.height, 3) * 4;
    }

    const setNewLayout = (layout) => {
        setLayout([...layout, {
            i: 'dummy',
            x: 0,
            y: 0,
            w: 0,
            h: 0
          }]);
        setTimeout(() => setLayout(layout), 0);
    }

    const setNewCards = (cards) => {
        setCards(cards);
        nextY1 = 0;
        nextY2 = 0;
        let layout = cards.map((c, i) => {
            let h = getTileHeight(c);
            let y; 
            if(commonWidth === 1 && i % 2 === 1) {
                y = nextY2;
                nextY2+=h;
            }
            else {
                y = nextY1;
                nextY1+=h;
            }
            return {
                i: c.id,
                x: commonWidth === 1 ? i % 2 : 0, 
                y: y, 
                w: commonWidth, 
                h: h, 
                minW: 1, 
                static: false,
                hideComponents: []
            }
        })
        setNewLayout(layout);
    }

    const checkHeight = (id, ref) => {
        if(ref != null && ref.scrollHeight > ref.clientHeight) {
            // setTileRefs([...tileRefs, id]);
            let newLayout = layout.map((l, i) => {
                if(l.i === id) {
                    return {...l, h: (ref.scrollHeight + 35) / 48}
                }
                return l;
            });
            setNewLayout(newLayout)
        }
    }

    const setMyTileRef = (id, ref) => {
        let firstSet = ref != null && !tileRefs.has(id);
        let myTileRefs = tileRefs;
        myTileRefs.set(id, ref);
        setTileRefs(myTileRefs);
        if(firstSet)
        checkHeight(id, ref);
    } 

    const doHideAllTexts = (hide) => {
      setHideAllTexts(hide);
    }

    const onHideComponents = (id, components) => {
        let hc = allHideComponents;
        hc.set(id, components);
        setAllHideComponents(hc);
    }

    const loadLayout = (name, layoutToLoad) => {
        setLoading(true);
        let layout = layoutToLoad.layout;
        let tilesToRead = layout.map(l => l.i).join(",");

        setSimplePageLayout(layoutToLoad.simplePageLayout);
        setCommonWidth(layoutToLoad.commonWidth);
        setHideAllTexts(layoutToLoad.hideAllTexts);
        setTile(name)

        readTiles(tilesToRead).then(response => {
            let hideComponents = new Map();
            for(let i = 0; i < layout.length; i++)
            {   
                hideComponents.set(layout[i].i, layout[i].hideComponents);
            }
            setCards(response);
            setAllHideComponents(hideComponents);
            setTimeout(() => {
                setNewLayout(layout);
                setLoading(false);
            }, 100);
        });
    }

    const saveLayout = () => {
      let saveLayout = {
        simplePageLayout: simplePageLayout,
        commonWidth: commonWidth,
        hideAllTexts: hideAllTexts,
        layout: layout.map(l => {
            l.hideComponents = [];    
            if(allHideComponents.has(l.i)){
                l.hideComponents = allHideComponents.get(l.i);
            }
            return l;
          })
      };
      
      setShowDialog(false);
      savePrintLayout(getUser().mail, layoutName, saveLayout).then(response => {
        if(response)
            showGrowlSuccess("Drucklayout gespeichert", "Das Drucklayout wurde erfolgreich gespeichert.");
        else
            showGrowlError("Fehler beim Speichern", "Beim Speichern des Drucklayouts ist ein Fehler aufgetreten.");
      })
    }

    const footer = (
        <div>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => setShowDialog(false)} />
            <Button label="Speichern" disabled={layoutName.length === 0} icon="pi pi-check" onClick={saveLayout} />
        </div>
    );


    return (
        <div>
            <Dialog header="Drucklayout - Name" visible={showDialog} style={{ width: '50vw' }} footer={footer} onHide={() => setShowDialog(false)}>
                <div className="printlayout-save-dialog">
                    <p>Bitte geben Sie einen Namen für das Drucklayout an:</p>
                    <InputText value={layoutName} maxLength={50} onChange={e => {
                        setLayoutName(e.target.value);
                        let upper = e.target.value.toUpperCase();
                        setShowOverrideLabel(e.target.value != title && myPrintTitles.has(upper));
                    }} className="printlayout-save-dialog-input"/>
                    <p className="printlayout-save-dialog-overridelabel" style={{visibility: showOverrideLabel ? 'visible' : 'hidden'}}>Sie haben bereits ein Drucklayout unter diesem Namen gespeichert.<br />Das vorhandene Layout wird beim Speichern überschrieben.</p>
                </div>
            </Dialog>
            <PrintToolbar
                onSort={{label: sortByCat ? "Nach Vorauswahl sortieren" : "Nach Kategorie sortieren", onClick: () => {sort()}}} 
                onPrint={!printLoading ? () => doPrint() : undefined} 
                onSaveLayout={() => {setShowOverrideLabel(false); setLayoutName(title != null ? title : ""); setShowDialog(true)}}
                onPrintCancel={printLoading ? () => {cancelPrinting = true;setPrintLoading(false)} : undefined} 
                pageLayout={!simplePageLayout}
                commonWidth={commonWidth}
                onSetCommonWidth={cw => setWidthForAllTiles(cw)}
                hideAllTexts={hideAllTexts}
                onHideAllTexts={hide => doHideAllTexts(hide)}
                onPageLayout={pageLayout => setSimplePageLayout(!pageLayout)}/>
            <div className={"printboard" + (loading || printLoading ? "-blur" : "")}>
                <div className="printboard-title">
                    <div className="printboard-title-center">{"Druck-Vorschau" + (title != null ? (": " + title) : "") }</div>
                    <div className="printboard-title-right">
                    </div>
                </div>
                <div className="printboard-container">
                    <div className="printboard-review">
                        {cards !== null ? <Legend cats = {cards.map(c => c.category)} /> : null}
                        {cards !== null && <div id="printboard-toprint" className="printboard-toprint">
                            <ReactGridLayout id="printboard" className={"layout printlayout"} style={{zIndex:'0', backgroundColor: 'white', width: '1000px'}}
                                onLayoutChange={onLayoutChange}
                                layout={layout}
                                isResizable={true}
                                autoSize={true}
                                rowHeight={40}
                                cols={2}
                                draggableHandle=".sticky-header"
                                draggableCancel={".card-title-functions,.card-title-category-icon"}
                                compactType={simplePageLayout ? "vertical" : null}
                                // preventCollision={!simplePageLayout}
                                {...props}
                            >
                                {cards.map((tile, i) => {
                                    return <div className="fadeIn" key={tile.id}
                                    // data-grid={{
                                    // x: commonWidth === 1 ? i % 2 : 0,
                                    // y: i,
                                    // w: commonWidth,
                                    // h: getTileHeight(tile),
                                    // minW: 1,
                                    // static: false }}
                                    style={{zIndex:'-1', height: '20px'}}>
                                        <DashboardTile id={tile.id} gridRef={ref => setMyTileRef(tile.id, ref)} audited={tile.audited} layout={tile.dashboardFormat} attachments={tile.attachments} date={tile.date} instantLoad onlyPrintable viewOptions hideAllTexts={hideAllTexts} hideComponents={allHideComponents.get(tile.id)} onHideComponents={hc => onHideComponents(tile.id, hc)} isOnPrintView={true}/>
                                    </div>})}
                                
                            </ReactGridLayout>
                        </div>}
                    </div>
                    {!simplePageLayout && <div className="printboard-pageindicator">
                        <div className="printboard-firstpage" />
                        <div className="printboard-nextpages" />
                    </div>}
                </div>
            </div>
            {printLoading ? 
                <div className="print-preview-loading-overlay">
                    <div className="print-preview-loading-overlay-title">
                        <div style={{width: '500px'}}><ProgressBar value={printState / printMax * 100} /></div>
                        <div>{printDesc}</div>
                        <div style={{maxWidth: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{printTile}</div>
                        {/*<ProgressSpinner className="login-progress-spinner" animationDuration="1.5s" />Bitte warten...*/}
                    </div>
                </div> : null}
            {loading ? 
            <div className="print-preview-loading-overlay">
                <div className="print-preview-loading-overlay-title">
                    <ProgressSpinner className="login-progress-spinner" animationDuration="1.5s" />Bitte warten...
                </div>
            </div> : null}
        </div>
        );
}
