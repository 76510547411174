import React, { useRef, useState, useEffect } from 'react'
import { WEB_URL } from '../../../app_gateway/Constants';
import { PATH_PDFVIEWER } from '../../main/Routing';
import CardHeaderMenuEntry from './CardHeaderMenuEntry';
import { readFile } from '../../../app_gateway/attachment/AttachmentAG';
import { mdiFileOutline, mdiFilePdfOutline, mdiFileWordOutline, mdiFileExcelOutline, mdiFilePowerpointOutline, mdiFolderZipOutline } from '@mdi/js'
import { ProgressSpinner } from 'primereact/progressspinner';

export default function AttachmentMenu(props) {

    const wrapperRef = useRef(null);

    const [showMenu, setShowMenu] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    useEffect(() => {
      if(showMenu)
        document.addEventListener("mousedown", handleClickOutside);
      else
        document.removeEventListener("mousedown", handleClickOutside);

    }, [showMenu])



    return (
        props.attachments !== null && props.attachments.length > 0 ?
        
        <div className="btn-card-title-menu" ref={wrapperRef} data-html2canvas-ignore="true">
            <button aria-label={"Anhang einsehen"} className={"p-link"} onClick={() => setShowMenu(!showMenu)}>
                {
                  loading ? <ProgressSpinner className="progress-spinner-anim-disabled" style={{width: '25px', height: '25px'}} strokeWidth="5" animationDuration="1.5s"/>:
                  <i className="material-icons">attach_file</i>
                }
            </button>

            <div className={"card-title-menu " + (showMenu ? "" : "hidden")} style={{right: '0px'}}>
              {props.attachments ? props.attachments.map((attachment, index) => 
              {
                var icon = undefined;
                switch(attachment.type.toLowerCase())
                {
                  case "pdf": icon = mdiFilePdfOutline; break;
                  case "doc": case "docx": icon = mdiFileWordOutline; break;
                  case "xls": case "xlsx": icon = mdiFileExcelOutline; break;
                  case "ppt": case "pptx": icon = mdiFilePowerpointOutline; break;
                  case "zip": icon = mdiFolderZipOutline; break;
                  default: icon = mdiFileOutline; break;
                }


                return <CardHeaderMenuEntry mdi={{path: icon, title: attachment.type.toUpperCase()}} key={index} label={attachment.name} onClick={() => {
                    setShowMenu(false); 
                    if(attachment.type.toLowerCase() === "pdf")
                    {
                        var win = window.open(WEB_URL + "/#" + PATH_PDFVIEWER + "/" + attachment.id, '_blank');
                        win.focus()
                    }
                    else
                    {
                      var timer = setTimeout(() => setLoading(true), 100);
                      readFile(attachment.id).then(url => {
                        clearTimeout(timer);
                        setLoading(false);
                        let a = document.createElement('a');
                        a.href = url;
                        a.download = attachment.name;
                        a.click();
                      });
                    }
                  }}/>
                  
              }) : null}
            </div>
        </div> : null
    )
}
