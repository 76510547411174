const USER_STORAGE = "dashboardgovapp.user";
const USER_STORAGE_REMEMBER = "dashboardgovapp.user.remember";

var user = null;
var notifications = null;

export function rememberMyLoginData(remember) {
  //localStorage.setItem(USER_STORAGE_REMEMBER, remember);
}

export function setLoginData(u) {
  localStorage.setItem(USER_STORAGE, JSON.stringify(u));
}

export function getLoginData() {
  return JSON.parse(localStorage.getItem(USER_STORAGE));
}

/*export function setUser(u) {
  localStorage.setItem(USER_STORAGE, JSON.stringify(u));
} */

export function getToken() {
  return getLoginData()?.token;
}

export function getUserToken() {
  try {
    return JSON.parse(atob(getUser().token.split(".")[1]));
    // return user;//get(USER_STORAGE);
  } catch (e) {
    return null;
  }
}

export function getMail() {
  try {
    const user = JSON.parse(localStorage.getItem(USER_STORAGE));
    return user?.mail;
  } catch (e) {
    return null;
  }
}

export function getUser() {
  try {
    const user = JSON.parse(localStorage.getItem(USER_STORAGE));
    if (user.token && user.mail) {
      return user;
    }
    return null;
  } catch (e) {
    return null;
  }
}

export const isUserLoggedIn = () => {
  return getUser() != null;
};

export function removeUser() {
  user = null;
  localStorage.removeItem(USER_STORAGE);
}

function get(item) {
  var result = sessionStorage.getItem(item);
  if (!result) result = localStorage.getItem(item);
  return JSON.parse(result);
}

function storage() {
  if (
    localStorage.getItem(USER_STORAGE_REMEMBER) &&
    localStorage.getItem(USER_STORAGE_REMEMBER) === "true"
  ) {
    return localStorage;
  }
  return sessionStorage;
}
