import React, { useState, useEffect } from "react";
import { getCachedCategories } from "../../app_gateway/category/CategoryAG";
import { Redirect } from "react-router-dom";
import { PATHNAME_TAGS } from "./Routing";
import Loading from "../util/Loading";

export default function Root() {
  const [redirect, setRedirect] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    getCachedCategories().then((cat) => {
      if (cat && cat.length > 0)
        setRedirect("/" + PATHNAME_TAGS + "/c:" + cat[0].name);
      else setError("Keine Startseite gefunden");
    });
  }, []);

  return redirect !== null ? (
    <Redirect to={redirect} />
  ) : (
    <div style={{ height: "100%", display: "flex", justifyContent: "center" }}>
      {error !== null ? error : <Loading />}
    </div>
  );
}
