import React from 'react'
import {Icon as MdiIcon} from '@mdi/react'

export default function Icon(props) {

    var customPaths = [];
    try
    {
        customPaths = JSON.parse(props.custom.paths);
    }
    catch(e)
    {
        if(props.custom)
        customPaths = props.custom.paths;
    }

    if(props.mdi && props.mdi.path)
    {
        return (
            <MdiIcon style={{color: "white"}} width="25px" height="25px" path={props.mdi.path}
                title={props.mdi.title}
                size={props.mdi.size ? props.mdi.size : '25px'}
                rotate={props.mdi.rotate ? props.mdi.rotate : 0}
                color={props.mdi.color} {...props}
                viewBox={props.mdi.viewBox ? props.mdi.viewBox : "0 0 24 24"}
                />
        )
    }
    if(props.custom && props.custom.paths)
    {
        return (
            <svg viewBox={props.custom.viewBox ? props.custom.viewBox : "0 0 24 24"} width={props.custom.size ? props.custom.size : '25px'} height={props.custom.size ? props.custom.size : '25px'} style={{color: 'white'}}>
                {customPaths.map((path, i) => <path key={i} d={path.path} style={path.style}/>)}
            </svg>
        )
    }
    else
    {
        return (
            <i {...props} className="material-icons">{props.icon}</i>
        )
    }
}
