import {STORE_DASHBOARDS, STORE_PRINTLAYOUTS} from './Store';
import { isUserLoggedIn, getUser } from '../authentication/AuthenticationStore';
import { findDashboardsForUser, addDashboardsForUser, removeDashboardsForUser, findDossiers, findReleased } from '../dashboard/DataAG';
import { findPrintlayoutsForUser } from '../printlayout/PrintLayoutAG';
import { getFromCache, removeFromCache } from '../../components/util/Cache';

const releasedDashboardsCache = "dashboardgovapp.releasedDashboards";
const mydashboardsCache = "dashboardgovapp.mydashboards";
const myprintlayoutCache = "dashboardgovapp.myprintlayout";
const cacheDossier = "dashboardgovapp.dossiers";

export function getMyDashboards()
{
    if(isUserLoggedIn())
    {
        return getFromCache(mydashboardsCache, 5000, findDashboardsForUser, getUser().mail);
    }
    else
    {
        return new Promise((resolve, reject) => resolve(JSON.parse(localStorage.getItem(STORE_DASHBOARDS))));
    }
}

export function getReleasedDashboards()
{
    if(isUserLoggedIn())
    {
        return getFromCache(releasedDashboardsCache, 30000, findReleased, getUser().mail);
    }
    return null;
}

export function resetMyDashboardsCache()
{
    removeFromCache(mydashboardsCache);
}

export function resetMyPrintLayoutsCache()
{
    removeFromCache(myprintlayoutCache);
}

export function getMyPrintlayouts()
{
    if(isUserLoggedIn())
    {
        return getFromCache(myprintlayoutCache, 5000, findPrintlayoutsForUser, getUser().mail);
    }
    else
    {
        return new Promise((resolve, reject) => resolve(JSON.parse(localStorage.getItem(STORE_PRINTLAYOUTS))));
    }
}

export function getDossiers()
{
    return getFromCache(cacheDossier, 5000, findDossiers, getUser() !== null ? getUser().mail : null);
}

export function addToMyDashboards(dashboard)
{
    if(isUserLoggedIn())
    {
        removeFromCache(mydashboardsCache);
        return addDashboardsForUser(dashboard.id, getUser().mail);
    }
    else
    {
        return new Promise((resolve, reject) => 
        {
            var dashboards = JSON.parse(localStorage.getItem(STORE_DASHBOARDS));
            if(!dashboards)
                dashboards = [];
            dashboards.push({
                id: dashboard.id,
                title: dashboard.title,
                category: dashboard.category
            }); 
            localStorage.setItem(STORE_DASHBOARDS, JSON.stringify(dashboards));
            resolve(dashboards);
        });
    }
}

export function removeFromMyDashboards(dashboard)
{
    if(isUserLoggedIn())
    {
        removeFromCache(mydashboardsCache);
        return removeDashboardsForUser(dashboard.id, getUser().mail);
    }
    else
    {
        return new Promise((resolve, reject) => 
        {
            var dashboards = JSON.parse(localStorage.getItem(STORE_DASHBOARDS));
            if(!dashboards)
                dashboards = [];
            dashboards = dashboards.filter(d => d.id !== dashboard.id);
            localStorage.setItem(STORE_DASHBOARDS, JSON.stringify(dashboards));
            resolve(dashboards);
        });
    }
}