var funcToGetLayout = null;
var lastDashboard = null;

export function setFuncToGetLayout(func)
{
    funcToGetLayout = func;
}

export function getCentralLayout()
{
    if(funcToGetLayout !== null)
        return funcToGetLayout();
    return null;
}

export function getLastDashboard()
{
    return lastDashboard;
}

export function setLastDashboard(dashboard)
{
    lastDashboard = dashboard;
}