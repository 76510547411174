import React, { useEffect } from 'react'

import '../../styles/dashboard-add-tile.css';

const DashboardAddTile = (props) => {

    useEffect(() => {
        
    }, [])


    return (
        <div className="dashboard-add-tile-content">
            <h2>Dashboard Kachel hinzufügen</h2>
        </div>
    )

}
export default DashboardAddTile;