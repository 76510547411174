import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { l } from '../main/Main'
import TextButton from '../util/TextButton'
import Datenschutz from './Datenschutz'

import '../../styles/privacy.css'

const STORRAGE_ITEM = "dashboardgovapp.cookiebanner"

export default function CookieBanner(props) {

    const [active, setActive] = useState(false);
    const [showDatenschutz, setShowDatenschutz] = useState(false)

    useEffect(() => {
        let activated = localStorage.getItem(STORRAGE_ITEM);
        if(activated == null || activated === "false")
        {
            setActive(true);
            props.onActive(true);
        }
    }, [props])

    const close = () => {
        localStorage.setItem(STORRAGE_ITEM, true);
        props.onActive(false);
        setActive(false);
    }

    if(active)
    {
        return (
            <div className={"cookiebanner-root " + (showDatenschutz ? "show-datenschutz" : "" )}>
                <div className="cookiebanner-content">
                    <div className="login-topbar">
                        <div className="cookiebanner-logo">
                            {/* <img src="assets/layout/images/DashboardDeutschlandLogo.png" alt="Daten Dashboard Deutschland Logo"/> */}
                        </div>
                    </div>
                    {showDatenschutz ?
                        <div className="cookiebanner-datenschutz">
                        <Datenschutz />
                        <div className="cookiebanner-button">
                                <Button label={"Akzeptieren"} aria-label={"Akzeptieren"} onClick={close}/>
                            </div>
                        </div>
                        :
                        <>
                            <div className="cookiebanner-title">
                                <h1>Wir verwenden Cookies.</h1>
                            </div>
                            <div className="cookiebanner-text">
                                Auf dieser Website werden Nutzungsdaten nur im notwendigen, zweckgebundenen Maß verarbeitet. Die auf dieser Website verwendeten Cookies dienen ausschließlich der technischen Bereitstellung und Optimierung des Webangebotes. Detaillierte Informationen erhalten Sie in unserer <button className="link-button" onClick={() => setShowDatenschutz(true)}> Datenschutzerklärung</button>.
                            </div>
                            <div className="cookiebanner-button">
                                <Button label={"Akzeptieren"} aria-label={"Akzeptieren"} onClick={close}/>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
    else
        return null;
    
}
