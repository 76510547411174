import React from 'react'
import Icon from '../util/Icon';
import {mdiCloudUploadOutline} from '@mdi/js';
import Tooltip from "rc-tooltip";
import {
    Binoculars16,
    Chemistry16,
    CurrencyDollar16,
    Finance16,
    Industry16,
    Stamp16,
    Portfolio16,
    Microscope16,
    Archive16,
    Locked16,
  } from "@carbon/icons-react";
import { getFormattedDate } from './cardheader/AuditMenu';
        
const formatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

export default function DashboardTileSource(props) {

    let formattedDate = getFormattedDate(props.date);
    let aria = props.source != null ? formattedDate + " Quelle: "+props.source : formattedDate;

    const createOldSource = (sources) => {
        let elements = sources.match(/\[.*?\]\(.*?\)/g)
        if(elements != null && elements.length > 0)
        {
            let el;
            for(el of elements)
            {
                let linkName = el.match(/\[(.*?)\]/)[1];
                let elNoLinkName = el.replace(linkName, "")
                let url = elNoLinkName.match(/\((.*?)\)/)[1];
                sources = sources.replace(el,'<a href="'+url+'" target="_blank">'+linkName+'</a>')
            }
        }

       return <div className="sourceLink" dangerouslySetInnerHTML={{__html: sources}} />
    }

    const createNewSources = (sources) => {
        
    }

    return (
        <div className="tile-source" tabIndex={0} aria-label={aria} >
            <div className="tile-source-firstrow">
              <div className="tile-source-upload-icon">
                  <Icon style={{color:'#666', marginBottom: '-3px', marginRight: '3px'}} mdi={{path: mdiCloudUploadOutline, size: '1.2em'}} width="1.2em" />
                  {formattedDate}
              </div>
              <div className="tile-source-dataversiondate">
                {props.dataVersionDate != null && props.dataVersionDate != '' ? ("| Datenstand: " + props.dataVersionDate) : ''}
              </div>
            </div>
            <div className="tile-source-secondrow">

              <div className="tile-source-source">
                  {(props.source != null || props.sources != null) && props.source != "" && "Quelle: "}
              </div>
              {props.source != null && props.sources == null && props.source != "" && 
                  <div className="tile-source-sourcetext">
                      
                      {createOldSource(props.source)}
                  </div>
              }
              {props.sources != null && 
                  <div className="tile-source-sourcetext newsources">
                      {props.sources.map((s, i) => {

                          return <div key={i} className={"sourceLink newsources"}>
                              {s.quality && SourceQualityMapping[s.quality - 1] && (
                  
                                <Tooltip
                                  placement="bottom"
                                  overlay={<span>{SourceQualityMapping[s.quality - 1].infotext_de}</span>}
                                  overlayClassName={"light"}
                                  overlayStyle={{ opacity: 1, maxWidth: 400 }}
                                  overlayInnerStyle={{
                                    background: "white",
                                    color: "rgb(39,44,45)",
                                    borderColor: "rgb(220,223,225)",
                                  }}
                                >
                  
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "left",
                                    marginLeft: 5,
                                    cursor:"pointer",
                                  }}
                                >
                                  <span style={{ marginRight: 0, display: "flex" }}>
                                    {SourceQualityMapping[s.quality - 1].icon}
                                  </span>
                                  {/* {SourceQualityMapping[s.quality - 1].name} */}
                                </div>
                                </Tooltip>
                              )}
                          {s.qualitySource &&
                            SourceCategoryMapping[s.qualitySource - 1]?.icon && (
                            <Tooltip
                              placement="bottom"
                              overlay={<span>{SourceCategoryMapping[s.qualitySource - 1]?.infotext_de}</span>}
                              overlayClassName={"light"}
                              overlayStyle={{ opacity: 1, maxWidth: 400 }}
                              overlayInnerStyle={{
                                background: "white",
                                color: "rgb(39,44,45)",
                                borderColor: "rgb(220,223,225)",
                              }}
                            >
                              
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "left",
                                  marginLeft: 5,
                                  cursor:"pointer",
                                }}
                              >
                                <span style={{ marginRight: 5, display: "flex" }}>
                                  {SourceCategoryMapping[s.qualitySource - 1]?.icon}
                                </span>
                                {/* {SourceCategoryMapping[s.qualitySource - 1].name} */}
                              </div>
                              </Tooltip>
                            )}
              
                          {s.link ? (
                            <>
                            <a href={s.link} target="_blank" rel="noopener noreferrer">
                              {s.name}
                            </a>
                              {i + 1 < props.sources.length && <div className="source-seperator">|</div>}
                              </>
                          ) : (
                              <>
                              {s.name}
                              {i + 1 < props.sources.length && <div className="source-seperator">|</div>}
                              </>
                          )}
                        </div>
                      })}
                  </div>
              }
            </div>
        </div>


        //     {data.source && data.source.length > 0 ?
        //         <div className="tile-source-sticky"><div className="tile-source">
        //             <div className="tile-source-left"></div>
        //             <div className="tile-source-right"></div>
        //         </div></div>
        //         : <div className="tile-source-sticky">
        //             <div className="tile-source" aria-label={new Date(data.date).toLocaleDateString('de-DE', formatOptions)}>
        //             <div className="tile-source-left"><div className="tile-source-upload-icon"><Icon style={{color:'#666', marginBottom: '-3px', marginRight: '3px'}} mdi={{path: mdiCloudUploadOutline, size: '1.2em'}} width="1.2em" /></div>{tile !== null ? new Date(tile.date).toLocaleDateString('de-DE', formatOptions) : new Date(props.date).toLocaleDateString('de-DE', formatOptions)}</div>
        //             <div className="tile-source-right"></div>
        //         </div></div>}
        // </div>
    )
}



const DestatisLogo = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="16"
        height="16"
        viewBox="0 0 103 93"
      >
        <defs>
          <clipPath id="clip-Artboard_1">
            <rect width="103" height="93" />
          </clipPath>
        </defs>
        <g
          id="Artboard_1"
          data-name="Artboard – 1"
          clipPath="url(#clip-Artboard_1)"
        >
          <g id="layer1" transform="translate(-119 -20.007)">
            <g id="Layer_x0020_1" transform="translate(0 0.007)">
              <g id="_110861608">
                <path
                  id="_112027328"
                  d="M120.163,111.3H220.92V96.183H120.163Z"
                  fill="#a9a9a9"
                  fillRule="evenodd"
                />
                <path
                  id="_47013000"
                  d="M121.214,88.678l0-59.086H142.3V88.66Z"
                  fillRule="evenodd"
                />
                <path
                  id="_110950768"
                  d="M159.6,88.66l0-45.234h21.083l.013,45.252Z"
                  fill="#d00"
                  fillRule="evenodd"
                />
                <path
                  id="_46752216"
                  d="M198.833,88.689v-67.1h21.089v67.08Z"
                  fill="#ffce00"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  };


/**
 * Dropdown for field "qualitySource" = "Datenkategorie"
 *
 * Use "code" for retrieving translations.
 * 
 * 1: "Destatis"
 * 2: "Amtliche Statistik (ONA)" 
 * 3: "Sonstige öffentliche Verwaltung"
 * 4: "Forschung und Wissenschaft"
 * 5: "Privat (Unternehmen, Verbände und Vereine)"
 */
const SourceCategoryMapping = [
    { 
      name: "Destatis", 
      code: "DEST", 
      icon: <DestatisLogo />, 
      infotext_de: "Der Indikator basiert auf Daten des Statistischen Bundesamtes (Destatis), das zusammen mit den Statistischen Ämtern der Länder in gesetzlichem Auftrag Bundesstatistiken durchführt und verbreitet. ", 
    },
    { 
      name: "Amtliche Statistik (ONA)", 
      code: "ONA", 
      icon: <Portfolio16 />, 
      infotext_de: "Der Indikator basiert auf Daten der Statistischen Ämter der Länder oder des Bundes, internationaler statistischer Ämter oder anderer einzelstaatlicher Stellen, die mit der Erstellung amtlicher Statistiken betraut sind.",  
    },
    {
      name: "Sonstige öffentliche Verwaltung",
      code: "SOEV",
      icon: <Archive16 />,
      infotext_de: "Der Datenproduzent des Indikators ist kein Produzent amtlicher Statistik, gehört aber zur sonstigen öffentlichen Verwaltung." ,
    },
    { 
      name: "Forschung und Wissenschaft", 
      code: "FUW",
      icon: <Microscope16 /> ,
      infotext_de: "Bei dem Datenproduzenten des Indikators handelt es sich um ein Forschungsinstitut oder eine sonstige Institution aus Forschung oder Wissenschaft.",
    },
    {
      name: "Privat (Unternehmen, Verbände und Vereine)",
      code: "PRIV",
      icon: <Locked16 />,
      infotext_de: "Der Datenproduzent des Indikators ist ein privates Unternehmen, ein Verband oder ein Verein." ,
    },
  ];
  
  /**
   * Dropdown for field "quality" = "DatenQualität"
   *
   * Use "code" for retrieving translations.
   * 
   * 1: "Amtliche Daten"
   * 2: "Experimentelle Daten"
   * 3: "Verwaltungsdaten"
   * 4: "Prognose- und Umfragedaten"
   * 5:"Unternehmens- und Verbandsdaten"
   * 6: "Finanzmarktdaten"
   */
  const SourceQualityMapping = [
    { 
      name: "Daten der amtlichen Statistik", 
      code: "AD", 
      icon: <Finance16 />,
      infotext_de: "Diese Daten sind Teil der amtlichen Statistik. Die Durchführung, Erstellung und Verbreitung amtlicher Bundesstatistiken durch staatliche Behörden ist durch das Bundesstatistikgesetz geregelt.",
     },
    { 
      name: "Experimentelle Daten", 
      code: "ED", 
      icon: <Chemistry16 />,
      infotext_de: "Dieser Indikator basiert auf experimentellen Daten. Experimentelle Daten sind das Resultat innovativer Projekte auf Basis neuer Datenquellen und Methoden. Sie unterscheiden sich daher in Reifegrad und Qualität von amtlichen Statistiken.",
    },
    {
      name: "Verwaltungsdaten", 
      code: "VD", 
      icon: <Stamp16 />,
      infotext_de: "Dieser Indikator basiert auf Verwaltungsdaten. Dabei handelt es sich um Daten, die bei staatlichen Behörden in Wahrnehmung bundes- oder landesgesetzlich übertragener Aufgaben anfallen.",
    },
    { 
      name: "Umfrage- und Stimmungsindikatoren, Prognosen", 
      code: "PUD", 
      icon: <Binoculars16 />,
      infotext_de: "Bei diesem Indikator handelt es sich um einen Umfrage- und Stimmungsindikator beziehungsweise um eine Prognose. Er basiert somit auf Einschätzungen der aktuellen und/oder zukünftigen Lage.",
    },
    {
      name: "Unternehmens- und Verbandsdaten",
      code: "UVD",
      icon: <Industry16 />,
      infotext_de: "Dieser Indikator basiert auf Unternehmens- oder Verbandsdaten. Solche Daten werden in Unternehmen, Verbänden oder Vereinen bei der Ausübung ihrer Geschäftsprozesse erhoben. ",   
    },
    { 
      name: "Finanzmarktdaten", 
      code: "FMD", 
      icon: <CurrencyDollar16 />,
      infotext_de: "Dieser Indikator basiert auf Finanzmarktdaten. Solche Daten zeigen den Kursverlauf von an der Börse gehandelten Wertpapieren, Rohstoffen oder sonstigen Handelsobjekten. Finanzmarktdaten stellen in der Regel hochfrequente Prozesse mit einer gewissen spekulativen Komponente dar. ",
      },
  ];
  