import React, { useState, useEffect } from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';
import {ColorPicker} from 'primereact/colorpicker';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import LinkButton from '../util/LinkButton';
import {Slider} from 'primereact/slider';
import {RadioButton} from 'primereact/radiobutton';
import CategoryIcon from './CategoryIcon';


export default function IconEditor(props) {
    
    const [color, setColor] = useState("#000")
    const [defaultColor, setDefaultColor] = useState("000")

    useEffect(() => {
        setColor(props.color.substring(1));
        setDefaultColor(props.color.substring(1));
    }, [])

    return (
        <div className="category-icon-editor">
            <div className="category-icon" style={{backgroundColor: props.color}}><CategoryIcon category={props} size="30px" /></div>
            <Accordion>
                <AccordionTab header="Hintergrundfarbe">
                    <div className="category-icon-editor-cp">
                    <ColorPicker inline={true} value={color} onChange={(e) => {setColor(e.value); props.onChange('color', "#"+ e.value)}} />
                    <div>
                        <div className="category-icon-editor-cp-txt">
                        Hex: <InputText value={color} onChange={(e) => {setColor(e.target.value); props.onChange('color', "#"+ e.target.value)}} />
                        </div>
                        <LinkButton label="Reset" onClick={() => {setColor(defaultColor); props.onChange('color', "#"+defaultColor)}} />
                    </div>
                    </div>
                </AccordionTab>
                {<AccordionTab header="IconType">
                    <div >
                        <div>
                        <RadioButton style={{width: '20px'}} value="singlesvg" name="Single SVG" onChange={(e) => props.onChange('iconType', e.value)} checked={props.iconType === 'singlesvg'} />
                        Single SVG
                        </div>
                        <div>Der IconType 'Single SVG' ist eine Grafik, die aus einem Path gerendert wird. Dieser Path kann bei 'Icon (SVG)' angegeben werden.</div>
                        <div style={{marginTop: '10px'}}>
                        <RadioButton style={{width: '20px'}}value="multisvg" name="Multi SVG" onChange={(e) => props.onChange('iconType', e.value)} checked={props.iconType === 'multisvg'} />
                        Multi SVG
                        </div>
                        <div>Der IconType 'Multi SVG' ist eine Grafik, die aus mehreren Paths gerendert wird. Dieser Path muss als Json-Array bei 'Icon (SVG)' angegeben werden. Bsp: <br />
                        [{'{'}<br />&nbsp;&nbsp;"path": "...",<br />&nbsp;&nbsp;"style": "..." <br />{'}'}] </div>
                    </div>
                </AccordionTab>}
                <AccordionTab header="Icon (SVG)">
                    <InputTextarea value={props.icon} onChange={(e) => props.onChange('icon', e.target.value)}/>
                </AccordionTab>
                <AccordionTab header="ViewBox">
                    <div className="category-icon-editor-viewbox">
                        <div className="category-icon-editor-viewbox-pos">Top:  {props.viewBox.split(" ")[1]}
                            <Slider value={props.viewBox.split(" ")[1]} onChange={(e) => props.onChange('viewBox.left',e.value)} min={0} max={512} />
                        </div>
                        <div className="category-icon-editor-viewbox-pos">Left: {props.viewBox.split(" ")[0]}
                            <Slider value={props.viewBox.split(" ")[0]} onChange={(e) => props.onChange('viewBox.top', e.value)} min={0} max={512} />
                        </div>
                        <div className="category-icon-editor-viewbox-pos">Width: {props.viewBox.split(" ")[2]}
                            <Slider value={props.viewBox.split(" ")[2]} onChange={(e) => props.onChange('viewBox.w', e.value)} min={1} max={512} />
                        </div>
                        <div className="category-icon-editor-viewbox-pos">Height: {props.viewBox.split(" ")[3]}
                            <Slider value={props.viewBox.split(" ")[3]} onChange={(e) => props.onChange('viewBox.h', e.value)} min={1} max={512} />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        </div>
    )
}
