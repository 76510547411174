import React, { useState, useEffect } from 'react'
import { PATH_CREATE_EXISTING_DASHBOARD } from '../main/Routing'

import '../../styles/dashboardtoolbar.css';
import DashboardToolbarToggleButton from './DashboardToolbarToggleButton';
import DashboardToolbarCalendarButton from './DashboardToolbarCalendarButton';
import CategoryIcon from '../category/CategoryIcon';
import { getCachedCategories } from '../../app_gateway/category/CategoryAG';
import { getUser } from '../../app_gateway/authentication/AuthenticationStore';
import { MultiSelect } from 'primereact/multiselect';
import 'react-calendar/dist/Calendar.css';
import { mdiClose } from '@mdi/js';
import Icon from '../util/Icon';


const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

export default function DashboardNewsToolbar(props) {
    
    const [toggleTime, setToggleTime] = useState(0)
    const [categories, setCategories] = useState([]);
    const [selectedCats, setSelectedCats] = useState([]);
    const [date, setDate] = useState(null);


    const getLast24 = () => {
        var d = new Date();
        d.setHours(d.getHours() - 24);
        return d;
    }

    const getLastLoginDate = () => {
        let user = getUser();
        if(user != null)
            return new Date(user.lastLoginTimestamp);
        return null;
    }

    const getLastMonth = () => {
        var d = new Date();
        d.setMonth(d.getMonth() - 1);
        return d;
    }

    const getLast6Month = () => {
        var d = new Date();
        if(d.getMonth>6)
            d.setMonth(d.getMonth - 6);
        else {
            let m = d.getMonth();
            d.setMonth(12 - (6-m));
            d.setFullYear(d.getFullYear() - 1);
        }
        return d;
    }

    const getLastYear = () => {
        var d = new Date();
        d.setFullYear(d.getFullYear() - 1);
        return d;
    }
    
    const timeSettings = [
        {label: "Letzte 24 Stunden", value: getLast24()},
        {label: "Seit letztem Login", value: getLastLoginDate()}
        // {label: "Letzter Monat", value: getLastMonth()},
        // {label: "Letzte 6 Monate", value: getLast6Month()},
        // {label: "Letztes Jahr", value: getLastYear()}
    ]

    useEffect(() => {
        getCachedCategories().then(cat => {
            setCategories(cat);
            // setSelectedCats(cat.map(c => c.name));
            updateSelection(timeSettings[toggleTime].value, cat);
        })
    }, [])

    const updateSelection = (time, cats) => {
        props.onUpdateSelection(time, cats.length > 0 ? cats.map(c => c.name) : categories.map(c => c.name));
    }

    const selectedItemTemplate = (c) => {
        if(c) {
            return <div className="lastupdated-selected-category" style={{backgroundColor: c.color}}>
                <CategoryIcon category={c} />
                {c.name}
                <div className="lastupdated-selected-category-close" onClick={(e) => setSelectedCats(selectedCats.filter(sc => sc.name !== c.name))}>
                    <Icon mdi={{path: mdiClose, color: 'white', size: '16px', viewBox: '-5 4 24 24'}} />
                </div>
            </div>
        }
        else {
            return <div className="lastupdated-no-selected-category">
                Nach Themencluster filtern
            </div>
        }
    }

    return (
        <div aria-label={"Toolbar"} className="dashboard-toolbar">
            <div className="dashboard-toolbar-left">
                <div className="dashboard-togglebuttons-left">
                    <MultiSelect 
                        optionLabel="name" 
                        value={selectedCats} 
                        options={categories} 
                        placeholder={"Nach Kategorie filtern"}
                        selectedItemTemplate={selectedItemTemplate}
                        // style={{minWidth: '500px'}}
                        maxSelectedLabels={10}
                        onChange={(e) => {
                            setSelectedCats(e.value);
                            updateSelection(toggleTime < 0 ? date : timeSettings[toggleTime].value, e.value);
                        }} />


                {/* {categories != null && categories.map(c => <DashboardToolbarToggleButton buttonid={c.name} color={c.color} textright={c.name} active={selectedCats.includes(c.name)} onClick={(id) => {
                    let cats = []
                    if(selectedCats.includes(c.name)){
                        cats = selectedCats.filter(cat => cat !== c.name)
                    }
                    else {
                        cats = [...selectedCats, c.name]
                    }
                    setSelectedCats(cats)
                    updateSelection(toggleTime < 0 ? date : timeSettings[toggleTime].value, cats);
                }} />)} */}
                </div>  
            </div>
            <div className="dashboard-toolbar-center">

            </div>
            <div className="dashboard-toolbar-right">
                <div className="dashboard-togglebuttons-right">
                    <DashboardToolbarCalendarButton buttonid={-1} date={date} setDate={d => {setDate(d); setToggleTime(-1); updateSelection(d, selectedCats);}} textright={(date != null ? (date.toLocaleDateString('de-DE', options)) : "Datum wählen")} active={-1 === toggleTime} onClick={() => {setToggleTime(-1);}} />
                    {timeSettings.map((ts, i) => ts.value != null ? <DashboardToolbarToggleButton key={ts.label} buttonid={i} textright={ts.label} active={i === toggleTime} onClick={() => {setToggleTime(i); updateSelection(timeSettings[i].value, selectedCats);}} /> : null)}
                </div>
            </div>
      </div>
    )
}
