import React, { useEffect, useState } from 'react'
import { getCachedCategories } from '../../app_gateway/category/CategoryAG';
import CategoryIcon from '../category/CategoryIcon';

export default function Legend(props) {

    const [catsToPrint, setCatsToPrint] = useState([])

    useEffect(() => {
        getCachedCategories().then(categories => {
            setCatsToPrint(categories.filter(c => props.cats.includes(c.name)));
        })
    }, [props.cats])

    return (
        <div className="print-legend">
            <div id="print-legend" className="print-legend-content">
                {catsToPrint.map((c, i) => 
                    <div key={i} className="print-legend-category">
                        <div className="print-legend-category-icon" style={{backgroundColor: c.color, border: '1px solid lightgray'}}>
                            <CategoryIcon category={c} size= '25px' />
                        </div>
                        <div className="print-legend-category-text">
                            {c.name}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
