import React from 'react';

import CardHeaderMenu from './CardHeaderMenu';
import './cardheader.css';
import { WEB_URL } from '../../../app_gateway/Constants';
import { PATH_TILEONLY } from '../../main/Routing';
import AttachmentMenu from './AttachmentMenu';
import IconButton from '../../util/IconButton';
import CategoryIcon from '../../category/CategoryIcon';
import Notification from './Notification';
import Tooltip from '../../util/Tooltip';
import ViewOptions from './ViewOptions';
import AuditMenu from './AuditMenu';

const CardHeader = React.forwardRef((props, ref) => {


    return (
        <div ref={ref} id={props.id} className="sticky-header">
            <div id="card-title" className="card-title">
                {props.category ? 
                <div className="card-title-link btn-card-title-menu card-title-category-icon" style={{backgroundColor: props.category.color}}>
                    <Tooltip style={{marginTop: '5px'}} onHover bottom auto delay={700} label={props.category.name}>
                    <CategoryIcon category={ props.category} size= '25px' /></Tooltip>
                </div>
                : <div />}
                <div style={{display: 'flex'}}>
                    <div tabIndex={0} aria-label={props.title}>
                        {props.title}
                    </div>
                    
                    <CardHeaderMenu onClose={props.onClose}>
                    </CardHeaderMenu>
                </div>
                <div className="card-title-functions">
                {props.audited && <Tooltip  onHover bottom auto delay={700} label={"Historisierte Stände ansehen"}>
                    <AuditMenu tileid={props.tileid} onChangeDate={(date) => props.onChangeDate(date)}/>
                </Tooltip>}
                    {props.enableNotification ? <Notification tileid={props.tileid} /> : null}
                {
                    props.components &&
                    <ViewOptions components={props.components} hideComponents={props.hideComponents} onClick={props.onShowHideComponent} />
                }
                {
                    props.link || (props.attachments !== null && props.attachments.length > 0) ?
                        <div>
                        {
                             <Tooltip  onHover bottom auto delay={700} label={"Anhang einsehen"}>
                            <AttachmentMenu attachments={props.attachments}/></Tooltip>
                        }
                        {
                            props.link ?
                            <div className="card-title-link btn-card-title-menu" data-html2canvas-ignore="true">
                                <Tooltip onHover bottom auto delay={700} label={"Link zu weiteren Informationen"}>
                                <IconButton icon="zoom_out_map" onClick={() => {
                                    var win = window.open(WEB_URL + "/#" + PATH_TILEONLY + "/" + props.link, '_blank');
                                    win.focus();
                                }} /></Tooltip>

                            </div> : null
                        }
                        </div>
                    : null
                }
                </div>
                
            </div>
            {/*<div className="card-subtitle">
                <h2 className="fadeInLeft">{props.subtitle}</h2>
                <span className="card-subtitle-icon fadeIn"><i className="material-icons" style={{verticalAlign: 'middle', marginRight: '5px'}}>{props.subtitleIcon}</i></span>
            </div>*/}
        </div>
        )
})

export default CardHeader;