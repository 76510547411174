import React, { useEffect, useState } from 'react'
import { deleteDashboard } from '../../app_gateway/dashboard/DataAG'
import { PATH_DASHBOARD, PATH_PRINTVIEW } from '../main/Routing'
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import { getMyDashboards, getMyPrintlayouts, removeFromMyDashboards, resetMyDashboardsCache, resetMyPrintLayoutsCache } from '../../app_gateway/store/MyDashboardsStore';
import Loading from '../util/Loading';
import { l, showGrowlError, showGrowlSuccess } from '../main/Main';

import '../../styles/mydashboards.css';
import { mdiDelete, mdiPrinter, mdiViewDashboard } from '@mdi/js';
import Icon from '../util/Icon';
import IconButton from '../util/IconButton';
import { Dialog } from 'primereact/dialog';
import { deletePrintLayout } from '../../app_gateway/printlayout/PrintLayoutAG';
import { getUser } from '../../app_gateway/authentication/AuthenticationStore';

export default function MyDashboards(props) {

    const [myDashboards, setMyDashboards] = useState(null);
    const [subscribedDashboards, setSubscribedDashboards] = useState(null);
    const [isDisplayModeCategory, setIsDisplayModeCategory] = useState(false);
    const [myPrintLayouts, setMyPrintLayouts] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [deleteMode, setDeleteMode] = useState(0);
    const [deleteObject, setDeleteObject] = useState({});
    const [deleteDialogTexts, setDeleteDialogTexts] = useState({});

    useEffect(() => {
       load();
    }, [])

    const load = () => {
        getMyDashboards().then(response => {
            let mail = getUser().mail;
            if(response) {
                setMyDashboards(response.filter(a => a.owner.mail === mail));
                setSubscribedDashboards(response.filter(a => a.owner.mail !== mail));
            }
            else {

                setMyDashboards([]);
            }
            });
        getMyPrintlayouts().then(response2 => {
            if(response2)
                setMyPrintLayouts(response2);
            else
                setMyPrintLayouts([]);
        });
    }

    const treeNodeTemplate = (node, del) => {
        if (node.url) {
            return dashboardItem(node, del);
        }
        else {
            return (
                <span className="search-category-label">{node.label}</span>
            )
        }
    }
    
    const sortByCategory = (list, del) => {
        // var sortedList = list.sort((a, b) => (a.category === null || a.category === "") ? 1 : (a.category > b.category) ? 1 : (a.category === b.category) ? ((a.title > b.title) ? 1 : -1) : -1 );

        var sortedList = list.sort(function(a, b) {
            if(a.category < b.category) { return -1; }
            if(a.category > b.category) { return 1; }
            if(a.category === null || a.category === "")  { return -1; }
            if(a.category === b.category) { 
                if(a.title < b.title) { return -1; }
                if(a.title > b.title) { return 1; }
            }
            return 0;
        })

        var items = [];
        let categoryCounter = 0;
        let categoryChildCounter = 0;
        let currentCategory = "";
        let currentCategoryChildren = [];

        for (const [i, v] of sortedList.entries()) {
            if (currentCategory === "") {
                currentCategory = v.category;
            }

            if (currentCategory === v.category) {
                currentCategoryChildren.push({
                    "key": categoryCounter + "-" + categoryChildCounter,
                    "id": v.id,
                    "title": v.title,  
                    "tags": v.tags,
                    "url": PATH_DASHBOARD + "/" + v.id,
                });
                categoryChildCounter++;
            }

            if ((sortedList[i+1] === undefined) || (sortedList[i+1] !== undefined && sortedList[i+1].category !== currentCategory)) {

                let a = {
                    "key": categoryCounter + "",
                    "label": (currentCategory === null || currentCategory === "" ? (l("Keine Kategorie")) : (currentCategory)),
                    "children": currentCategoryChildren
                }
                items.push(a);

                categoryCounter++;
                categoryChildCounter = 0;
                currentCategory = "";
                currentCategoryChildren = [];
            }
          }
        return <Tree value={items} nodeTemplate={node => treeNodeTemplate(node, del)} contentClassName="search-category-tree"/>
    }

    const dashboardItem = (db, del) => {
        return item(db.category, db.title, db.tags, PATH_DASHBOARD + "/" + db.id, db.id, mdiViewDashboard, del === true ? () => {
            setDeleteMode(0);
            setDeleteObject(db);
            setDialogTexts(0, db);
            setShowDialog(true);
        } : () => {
            setDeleteMode(2);
            setDialogTexts(2, db);
            setDeleteObject(db);
            setShowDialog(true);
        })
    }

    const printlayoutItem = (pl) => {
        return item(null, pl.name, null, PATH_PRINTVIEW + "/" + "storedLayout:" + pl.id, pl.id, mdiPrinter, () => {
            setDeleteMode(1);
            setDialogTexts(1, pl);
            setDeleteObject(pl);
            setShowDialog(true);
        })
    }

    const item = (category, title, tags, path, id, icon, onDelete) => {
        return (
            <div key={id} className="search-content-entry">
                <div className="search-content-text" onClick={() => window.location = "#" + path}>
                    <Icon mdi={{path: icon, color: '#444'}}/>
                    <div className="search-content-entry-title">
                        {(category ? (category + " / ") : "") +title}
                    </div>
                    <div className="search-content-entry-tags">
                        {tags != null && tags.map(tag => <div className="search-content-entry-tag">{tag}</div>)}
                    </div>
                </div>
                {onDelete != null && <div className="search-content-delete" id={"DELETE"+id}>
                    <IconButton mdi={{path: mdiDelete, color: '#444'}} onClick={onDelete} />
                </div>}
            </div>);
    }



    const sortByAlphabet = (list, del) => {
        var sortedList = list.sort(function(a, b) {
            if(a.title < b.title) { return -1; }
            if(a.title > b.title) { return 1; }
            return 0;
        })

        return <>
            {sortedList.map((db, i) => {
                return dashboardItem(db, del);
            })}
        </>
    }

    const sortPrintLayoutByAlphabet = (list) => {
        var sortedList = list.sort(function(a, b) {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })

        return <>
            {sortedList.map((db, i) => {
                return printlayoutItem(db);
            })}
        </>
    }

    const doDelete = () => {
      if(deleteMode === 0) {
        deleteDashboard(deleteObject.id).then(response => {
            resetMyDashboardsCache();
            resetMyPrintLayoutsCache();
            load();
            setShowDialog(false);
            if(response)
                showGrowlSuccess("Dashboard gelöscht", "Das Dashboard wurde erfolgreich gelöscht.");
            else
                showGrowlError("Fehler beim Löschen", "Beim Löschen des Dashboard ist ein Fehler aufgetreten.");
        })
      } 
      else if(deleteMode === 1) {
        deletePrintLayout(deleteObject.id).then(response => {
            resetMyDashboardsCache();
            resetMyPrintLayoutsCache();
            load();
            setShowDialog(false);
            if(response)
                showGrowlSuccess("Drucklayout gelöscht", "Das Drucklayout wurde erfolgreich gelöscht.");
            else
                showGrowlError("Fehler beim Löschen", "Beim Löschen des Drucklayouts ist ein Fehler aufgetreten.");
        })
      }
      else if(deleteMode === 2) {
        removeFromMyDashboards(deleteObject).then(e => {
            resetMyDashboardsCache();
            resetMyPrintLayoutsCache();
            load();
            setShowDialog(false);
            if(e)
                showGrowlSuccess("Dashboard nicht mehr abboniert", "Das Abonnement des Dashboards wurde erfolgreich gelöscht.");
            else
                showGrowlError("Fehler beim Abbestellen", "Beim Abbestellen des Abonnement des Dashboard ist ein Fehler aufgetreten.");
        });
      }
    }

    const footer = (
        <div>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => setShowDialog(false)} />
            <Button label="Löschen" icon="pi pi-check" onClick={doDelete} />
        </div>
    );

    const setDialogTexts = (deleteMode, deleteObject) => {
        if(deleteMode === 0) {
            setDeleteDialogTexts({
                header: "Dashboard \"" + deleteObject.title + "\" löschen",
                content: "Sind Sie sicher, dass die das Dashboard \"" + deleteObject.title + "\" löschen möchten?"
            })
        }
        else if(deleteMode === 1) {
            setDeleteDialogTexts({
                header: "Drucklayout \"" + deleteObject.name + "\" löschen",
                content: "Sind Sie sicher, dass die das Drucklayout \"" + deleteObject.name + "\" löschen möchten?"
            })
        }
        else if(deleteMode === 2) {
            setDeleteDialogTexts({
                header: "Dashboard \"" + deleteObject.title + "\" nicht mehr abbonieren",
                content: "Sind Sie sicher, dass die das Dashboard \"" + deleteObject.title + "\" nicht mehr abbonieren möchten?"
            })
        }
    }

    return (
        <div className="my-dashboards-root">
            <Dialog header={deleteDialogTexts.header} visible={showDialog} style={{ width: '50vw' }} footer={footer} onHide={() => setShowDialog(false)}>
                {deleteDialogTexts.content}
            </Dialog>
            <div className="my-dashboards">
                <div className="my-dashboards-title">
                        <Button icon="pi pi-folder-open" tooltip={l("Ansicht nach Kategorie")} className={"my-dashboards-display-mode-button " + (isDisplayModeCategory ? "button-active" : "button-inactive")} onClick={() => setIsDisplayModeCategory(true)}/>
                        <Button icon="pi pi-list" tooltip={l("Ansicht als Liste")} className={"my-dashboards-display-mode-button " + (!isDisplayModeCategory ? "button-active" : "button-inactive")} onClick={() => setIsDisplayModeCategory(false)}/>
                        <span>{l("Meine Dashboards")}:</span>
                    {/* <div>{l("Abonnieren")}:</div> */}
                </div>
                {myDashboards != null ? 
                    (myDashboards.length > 0) ?
                    (
                        (isDisplayModeCategory ? 
                            (sortByCategory(myDashboards, true)
                            ):
                            (
                            <div className="search-list-mode">
                                {sortByAlphabet(myDashboards, true)}
                            </div>)
                        )
                    ) : 
                    (<span class="my-dashboards-notfound" style={{fontWeight: '500', fontSize: '16px'}}>{l("Keine Dashboards vorhanden")}</span>) : 
                <Loading />
                }
            </div>
            <div className="my-dashboards">
                <div className="my-dashboards-title">
                        <Button icon="pi pi-folder-open" tooltip={l("Ansicht nach Kategorie")} className={"my-dashboards-display-mode-button " + (isDisplayModeCategory ? "button-active" : "button-inactive")} onClick={() => setIsDisplayModeCategory(true)}/>
                        <Button icon="pi pi-list" tooltip={l("Ansicht als Liste")} className={"my-dashboards-display-mode-button " + (!isDisplayModeCategory ? "button-active" : "button-inactive")} onClick={() => setIsDisplayModeCategory(false)}/>
                        <span>{l("Meine abbonierten Dashboards")}:</span>
                    {/* <div>{l("Abonnieren")}:</div> */}
                </div>
                {subscribedDashboards != null ? 
                    (subscribedDashboards.length > 0) ?
                    (
                        (isDisplayModeCategory ? 
                            (sortByCategory(subscribedDashboards, false)
                            ):
                            (
                            <div className="search-list-mode">
                                {sortByAlphabet(subscribedDashboards, false)}
                            </div>)
                        )
                    ) : 
                    (<span class="my-dashboards-notfound" style={{fontWeight: '500', fontSize: '16px'}}>{l("Keine Dashboards vorhanden")}</span>) : 
                <Loading />
                }
            </div>
            <div className="my-printlayouts">
                <div className="my-printlayouts-title">
                        <span>{l("Meine Drucklayouts")}:</span>
                    {/* <div>{l("Abonnieren")}:</div> */}
                </div>
                {myPrintLayouts != null ? 
                    (myPrintLayouts.length > 0) ?
                    (
                            <div className="search-list-mode">
                                {sortPrintLayoutByAlphabet(myPrintLayouts)}
                            </div>
                    ) : 
                    (<span class="my-dashboards-notfound" style={{fontWeight: '500', fontSize: '16px'}}>{l("Keine Drucklayouts vorhanden")}</span>) : 
                <Loading />
                }
            </div>
        </div>
    )
}
