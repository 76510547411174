import React, { useEffect } from 'react'

export default function Impressum(props) {

    useEffect(() => {

        if(props.parentRef != null)
        {
        setTimeout(() => props.parentRef.current.scrollTop = 0,0)
        }
    }, [])


    return (
        <div className="impressum-content">
                    <h1>Impressum</h1><br />
                    <b>Herausgeber</b><br /><br />
                    <b>Statistische Bundesamt, vertreten durch den Präsidenten Dr. Georg Thiel, im Auftrag des Bundesministerium des Inneren für Bau und Heimat.</b><br /><br />
                    <b>Rechtsform: </b>Obere Bundesbehörde im Geschäftsbereich des Bundesministeriums des Innern, für Bau und Heimat<br /><br />
                    Statistisches Bundesamt<br />
                    Gustav-Stresemann-Ring 11<br />
                    65189 Wiesbaden<br />
                    <br />
                    Postfach-Anschrift:<br />
                    65180 Wiesbaden<br />
                    <br />
                    <a href="https://www.destatis.de/DE/Service/Kontakt/Kontakt.html">Kontaktformular</a><br />
                    Umsatzsteuer-Identifikationsnummer DE 206511374<br />
                    <br />
                    <br /><b>Presserechtlich verantwortlich:</b>
                    <br />Florian Burg 
                    <br />
                    <br /><b>Redaktion:</b>
                    <br />Tanja Mucha
                    <br />E-Mail: <a href="mailto:dashboard-deutschland@destatis.de">dashboard-deutschland@destatis.de</a>
                    <br />
                    <br /><b>Design und Realisierung: </b>
                    <br />Statistisches Bundesamt (Destatis)
                    <br />
                    <br /><b>Inhalte/Produktion: </b>
                    <br />Statistisches Bundesamt (Destatis)
                    <br />
                    <br /><b>Datenquellen: </b>
                    <br />Arbeitsgemeinschaft Deutscher Verkehrsflughäfen e. V.
                    <br />Bundesamt für Güterverkehr
                    <br />Bundesinstitut für Bau-, Stadt- und Raumforschung
                    <br />Bundesministerium der Finanzen
                    <br />Bundesministerium für Wirtschaft und Energie
                    <br />Bundesnetzagentur
                    <br />Bundesrepublik Deutschland Finanzagentur GmbH
                    <br />Bundesverband Materialwirtschaft, Einkauf und Logistik e. V.
                    <br />Deutsche Börse AG
                    <br />Deutsche Bundesbank
                    <br />Europäische Zentralbank
                    <br />European Energy Exchange AG
                    <br />finanzen.net GmbH
                    <br />Flightradar24 AB
                    <br />GfK SE
                    <br />Google LLC
                    <br />Handelsblatt Research Institute
                    <br />Handelsverband Deutschland
                    <br />hystreet.com GmbH
                    <br />ifo Institut - Leibniz-Institut für Wirtschaftsforschung an der Universität München e. V.
                    <br />IHS Markit Ltd.
                    <br />Institut für Arbeitsmarkt- und Berufsforschung
                    <br />Institut für Seeverkehrswirtschaft und Logistik
                    <br />Kraftfahrt-Bundesamt
                    <br />Kreditanstalt für Wiederaufbau
                    <br />Macrobond Financial AB
                    <br />MarketWatch
                    <br />Robert Koch-Institut
                    <br />RWI - Leibniz-Institut für Wirtschaftsforschung e. V.
                    <br />SCHUFA Holding AG
                    <br />Statistik der Bundesagentur für Arbeit
                    <br />Statistisches Bundesamt (Destatis)
                    <br />Teralytics AG
                    <br />Verband der Automobilindustrie e. V.
                    <br />Verband Deutscher Maschinen- und Anlagenbau e. V.
                    <br />ZEW - Leibniz-Zentrum für Europäische Wirtschaftsforschung GmbH
                    <br />
                    <br /><b>Hosting:</b>
                    <br />Diese Seite wird über Amazon Web Services (AWS) [410 Terry Avenue North, Seattle WA 98109, United States] auf Servern in der Europäischen Union betrieben.
                    <br />
                    <br /><b>Haftungsausschluss:</b>
                    <br />Die Inhalte dieser Website werden mit größtmöglicher Sorgfalt recherchiert und implementiert. Fehler im Bearbeitungsvorgang sind dennoch nicht auszuschließen. Hinweise und Korrekturen senden Sie uns bitte über unser <a href="https://www.destatis.de/DE/Service/Kontakt/Kontakt.html">Kontaktformular</a>. 
                    <br />Eine Haftung für die Richtigkeit, Vollständigkeit und Aktualität dieser Webseite kann trotz sorgfältiger Prüfung nicht übernommen werden. Das Statistische Bundesamt übernimmt insbesondere keinerlei Haftung für eventuelle Schäden oder Konsequenzen, die durch die direkte oder indirekte Nutzung der angebotenen Inhalte entstehen. 
                    <br />Neben den eigenen Inhalten werden Daten von weiteren Datenlieferanten (siehe Rubrik Datenquellen) bereitgehalten. Das Statistische Bundesamt haftet nicht für diese Inhalte. Die Verantwortlichkeit liegt alleine bei den Datenlieferanten. 
                    <br />Bei Nutzungsausfällen der Website wegen technischer oder organisatorischer Störungen bestehen keinerlei Ansprüche - gleich welcher Art - gegen das Statistische Bundesamt. 
                    <br />
                    <br /><b>Datenschutz:</b>
                    <br />Das Statistische Bundesamt nimmt den Schutz Ihrer Privat- und Persönlichkeitssphäre ernst und beachtet die datenschutzrechtlichen Bestimmungen. Ihr Vertrauen ist uns wichtig!
                    <br />
                    <button className="link-button" onClick={() => props.onClickDatenschutz()}>[Mehr...]</button><br /><br />
                    <br /><b>Copyright </b>
                    <br />© Statistisches Bundesamt (Destatis), 2020
                    <br /><br />
                </div>
    )
}
