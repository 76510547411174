import React, { useEffect, useState, useLayoutEffect } from 'react'
import { WidthProvider, Responsive } from "react-grid-layout";

import { readAllTilesFromTags, readAllTags } from '../../../app_gateway/tags/TagAG';
import DashboardTile from '../DashboardTile';
import { getFromCache } from '../../util/Cache';

import { PATH_TAGS } from '../../main/Routing';
import Layout from '../Layout';
import { setFuncToGetLayout, setLastDashboard } from '../../util/CentralStore';
import DashboardTopbar from '../DashboardToolbar';
import DashboardLastUpdatedToolbar from '../DashboardLastUpdatedToolbar';
import { IS_FOR_COCKPIT_VERSION } from '../../../app_gateway/Constants';
import { readAllLastTiles } from '../../../app_gateway/dashboard/DataAG';
import { readTiles } from '../../../app_gateway/dashboardtile/TileAG';
import { getCachedCategories } from '../../../app_gateway/category/CategoryAG';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const keycodes = [38, 37, 40, 39];

export default function TagsDashboard(props) {

  useEffect(() => {
      readMyDashboard();
      if(props.innerContent !== null)
      {
        setTimeout(() => props.innerContent.scrollTop = 0,1)
      }
  }, [props.location.pathname])

  const [cards, setCards] = useState(null);
  const [layout, setLayout] = useState({});
  const [activeElement, setActiveElement] = useState(null);
  const [tags, setTags] = useState("");

  const readMyDashboard = () =>
  {
    setCards(null);
    setLastDashboard(null);
    if(props.mode_new) {

    }
    else{
      readFromTags();
    }
  }

  
  const readFromTags = () => {
    let tags = props.match.params.id;
    if(!tags)
    {
      tags = "";
    }
    setTags(tags);
    if(tags.startsWith("indicators"))
    {
      getCachedCategories().then((cats) => {
        getFromCache(PATH_TAGS + "/" + tags, 5000, readTiles, tags.split(":")[1]).then(response => {
          setCards(null);
          response = response.map(e => {e.cat = cats.find(c => e.category === c.name); return e});
          createTiles(response.sort((a, b) => a.cat?.catIndex - b.cat?.catIndex));
        });
      });
    }
    else
    {
      getFromCache(PATH_TAGS + "/" + tags, 5000, readAllTilesFromTags, tags).then(response => {
        setCards(null);
        createTiles(response);
      });
    }
  }

  const selectLastTiles = (time, cats) => {
    readAllLastTiles(time.getTime(), cats).then(response => {
      setCards(null);
      createTiles(response);
    })
  }

  const getValidValue = (value, defaultVal) =>
  {
    if(value > 0)
      return value;
    return defaultVal;
  }

  const createTiles = (tiles) => 
  {
    if(tiles) {
      let nextX = 0;
      let nextY = 0;
      let lastHeight = 0;
      let freeSpace = [];
      let c = tiles.filter(r => r.hidden === false).map((r, i) => {let x = nextX;
        let y = nextY;
        let width = getValidValue(r.width, 3);
        let height = getValidValue(r.height, 3);
        let fs = freeSpace.filter(space => space.width >= width);
        if(fs.length > 0)
        {
          x = fs[0].x;
          y = fs[0].y;
          freeSpace = freeSpace.filter(space => space.x !== x && space.y !== y);
        }
        else
        {
          if(x + width > Layout.cols)
          {
            if(x < Layout.cols)
            {
              freeSpace = [...freeSpace, {x: x, y: y, width: Layout.cols - x}]
            }
            x = 0;
            nextX = 0;
            y+=lastHeight;
            nextY+=lastHeight;
          }
          nextX+=width;
          if(nextX >= Layout.cols)
          {
            nextX = 0;
            nextY += height;
          }
        }
        lastHeight = height;
        return <div aria-label={r.title} onFocus={() => setActiveElement(r.id)} tabIndex={0} key={r.id} data-grid={{
        x: x,
        y: y,
        w: width,
        h: height,
        minW: getValidValue(r.minWidth, undefined),
        minH: getValidValue(r.minHeight, undefined),
        maxW: getValidValue(r.maxWidth, undefined),
        maxH: getValidValue(r.maxHeight, undefined),
        static: false }}
        style={{ zIndex: -1}}>
          <DashboardTile id={r.id}  attachments={r.attachments} date={r.date} audited={r.audited} enableNotification={!IS_FOR_COCKPIT_VERSION}/>
        </div>});
      
      setCards(c);
    }
  }
  
  setFuncToGetLayout(() => {
    return layout;
  });

  const setKeyDown = (e, id) => {
    let s = e.shiftKey;
    let c = e.ctrlKey;
    if(id && id !== null && keycodes.includes(e.keyCode) && (s || c))
    {
      e.preventDefault();
      for(let breakpoint in layout)
      {
        let tile = layout[breakpoint].find(e => e.i === id);
        if(tile)
        {
          switch(e.keyCode)
          {
            //UP
            case 38: s ? (tile.h > 1 ? tile.h = tile.h - 1 : tile.h = 1) : tile.y > 0 ? tile.y = tile.y - 1 : tile.y = 0; break;
            //LEFT
            case 37: s ? (tile.w > 1 ? tile.w = tile.w - 1 : tile.w = 1) : tile.x > 0 ? tile.x = tile.x - 1 : tile.x = 0;  break;
            //DOWN
            case 40: s ? tile.h = tile.h + 1 : tile.y = tile.y + 1; break;
            //RIGHT
            case 39: s ? tile.w = tile.w < Layout.cols ? tile.w + 1 : tile.w = Layout.cols : tile.x + tile.w < Layout.cols ? tile.x = tile.x + 1 : tile.x = Layout.cols - tile.w; break;
            default: break;
          }
        }
        let l = layout[breakpoint].filter(e => e.id !== id);
        l.push(tile);
        layout[breakpoint] = l;
      }
      setLayout({...layout})
    }
  }

  let l = JSON.parse(JSON.stringify(layout));

  return (
    <div aria-label={"Dashboard nach Tags " + tags} onKeyDown={(e) => setKeyDown(e, activeElement)}>
      {!IS_FOR_COCKPIT_VERSION && 
        props.mode_new ? 
        <DashboardLastUpdatedToolbar pathid={props.match.params.id} printDisabled={cards === null || cards.length === 0} onUpdateSelection={(time, cats) => selectLastTiles(time, cats)}/>
          : <DashboardTopbar pathid={props.match.params.id} showTags={!tags.startsWith("indicators")} printDisabled={cards === null || cards.length === 0} />}
        <ResponsiveReactGridLayout aria-label={"Dashboard nach Tags " + tags} className={"layout"} style={{zIndex:'0'}}
          layouts={l}
          onLayoutChange={(layout, layouts) => {
            setLayout(layouts)
            window.dispatchEvent(new Event('resize'));
          }}
          rowHeight={Layout.rowHeight}
          cols={Layout.layout}
          draggableHandle=".sticky-header"
          draggableCancel={".card-title-functions,.card-title-category-icon"}
          {...props}
        >
          {cards}
        </ResponsiveReactGridLayout>
    </div>
  );
}
