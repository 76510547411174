import React, { useState, useRef, useEffect } from 'react'

const PubSub = require('pubsub-js');

export default function Tooltip(props) {

    const [timer, setTimer] = useState(null)
    const tooltip = useRef(null)

    const show = () => {
        if(!props.disable)
        {
            setTimer(setTimeout(() => {
                PubSub.publish("global.tooltip", {
                    label: props.label, 
                    pos: tooltip.current.getBoundingClientRect(),
                    top: props.top,
                    left: props.left,
                    right: props.right,
                    bottom: props.bottom,
                    auto: props.auto
                });
            }, props.delay ? props.delay : 0));
        }
    }

    const hide = () => {
        clearTimeout(timer);
        PubSub.publish("global.tooltip", null);
    }

    useEffect(() => {
        if(props.disable)
            hide();
    }, [props.disable])

    return (
        <div ref={tooltip} /*onPointerDown={() => {if(props.onHover) {show(0); setTimeout(() => hide(), 3000)}}} */ onMouseEnter={(e) => show(e)} onMouseLeave={() => hide()} className ={props.className} style={props.style}>
            {props.children}
        </div>
    )
}
