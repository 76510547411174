import { mdiInformationOutline } from '@mdi/js';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'

import '../../styles/search/dashboardsearch.css'
import Icon from '../util/Icon';
import IconButton from '../util/IconButton';
import SelectButton from '../util/SelectButton';
import SearchBar from './SearchBar';
import SearchContentDashboards from './SearchContentDashboards';
import SearchContentIndicator from './SearchContentIndicator';

export default function DashboardSearch() {

    const [searchValues, setSearchValues] = useState([]);
    const [infoVisible, setInfoVisible] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [searchOption, setSearchOption] = useState({name: 'Indikatoren', code: 1})

    const searchOptions = [
        {name: 'Indikatoren', code: 1},
        {name: 'Freigegebene Dashboards', code: 2}
    ];

    const footer = (
        <div>
            <Button label="Abbrechen" icon="pi pi-times" onClick={() => setShowDialog(false)} />
            <Button label="Bestätigen" icon="pi pi-check" onClick={() => {setSearchOption(showDialog); setSearchValues([]); setShowDialog(false)}} />
        </div>
    );

    return (
        <div className="dashboard-search-root">
            <Dialog header={"Suchmodus wechseln"} visible={showDialog != false} style={{ width: '50vw' }} footer={footer} onHide={() => setShowDialog(false)}>
                <div className="dashboard-search-dialog-content">
                    Ihre bisherige Suche wird zurückgesetzt.<br /><br />Sind Sie sicher, dass sie den Suchmodus wechseln möchten?
                </div>
            </Dialog>
            <div className="dashboard-search-content">
                <div className="dashboard-search">
                    <div className="dashboard-search-title">
                        <h2>Was möchten Sie suchen?</h2>
                    </div>
                    <div className="dashboard-search-options">
                    <SelectButton optionLabel="name" value={searchOption} options={searchOptions} onChange={(e) => searchValues.length > 0 ? setShowDialog(e) : setSearchOption(e)}></SelectButton>
                    </div>

                    {searchOption != null && <>
                        <div className="dashboard-search-title">
                            <h2>Geben Sie einen Suchbegriff ein.</h2>
                            <IconButton className="dashboard-search-info" mdi={{path: mdiInformationOutline, color: '#444', size: '24px'}} onClick={() => setInfoVisible(!infoVisible)} />
                        </div>
                            {infoVisible && 
                            <div className="dashboard-search-hinttext">
                                <Icon mdi={{path: mdiInformationOutline, color: '#444', size: '24px'}} />
                                {searchOption.code == 1 ? 
                                <div>Hier können Sie nach Indikatoren suchen und daraus ein Dashboard erstellen.<br />Geben Sie hierzu einen Suchbegriff ein. Sie können die gefundenen Indikatoren auswählen und ein Dashboard generieren.</div>
                                : <div>Hier können Sie freigegebene Dashboards anderer Nutzer/-innen suchen und aufrufen.<br />Geben Sie hierzu den Namen des Dashboards ein. Alternativ kann nach Tags (Stichwörtern) gesucht werden, die für erstellte Dashboards vergeben wurden.</div>
                                }
                            </div>
                            }
                        <div className="dashboard-search-bar">
                            <SearchBar mode={searchOption.code} values={searchValues} onChange={(values) => setSearchValues(values)}/>
                        </div>
                    </>
                    }

                </div>
                {searchOption != null && <div className="dashboard-search-result">
                {
                    searchOption.code === 2 ? 
                        <SearchContentDashboards searchValue={searchValues} /> :
                        <SearchContentIndicator searchValue={searchValues} />
                }
                </div>
                }
            </div>

        </div>
    )
}
