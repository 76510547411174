import { mdiDatabaseEdit, mdiTimelapse, mdiTimelineClockOutline } from '@mdi/js';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, {useState, useRef, useEffect} from 'react'
import { readAuditDates } from '../../../app_gateway/dashboardtile/TileAG'
import Icon from '../../util/Icon';
import CardHeaderMenuEntry from './CardHeaderMenuEntry';
        
const formatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

export const getFormattedDate = date => {
  let formattedDate;
  if(Number.isNaN(Number(date)))
  {
    formattedDate = date === "now" ? "Aktueller Stand" : date;
  }
  else{
    formattedDate = new Date(Number(date)).toLocaleDateString('de-DE', formatOptions);
  }
  return formattedDate;
}

export default function AuditMenu(props) {

    const wrapperRef = useRef(null);

    const [showMenu, setShowMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const [auditedTiles, setAuditedTiles] = useState(null)

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    useEffect(() => {
      if(showMenu)
        document.addEventListener("mousedown", handleClickOutside);
      else
        document.removeEventListener("mousedown", handleClickOutside);

    }, [showMenu])

    const openMenu = () => {
        if(!showMenu) {
            if(auditedTiles == null) {
                setLoading(true);
                readAuditDates(props.tileid).then(response => {
                    setAuditedTiles(["now", ...response.sort((a, b) => b.id - a.id)]);
                    setShowMenu(!showMenu);
                    setLoading(false);
                })
            }
            else {
                setShowMenu(!showMenu)
            }
        }
        else {
            setShowMenu(!showMenu)
        }     
    }

    const showAudit = (date) => {
      props.onChangeDate(date);
    }

    return (
        <div className="btn-card-title-menu" ref={wrapperRef} data-html2canvas-ignore="true">
            <button aria-label={"Alte Stände einsehen"} className={"p-link"} onClick={() => openMenu()}>
                {
                  loading ? <ProgressSpinner className="progress-spinner-anim-disabled" style={{width: '25px', height: '25px'}} strokeWidth="5" animationDuration="1.5s"/>:
                  <Icon mdi={{path: mdiTimelineClockOutline}}/>
                }
            </button>

            <div className={"card-title-menu " + (showMenu ? "" : "hidden")} style={{right: '0px'}}>
              {auditedTiles != null && auditedTiles.length > 0 && auditedTiles.map((auditedTile, index) => 
              {
                let date = auditedTile === 'now' ? 'now' : auditedTile.dataVersionDate != null ? auditedTile.dataVersionDate : auditedTile.date;
                let formattedDate = getFormattedDate(date);
                return <CardHeaderMenuEntry mdi={{path: mdiTimelineClockOutline, title: formattedDate}} key={index} label={formattedDate} onClick={() => {
                    showAudit(auditedTile === 'now' ? {id: 'now'} : auditedTile);
                    setShowMenu(false); 
                  }}/>
                  
              })}
            </div>
        </div>
    )
}
