import React from 'react'
import Icon from '../util/Icon'

export default function CategoryIcon(props) {

    if(props.category?.iconType === "singlesvg")
    {
        return (
            <Icon mdi={{path: props.category?.icon, size: props.size, viewBox: props.category?.viewBox}} />
        )
    }
    else if(props.category?.iconType === "multisvg")
    {
        return (
            <Icon custom={{paths: props.category?.icon, size: props.size, viewBox: props.category?.viewBox}} />
        )
    }
    else return <Icon icon={props.category?.icon} />
    
}
