import React from 'react'

export default (props) => {

    return (
        props.if ? 
            props.then : 
            props.else !== undefined ? props.else : <div />
    )
}
