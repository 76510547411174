import React from 'react'
import Icon from './Icon'
import {InputSwitch} from 'primereact/inputswitch';

export default function SwitchButton(props) {
    return (
        <button aria-label={props.title} disabled={props.disabled} {...props} className={"p-link icon-button switch-button " + props.className + (props.disabled ? " disabled" : "")} onClick={props.onClick}>
            {props.textleft ? props.textleft : null}
            {/* {(props.icon || props.mdi) &&< Icon style={props.style} className={props.className} icon={props.icon} mdi={props.mdi} />} */}
            <InputSwitch style={{marginLeft: props.textleft ? "7px" : "0px", marginRight: props.textright ? "7px" : "0px"}} checked={props.checked} onChange={(e) => props.onClick(e.value)} />
            {props.textright ? props.textright : null}
            {props.children}
        </button>
    )
}
