import React, { useEffect, useState } from 'react'
import {InputSwitch} from 'primereact/inputswitch';
import { findDashboards } from '../../app_gateway/dashboard/DataAG'
import { PATH_DASHBOARD, PATH_TAGS } from '../main/Routing'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Tree } from 'primereact/tree';
import { getMyDashboards, addToMyDashboards, removeFromMyDashboards } from '../../app_gateway/store/MyDashboardsStore';
import Loading from '../util/Loading';
import { l } from '../main/Main';
import { getUser } from '../../app_gateway/authentication/AuthenticationStore';
import { getFromCache } from '../util/Cache';
import { readAllTilesFromTags } from '../../app_gateway/tags/TagAG';
import { getCachedCategories } from '../../app_gateway/category/CategoryAG';
import CategoryIcon from '../category/CategoryIcon';
import { Checkbox } from 'primereact/checkbox';
import { mdiCancel, mdiCheckboxMarkedCircleOutline, mdiInformationOutline, mdiWrench } from '@mdi/js';
import Icon from '../util/Icon';

export default function     SearchContentIndicator(props) {

    const [loading, setLoading] = useState(false);
    const [indicators, setIndicators] = useState([]);
    // const [categories, setCategories] = useState([]);
    const [selectedIndicators, setSelectedIndicators] = useState([]);


    useEffect(() => {
        setLoading(true);
        getCachedCategories().then((cats) => {
            getFromCache(PATH_TAGS + "/" + tags, 5000, readAllTilesFromTags, tags).then(response => {
                setLoading(false);
                setIndicators(response.map(e => {e.cat = cats.find(c => e.category === c.name); return e}));
                setSelectedIndicators(response);
              });
        });
        let tags = props.searchValue.map(sv => sv.type + ":" +sv.value).join(";");
    }, [props.searchValue])

    const joinSelectedValues = () => {
        return "indicators:" + selectedIndicators.map(s => s.id).join(",");
    }

    
    // const getCat = (indicator) => {
    //     return categories.find(c => indicator.category === c.name);
    // }

    let filteredIndicators = indicators != null ? indicators.filter(i => i.hidden === false) : null;

    return (
        <div className="search-content-indicator">
            {loading && <Loading small horizontal/>}
            <div className="search-content-indicator-title">
                <h2>{filteredIndicators != null && filteredIndicators.length > 0 ? ("Gefundene Indikatoren ("+filteredIndicators.length+")") : 
                props.searchValue == null || props.searchValue.length === 0 ? "" : 
                "Es wurden keine Indikatoren gefunden"}</h2>
                {filteredIndicators != null && filteredIndicators.length > 0 && <div>
                    <button className="p-link search-content-indicator-check" onClick={() => setSelectedIndicators(indicators)}><div>alles auswählen</div></button>
                    <button className="p-link search-content-indicator-uncheck" onClick={() => setSelectedIndicators([])}><div>nichts auswählen</div></button>
                </div>}
            </div>
            <div className="search-content-indicator-items">
                {filteredIndicators.sort((a, b) => a.cat?.catIndex - b.cat?.catIndex ).map(i => {
                    let selected = selectedIndicators.includes(i);
                    return <button key={i.id} className={"p-link search-content-indicator-item" + (selected ? " selected" : " unselected")} onClick={() => {
                        if(selected) {
                            setSelectedIndicators(selectedIndicators.filter(s => s !== i))
                        }
                        else {
                            setSelectedIndicators([...selectedIndicators, i])
                        }
                    }}>
                                <div className="search-content-indicator-item-category"  style={{backgroundColor: i.cat?.color}}>
                                    <CategoryIcon category={i.cat} />
                                </div>
                                <div className="search-content-indicator-item-title">
                                    {i.title}
                                </div>
                                <div className="search-content-indicator-item-checkbox">
                                    {selected ? 
                                        <Icon mdi={{path: mdiCheckboxMarkedCircleOutline, color: 'white', size: '24px'}} /> :
                                        <Icon mdi={{path: mdiCancel, color: 'white', size: '24px'}} />
                                    }
                                </div>
                        </button>
                })}
            </div>
            {indicators != null && indicators.length > 0 && 
            <div className="search-content-indicator-footer">
                {selectedIndicators.length === 0 && <div className="search-content-indicator-create-no-selection-hint">
                    <Icon mdi={{path: mdiInformationOutline, color: '#444', size: '16px'}} />
                    Wählen Sie mindestens einen Indikator aus<br/>um ein Dashboard erstellen zu können.
                    
                </div>}
                <button disabled={selectedIndicators.length === 0} className={"p-link search-content-indicator-create" + (selectedIndicators.length <= 0 ? " disabled" : "")} 
                    onClick={() => {
                        if(selectedIndicators.length > 0) {
                            window.location = "#" + PATH_TAGS + "/" + joinSelectedValues();
                        } 
                    }}>
                    <Icon mdi={{path: mdiWrench, color: '#005cb3', size: '20px'}} />
                    <div>Dashboard aus Ergebnissen erstellen</div>
                </button>
            </div>}
        </div>
    )
}
