import React, { useState, useEffect } from 'react'
import { getUser } from '../../../app_gateway/authentication/AuthenticationStore'
import Icon from '../../util/Icon';
import { l } from '../Main';

export default function AddDashboardButton(props) {
    
    const [hover, setHover] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show === props.icon);
    }, [props.show])

    return (
        getUser() !== null ? 
        <div aria-label={l("Neues Dashboard erstellen")} className="sidebar-button" onMouseEnter={(e) => setHover(true)} onMouseLeave={() => setHover(false)}>
            <button aria-label={props.title} className={"p-link "+(hover || show ? "hover" : "")} onClick={() => {
                if(hover) {
                    setHover(false); props.onClick()
                }
                else {
                    props.onShow(show ? "" : props.icon);
                }
                }}>
               <Icon icon={props.icon} />
            </button>
            <div className={"hover-menu "+(hover || show ? "" : "hidden")}>
            <div className="hover-menu-title top" style={{cursor: 'pointer'}} onClick={() => {setHover(false);props.onClick()}}>
                {l("Neues Dashboard erstellen")}</div>
            </div>
            
           
        </div> : null
    
    )
}
