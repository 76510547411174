import React from 'react'
import Icon from '../../util/Icon'

export default function CardHeaderMenuEntry(props) {
    return (
        <div className="card-title-entry">
            <button className={"p-link"} onClick={() => props.onClick()}>
                <Icon {...props} onClick={() => {}} />
                {props.label}
            </button>
        </div>
    )
}
