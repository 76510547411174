import React, {useState, useEffect} from 'react'
import { AutoComplete } from 'primereact/autocomplete'
import { PATH_TAGS, PATH_PRINTSELECTION, PATH_CREATE_EXISTING_DASHBOARD } from '../main/Routing'

import '../../styles/dashboardtoolbar.css';
import IconButton from '../util/IconButton';
import { getUser } from '../../app_gateway/authentication/AuthenticationStore';
import { l } from '../main/Main';
import { getCachedCategories } from '../../app_gateway/category/CategoryAG';
import { getFromCache } from '../util/Cache';
import { readAllTags } from '../../app_gateway/tags/TagAG';
import Icon from '../util/Icon';
import { mdiCardTextOutline, mdiCat, mdiFolder, mdiTag, mdiText } from '@mdi/js';

export default function DashboardToolbar(props) {

    const [suggestions, setSuggestions] = useState([])
    const [allSuggestions, setAllSuggestions] = useState([])

    useEffect(() => {
        getCachedCategories().then(cats => {
            getFromCache("alltags", 5000, readAllTags).then(tags => {
                let s = [...cats.map(c => {return {type: "c", value: c.name}}), ...tags.map(t => {return {type: "t", value: t}})]
                setAllSuggestions(s);
            });
        })
        
    }, [])

    const addNewTag = (event) => {
        let val = event.target.value;
        if(event.key === "Enter" && val !== "" && !val.startsWith(" ") && (props.pathid == null || !props.pathid.includes(val)))
        {
            event.target.value = "";
            window.location = "#" + PATH_TAGS + "/" + (props.pathid != null ? (props.pathid + ";") : "") + "ft:" + val.replace("&", "%26");
        }
    }

    const getValues = () => {
        if(props.pathid)
        {
           let values = props.pathid.split(";").map(a => {
               return {
                   type: a.split(":")[0],
                   value: a.split(":")[1]
               }
           });
           return values;
        }
        return [];
    }

    const onSuggestTags = (event) => {
        let results = allSuggestions.filter((tag) => {
             return tag.value.toLowerCase().startsWith(event.query.toLowerCase());
        });
        setSuggestions(results);
    }

    const itemTemplate = (e) => {
        return <div className="dashboard-toolbar-suggestion">
            {e.type === "t" &&
                <Icon mdi={{path: mdiTag, color: 'gray', size: '16px', viewBox:"0 -2 24 24"}} />}
            {e.type === "c" &&
                <Icon mdi={{path: mdiFolder, color: 'gray', size: '16px', viewBox:"0 -2 24 24"}} />}
            {e.value}    
        </div>
    }

    const selectedItemTemplate = (item) => {
        let val = item.value ? item.value : item.type;
        return <div className={"taglist-item " + val}  >
             {item.type === "t" &&
                <Icon mdi={{path: mdiTag, color: 'white', size: '16px', viewBox:"0 -2 24 24"}} />}
            {item.type === "ft" &&
                <Icon mdi={{path: mdiCardTextOutline, color: 'white', size: '16px', viewBox:"0 -2 24 24"}} />}
            {item.type === "c" &&
                <Icon mdi={{path: mdiFolder, color: 'white', size: '16px', viewBox:"0 -2 24 24"}} />}
            {val.replace("%26", "&")}</div>
    }

    const joinSelectedValues = (values) => {
        if(values.length > 0) {
            return values.map(v => v.type + ":" + v.value.replace("&", "%26")).join(";")
        }
        else {
            return "";
        }
    }

    return (
        <div aria-label={"Toolbar"} className="dashboard-toolbar">
            <div className="dashboard-toolbar-left">
                {props.addTile ? <IconButton id="dashboardtoolbar.addtile" title={l("Kachel hinzufügen")} className={props.addTile.toggle?"toggle":""} icon="add" textright={l("Kachel hinzufügen")} onClick={() => {props.addTile.setToggle();props.addTile.onClick()}} /> : null}
                {getUser() !== null ? <IconButton icon="save" title={l("Dashboard speichern")} textright={l("Dashboard speichern")} onClick={() => window.location = '#'+PATH_CREATE_EXISTING_DASHBOARD} /> : null}
                {props.onDelete ? <IconButton icon="delete" title={l("Dashboard löschen")} textright={l("Dashboard löschen")} onClick={() => {props.onDelete()}} /> : null}
                <IconButton disabled={props.printDisabled} title={l("Dashboard drucken")} icon="print" textright={l("Dashboard drucken")} onClick={() => window.location = '#'+PATH_PRINTSELECTION} />
            </div>
            <div className="dashboard-toolbar-center">

            </div>
            <div className="dashboard-toolbar-right">
                {props.showTags ? 
                    <div aria-label={"Filtern nach Tags"} className="dashboard-toolbar-tags">
                        <div aria-label={"Filtern nach Tags"} className="dashboard-toolbar-text">{l("Filtern und Suchen")}:</div>
                        <AutoComplete ariaLabelledBy={"Tags eingeben"}
                        appendTo={"self"}  multiple={true} dropdown={true} minLength={0}  value={getValues()} onChange={(e) => window.location = "#" + PATH_TAGS + "/" + joinSelectedValues(e.value)}
                            suggestions={suggestions} onKeyUp={addNewTag} completeMethod={onSuggestTags} itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate}
                        />
                    </div>
                : null}
            </div>
      </div>
    )
}
