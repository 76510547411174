import { mdiCheckCircleOutline, mdiCheckboxBlankCircleOutline, mdiChartLine, mdiCheckOutline, mdiCheckboxMarkedCircle, mdiCheckboxMarkedCircleOutline, mdiEyeOutline } from '@mdi/js';
import React, { useRef, useState, useEffect } from 'react'
import Icon from '../../util/Icon';
import CardHeaderMenuEntry from './CardHeaderMenuEntry';

export default function ViewOptions(props) {

    const wrapperRef = useRef(null);
    const [showMenu, setShowMenu] = useState(false);
    const [showComponents, setShowComponents] = useState([]);

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowMenu(false)
      }
    }

    useEffect(() => {
      if(showMenu)
        document.addEventListener("mousedown", handleClickOutside);
      else
        document.removeEventListener("mousedown", handleClickOutside);

    }, [showMenu])

    useEffect(() => {
      let comps = [];
      if(props.components != null) {
        comps = props.components.filter(c => c.type !== "compact" && c.type !== "teaser").map((c, i) => {
          return {
            show: true,
            comp: c,
            id: i
          }
        })
      }
      if(props.hideComponents != null) {
        
        setShowComponents(comps.map(c => {
            let found = props.hideComponents.find(hc => c.id === hc.id);
            if(found != null) {
              c.show = found.show;
              return c;
            }
            return c;
        }))
      }
    }, [props.hideComponents])

    const onClickComponent = (component, index) => {
      setShowMenu(false); 
      showComponents.forEach(c => {
        if(c.id === component.id) {
          if(props.onClick({...c, show: !c.show}))
            c.show = !c.show;
        }
        return c;
      })
      // if(props.onClick(clickedComponent)) {
        // setShowComponents(newComp);
      // }
    }
    return (
        props.components != null && props.components.length > 0 ?
        
        <div className="btn-card-title-menu" ref={wrapperRef} data-html2canvas-ignore="true">
            <button aria-label={"Ansicht verwalten"} className={"p-link"} onClick={() => setShowMenu(!showMenu)}>
                {
                  <Icon mdi={{path: mdiEyeOutline}} />
                  // <i className="material-icons">visibility</i>
                }
            </button>

            <div className={"card-title-menu " + (showMenu ? "" : "hidden")} style={{right: '0px'}}>
              {showComponents.map((c, index) => 
              {

                var icon = mdiCheckCircleOutline;
                if(!c.show) 
                  icon = mdiCheckboxBlankCircleOutline;
                let title;
                switch(c.comp.type)
                {
                  case "text": title = (index + 1) + ": Begleittext"; break;
                  case "highcharts": title = (index + 1) + ": Diagramm"; break;
                  case "table": title = (index + 1) + ": Tabelle"; break;
                  case "iframe": title = (index + 1) + ": Fenster"; break;
                  default: title = ""; break;
                }

                return <CardHeaderMenuEntry mdi={{path: icon, title: title}} key={index} label={title} onClick={() => onClickComponent(c, index)}/>
                  
              })}
            </div>
        </div> : null
    )
}
