import React, { useRef, useState, useEffect } from 'react'

export default function TooltipComponent(props) {

    const tooltip = useRef(null)
    const label = useRef(null)
    const [top, setTop] = useState(0)
    const [left, setLeft] = useState(0)
    const [show, setShow] = useState(false)
    const [pos, setPos] = useState("right")
    const [offsetLeft, setOffsetLeft] = useState(0)

    useEffect(() => {

        var orientation = props.left ? "left" : props.top ? "top" : props.bottom ? "bottom" : "right";
        var pos = getPosition(orientation);
        if(pos.left < 0)
        {
            if(props.auto) {
                pos = getPosition("right");
            } else {
                pos = getPosition(pos, pos.left * -1);
            }
        }
        else if(pos.left + tooltip.current.offsetWidth > window.innerWidth)
        {
            if(props.auto) {
                pos = getPosition("left");
            } else {
                let offset = (pos.left + tooltip.current.offsetWidth - window.innerWidth) * -1
                setOffsetLeft(offset * -1)
                pos = getPosition(orientation, offset);
            }
        }
        else if(pos.top < 0)
        {
            if(props.auto) {
                pos = getPosition("bottom");
            }
        }
        else if(pos.top + tooltip.current.offsetHeight > window.innerHeight)
        {
            if(props.auto) {
                pos = getPosition("top");
            }
        }
        setLeft(pos.left);
        setTop(pos.top);
        setShow(true);
    }, [])

    const getPosition = (pos, offset) =>
    {

        if(!offset)
            offset = 0;
        let left = 0;
        let top = 0;
        switch(pos)
        {
            case "left":
                left = props.pos.left - tooltip.current.offsetWidth + offset;
                top = (props.pos.top - 5);
                break;
            case "right":
                left = (props.pos.left + props.pos.width) + offset;
                top = (props.pos.top - 5);
                break;
            case "top":
                left = (props.pos.left - (tooltip.current.offsetWidth / 2) + (props.pos.width / 2) + 3) + offset;
                top = (props.pos.top - tooltip.current.offsetHeight - 5);
                break;
            case "bottom":
                left = (props.pos.left - (tooltip.current.offsetWidth / 2) + (props.pos.width / 2) + 3) + offset;
                top = (props.pos.top + props.pos.height);
                break;
            default: break;
        }
        setPos(pos);
        return {left: left, top: top}
    }

    var fade = pos === "bottom" ? "tt-fadeInUp" : pos === "left" ? "tt-fadeInLeft" : pos === "top" ? "tt-fadeInDown" : "tt-fadeInRight" ;

    return (
        <div ref={tooltip} className={"global-tooltip "+fade} style={{top: top, left: left, flexFlow: pos === "top" || pos === "bottom" ? "column" : "row", visibility: show? "visible": "hidden"}}>
            {pos === "right" ?<div className="global-tooltip-arrow-left" /> : null}
            {pos === "bottom" ?<div className="global-tooltip-arrow-bottom" style={{marginLeft: offsetLeft + "px"}}/> : null}
            <div ref={label} className="global-tooltip-label">{props.label}</div>
            {pos === "top" ?<div className="global-tooltip-arrow-top" style={{marginLeft: offsetLeft + "px"}} /> : null}
            {pos === "left" ?<div className="global-tooltip-arrow-right" /> : null}
        </div> 
    )
}
