
// STAGING GOV APP
//export const PRODUCTION_URL = "http://acd64f07861db4a2798e0167ff8c99a5-102795349.eu-central-1.elb.amazonaws.com"
export const SERVER_CONTEXT = "";
const PRODUCTION_URL = window.location.origin + SERVER_CONTEXT
export const BASE_URL = process.env.NODE_ENV === 'production' ? PRODUCTION_URL :'';
//export const BASE_URL = window.location.origin;
export const WEB_URL = process.env.NODE_ENV === 'production' ? PRODUCTION_URL :'http://localhost:3000';
//export const WEB_URL = window.location.origin;
export const API_PATH = '/api';
export const DATA_PATH = '/data';
export const SSE_PATH = API_PATH + '/sse/register';
//export const API_BASE_URL = '/api';
export const ACCESS_TOKEN = 'accessToken';
export const TOKEN = 'Bearer ';
export const HOME_DASHBOARD = "Aktuelles";

export const ONLY_WITH_LOGIN = true
//process.env.REACT_APP_ONLY_WITH_LOGIN === 'true';

export const IS_FOR_COCKPIT_VERSION = false;

// export const BASIC_U = "J6MBt9Q4Wu24P2xYzCTmB9erBhP6UqQNjYKK2wP8TrMpw9GN";
// export const BASIC_P = "3ePFmtj9NcCZQFy63EFa76BQjzeGvUteechqDJBqr6jgUx5T";

export const VERSION = "2.0.5";
