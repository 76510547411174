import { getToken, removeUser } from "./authentication/AuthenticationStore";
import { BASE_URL } from "./Constants";
import { PATH_ROOT } from "../components/main/Routing";
// var accessJwt = null;

export function restRequest(options) {
    const token = getToken();
    const headers = new Headers({
        "Content-Type": "application/json",
    });
    if (token) {
        headers.append("Authorization", "Bearer " + token);
    }

    return fetch(BASE_URL + options.url, { headers, ...options }).then((response) => {
        // Check if user is not authorized
        if (response.status === 401 || response.status === 403) {
            // Redirect to login
            console.warn("User is not authorized, returning to login screen");
            removeUser();
            // Forward to root instead of login path, since weird # routing will
            // make problems with some domain names in different envs
            window.location.href = PATH_ROOT;
            return;
        }

        // Check if server error occured
        if (response.status >= 500) {
            // Redirect to login
            console.error("500 server error occured.");
            return;
        }

        return response.json().then((json) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        }).catch(e => {
            // Error in JSON
            return Promise.reject(e);
        })
    });
}

export function restRequestToBlob(options) {
    const headers = new Headers({
        "Content-Type": "application/json",
    });

    headers.append("Authorization", "Bearer " + getToken());

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);

    return fetch(BASE_URL + options.url, options)
        .then((response) => response.blob())
        .then((blob) => {
            return URL.createObjectURL(blob);
        });
}
