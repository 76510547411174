import React, {useState} from 'react';
import CategoryIcon from '../../category/CategoryIcon';

export default function NewCategoryButton(props) {

    const [hover, setHover] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [show, setShow] = useState(false);

    return (
        <div aria-label={props.category.name}  className="sidebar-button" onFocus={e =>  setHover(true)} onBlur={e =>  setHover(false)} onMouseEnter={(e) => setHover(true)} onMouseLeave={() => setHover(false)}>
            <button aria-label={props.category.name} className={"p-link category-button "+(hover || show ? "hover" : "")} style={{backgroundColor: props.category.color}} onClick={() => {
                if(hover) {
                    setHover(false); 
                    if(!props.disabled)
                        props.onClick()
                }
                else {
                    props.onClick()
                    props.onShow(show ? "" : props.category.name);
                }
                }}>
                <CategoryIcon category={ props.category} size= '30px' />
            </button>
            <div className={"hover-menu "+(hover || show ? "" : "hidden ") + (props.disabled? 'hover-menu-disabled ' : '') + (props.mobileMenuActive? 'mobile-menu-active ' : '')} onClick={() => {
                setHover(false); 
                if(!props.disabled)
                {
                    props.onClick()
                }}}>
                <div className="hover-menu-title top">{props.category.name}</div>
            </div>
        </div>
    )
}
