import React from 'react'

import '../../styles/success/success.css'

export default function SuccessIcon(props) {
    return (
        <div className={"success-checkmark " + props.className}>
            <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
            </div>
        </div>
    )
}
