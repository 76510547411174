import { BASE_URL } from "../Constants";
import { getToken } from "./AuthenticationStore";

export function loginUser(user)
{
    return restRequest(
        {
            url: "/api/authentication/login",
            method: 'POST',
            body: JSON.stringify(user)
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}


export function validateUser(uuid)
{
    return restRequest(
        {
            url: "/api/authentication/validate",
            method: 'POST',
            body: uuid
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function registerNewUser(user)
{
    return restRequestText(
        {
            url: "/api/authentication/register",
            method: 'POST',
            body: JSON.stringify(user)
        }).then(response => {
            return response;
        }).catch(error => {
            return error;
        })
}

export function forgotPassword(mail)
{
    return restRequest(
        {
            url: "/api/authentication/forgotpw",
            method: 'POST',
            body: JSON.stringify({mail: mail})
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function changePassword(body)
{
    return restRequest(
        {
            url: "/api/authentication/changepw",
            method: 'POST',
            body: JSON.stringify(body)
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

/**
 * Special restRequest function for the authentication workflow,
 * since we don't need auth and 403 checks here.
 */
function restRequest(options){
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
    if(getToken() != null)
        headers.append('Authorization', "Bearer " + getToken())
    
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    return fetch(BASE_URL + options.url, options)
    .then(response =>
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

function restRequestText(options){
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
    if(getToken() != null)
        headers.append('Authorization', "Bearer " + getToken())
    
    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);
    return fetch(BASE_URL + options.url, options).then(response => response.text()).catch(error => Promise.reject(error));
};