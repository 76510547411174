import React, {useState, useEffect, useRef} from 'react'
import { AutoComplete } from 'primereact/autocomplete'

import '../../styles/dashboardtoolbar.css';
import { getCachedCategories } from '../../app_gateway/category/CategoryAG';
import { getFromCache } from '../util/Cache';
import { readAllTags } from '../../app_gateway/tags/TagAG';
import Icon from '../util/Icon';
import { mdiCardTextOutline, mdiCat, mdiClose, mdiFolder, mdiFolderOutline, mdiInformationOutline, mdiTag, mdiTagOutline, mdiText, mdiViewDashboard, mdiViewDashboardOutline } from '@mdi/js';
import { getReleasedDashboards } from '../../app_gateway/store/MyDashboardsStore';
import IconButton from '../util/IconButton';

export default function SearchBar(props) {

    const [suggestions, setSuggestions] = useState([])
    const [allSuggestions, setAllSuggestions] = useState([])
    const autoComplete = useRef(null);

    useEffect(() => {
        if(props.mode === 1) {
            getCachedCategories().then(cats => {
                getFromCache("alltags", 5000, readAllTags).then(tags => {
                    let s = [...cats.map(c => {return {type: "c", value: c.name}}), ...tags.map(t => {return {type: "t", value: t}})]
                    setAllSuggestions(s);
                });
            })
        }
        else if(props.mode === 2) {
            getReleasedDashboards().then(db => {
                let tags = [];
                db?.forEach(dashboard => {
                    tags = [...tags, {type: "db", value: dashboard.title}]
                    tags = [...tags, ...dashboard.tags?.map(t => {return {type: "t", value: t}})]
                })
                setAllSuggestions(tags);
            })
        }

        
    }, [props.mode])


    const addNewTag = (event) => {
        let val = event.target.value;
        if(event.key === "Enter" && val !== "" && val.length > 2 && !val.startsWith(" ") && (props.pathid == null || !props.pathid.includes(val)))
        {
            event.target.value = "";
            props.onChange([{type: "ft", value: val}]);
            autoComplete.current.hideOverlay();
        }
    }

    const getValues = () => {
        if(props.pathid)
        {
           let values = props.pathid.split(";").map(a => {
               return {
                   type: a.split(":")[0],
                   value: a.split(":")[1]
               }
           });
           return values;
        }
        return [];
    }


    const onSuggestTags = (event) => {
        let results = allSuggestions.filter((tag) => {
             return tag.value.toLowerCase().startsWith(event.query.toLowerCase()) && props.values.find(v => v.value === tag.value && v.type === tag.type) == null;
        });
        setSuggestions(results);
    }


    const itemTemplate = (e) => {
        return <div className="dashboard-toolbar-suggestion">
            {e.type === "t" &&
                <Icon mdi={{path: mdiTag, color: 'gray', size: '16px', viewBox:"0 -2 24 24"}} />}
            {e.type === "c" &&
                <Icon mdi={{path: mdiFolder, color: 'gray', size: '16px', viewBox:"0 -2 24 24"}} />}
            {e.type === "db" &&
                <Icon mdi={{path: mdiViewDashboard, color: 'gray', size: '16px', viewBox:"0 -2 24 24"}} />}
            {e.value}    
        </div>
    }

    const selectedItemTemplate = (item) => {
        let val = item.value ? item.value : item.type;
        return <div className={"taglist-item " + val}  >
             {item.type === "t" &&
                <Icon mdi={{path: mdiTag, color: 'white', size: '16px', viewBox:"0 -2 24 24"}} />}
            {item.type === "ft" &&
                <Icon mdi={{path: mdiCardTextOutline, color: 'white', size: '16px', viewBox:"0 -2 24 24"}} />}
            {item.type === "c" &&
                <Icon mdi={{path: mdiFolder, color: 'white', size: '16px', viewBox:"0 -2 24 24"}} />}
            {item.type === "db" &&
                <Icon mdi={{path: mdiViewDashboard, color: 'white', size: '16px', viewBox:"0 -2 24 24"}} />}
            {val.replace("%26", "&")}</div>
    }

    const removeValue = (value) => {
        props.onChange(props.values.filter(v => v !== value));
    }

    return (
        <div className="search-bar-root">
            <div className="search-bar-bar">

                <div className="search-bar">
                    <i className="material-icons">search</i>
                    {/* {props.mode === 1 ? <InputText placeholder="Namen oder Tag eingeben" /> :  */}

                    <AutoComplete ref={autoComplete} className="search-page-input" ariaLabelledBy={"Tags eingeben"} placeholder={"Suchbegriff hier eingeben"}
                                appendTo={"self"}  multiple={true} dropdown={true} minLength={0}  value={getValues()} onChange={(e) => {props.onChange([...props.values, ...e.value])}}
                                    suggestions={suggestions} onKeyUp={addNewTag} completeMethod={onSuggestTags} itemTemplate={itemTemplate} selectedItemTemplate={selectedItemTemplate}
                                />
                    {/* } */}
                </div>
            </div>
            <div className="searchbar-values">
                {props.values.map(sv => <div key={sv.type + sv.value} className="searchvalue">
                {sv.type === "t" &&
                    <Icon mdi={{path: mdiTagOutline, color: '#272c2d', size: '16px', viewBox:"0 -2 24 24"}} />}
                {sv.type === "c" &&
                    <Icon mdi={{path: mdiFolderOutline, color: '#272c2d', size: '16px', viewBox:"0 -2 24 24"}} />}
                {sv.type === "ft" &&
                    <Icon mdi={{path: mdiCardTextOutline, color: '#272c2d', size: '16px', viewBox:"0 -2 24 24"}} />}
                {sv.type === "db" &&
                    <Icon mdi={{path: mdiViewDashboardOutline, color: '#272c2d', size: '16px', viewBox:"0 -2 24 24"}} />}
                    <div className="searchvalue-text">{/*l(*/sv.value/*)*/}</div>
                    <div className="searchvalue-close"><IconButton onClick={() => removeValue(sv)} mdi={{path: mdiClose, size:"16px", color: '#272c2d'}} /></div>
                </div> )}
            </div>

        </div>
    )
}
