import React, {useEffect, useState, useRef} from 'react'
import { InputText } from 'primereact/inputtext';
import {Chips} from 'primereact/chips';
import {InputSwitch} from 'primereact/inputswitch';
import { AutoComplete } from 'primereact/autocomplete';

import Icon from '../../util/Icon';
import LinkButton, { ButtonColor } from '../../util/LinkButton';

import '../../../styles/dashboardsettings.css'
import { PATH_DASHBOARD_HOME, PATH_CREATE_DASHBOARD, PATH_DASHBOARD } from '../../main/Routing';
import { getCentralLayout, getLastDashboard } from '../../util/CentralStore';
import { getUser, getUserToken } from '../../../app_gateway/authentication/AuthenticationStore';
import { saveDashboardSettings } from '../../../app_gateway/dashboard/DataAG';
import { l, showGrowlError } from '../../main/Main';
import { mdiInformationOutline } from '@mdi/js';

export default function DashboardSettings(props) {

    const saveButton = useRef(null)
    const catSuggestion = ['Dossier'];
    let user = getUserToken();

    useEffect(() => {
        readMyDashboard();
    }, [props.location.pathname]);

    const [title, setTitle] = useState("");
    const [icon, setIcon] = useState("");
    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [tags, setTags] = useState([]);
    const [editable, setEditable] = useState(true);
    const [release, setRelease] = useState(false);
    const [layout, setLayout] = useState(null);
    const [id, setId] = useState(-1);
    const [catSuggestions, setCatSuggestions] = useState(null);
    

    /*componentDidMount()
    {
    this.readMyDashboard(id);
    }*/

    const readMyDashboard = () =>
    {
        var path = props.location.pathname.split("/");
        var dashboardToRead = path[path.length-1];
        
        if(dashboardToRead === "new")
        {
            setTitle(l("Neues Dashboard erstellen"))
            setIcon("add")
        }
        else if(dashboardToRead === "save")
        {
            setTitle(l("Neues Dashboard erstellen"))
            setIcon("add")
            if(getCentralLayout() !== null)
            {
                setLayout(getCentralLayout().lg.map((r, i) => {return {
                    x: r.x,
                    y: r.y,
                    width: r.w,
                    height: r.h,
                    tile: {
                        id: r.i
                    }
                }}));
            }
            else{
                window.location = '#' + PATH_CREATE_DASHBOARD;
            }
            if(getLastDashboard() && getLastDashboard().owner.id === user.id)
            {
                setTitle(l("Dashboard speichern"))
                setIcon("save")
                var db = getLastDashboard();
                setName(db.title);
                setCategory(db.category);
                setTags(db.tags);
                setId(db.id);
                setEditable(db.editable);
                setRelease(db.visibility === "SHARED");
            }
        }
        else
        {
            setTitle(l("Einstellungen"))
            setIcon("settings")
        }
    }

    const confirmSave = () => {
      if(name == null || name.trim() === "")
      {
          showGrowlError("Fehler", "Es muss ein Name angegeben werden."); 
          return false;
      }
      return true;
    }

    const saveDashboard = () => {

        if(confirmSave())
        {
            var dashboard = {};
            dashboard.id = id;
            dashboard.title = name;
            dashboard.category = category;
            dashboard.tags = tags;
            dashboard.layout = layout;
            dashboard.editable = editable;
            dashboard.visibility = release ? "SHARED" : "PERSONAL";
            if(user !== null)
                dashboard.owner = {id: user.id};
    
            saveDashboardSettings(dashboard, () => {
                if (saveButton.current) {
                    saveButton.current.redirectTo(PATH_DASHBOARD_HOME);
                }
            }).then(dashboard => 
                {
                    if(dashboard && dashboard.id)
                    window.location = '#' + PATH_DASHBOARD + "/" + dashboard.id;
                });
        }

    }

    const checkTags = (tags) => {
        return  [...new Set(tags)];
    }

    const suggestCategory = (event) => {
        let result = catSuggestion.filter((cat) => {
             return cat.toLowerCase().startsWith(event.query.toLowerCase());
        });
    
        setCatSuggestions(result);
    }


    return (
        <div className="db-settings-root">
            <div className="db-settings-content">
                <div className="db-settings-title">
                    {title}
                    <Icon  icon={icon} />
                </div>
                <div className="db-settings-items">
                    <div className="p-grid">
                        <div className="p-col-fixed label">{l("Name")}</div>
                        <div className="p-col value"><InputText style={{width: '450px'}} value={name} maxLength={25} onChange={e => setName(e.target.value)} /></div>
                        <div className="p-col db-settings-items-info">
                            <Icon mdi={{path: mdiInformationOutline, color: '#444', size: '18px'}} />
                            Vergeben Sie eine Bezeichnung für das erstellte Dashboard.
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-fixed label">{l("Kategorie")}</div>
                        <div className="p-col value"><InputText className="dashboard-settings-category" style={{width: '450px'}} value={category} maxLength={15} size={15} onChange={(e) => setCategory(e.target.value)} /></div>
                        <div className="p-col db-settings-items-info">
                            <Icon mdi={{path: mdiInformationOutline, color: '#444', size: '18px'}} />
                            Erstellte Dashboards können in verschiedene Kategorien zusammengefasst werden. Vergeben Sie ggf. einen Kategorie-Namen.
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-fixed label">{l("Tags")}</div>
                        <div className="p-col value"><Chips style={{width: '450px'}} value={tags} max={5} onChange={(e) => setTags(checkTags(e.value))} /></div>
                        <div className="p-col db-settings-items-info">
                            <Icon mdi={{path: mdiInformationOutline, color: '#444', size: '18px'}} />
                            Tags (Stichwörter) können vergeben werden, um ein freigegebenes Dashboard für andere Benutzer/-innen zu beschreiben und leichter auffindbar zu machen.<br/>Die Eingabe eines Tags muss mit der Enter-Taste bestätigt werden.
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-fixed label">{l("Editierbar")}</div>
                        <div className="p-col value"><InputSwitch checked={editable} onChange={(e) =>setEditable(e.value)} /></div>
                        <div className="p-col db-settings-items-info">
                            <Icon mdi={{path: mdiInformationOutline, color: '#444', size: '18px'}} />
                            Ist ein Dashboard editierbar, kann es bearbeitet und angepasst werden.<br />Beachten Sie, falls Ihr Dashboard freigegeben ist, können auch andere Benutzer/-innen das Dashboard bearbeiten.
                        </div>
                    </div>
                    {getUser() !== null && getUser().mail.includes("@destatis") ? 
                    <div className="p-grid">
                        <div className="p-col-fixed label">{l("Freigeben")}</div>
                        <div className="p-col value"><InputSwitch checked={release} onChange={(e) =>setRelease(e.value)} /></div>
                        <div className="p-col db-settings-items-info">
                            <Icon mdi={{path: mdiInformationOutline, color: '#444', size: '18px'}} />
                            Ein erstelltes Dashboard kann "freigegeben" werden. Somit können andere Benutzer/-innen Ihr Dashboard über „Freigegebene Dashboards suchen“ finden und aufrufen.
                        </div>
                    </div> : null}
                </div>
                <div className="db-settings-buttons">
                    <LinkButton ref={saveButton} label={l("Dashboard speichern")} color={ButtonColor.blue} icon="pi-md-save" manualRedirect={true} onClick={() => saveDashboard()}/>
                </div>
            </div>
        </div>
    )
}
