const PubSub = require('pubsub-js');

const CHANGELANGUAGE = "dashboard.changeLanguage"

export function onChangeLanguage(func)
{
    PubSub.subscribe(CHANGELANGUAGE, func);
}

export function stopOnChangeLanguage()
{
    PubSub.unsubscribe(CHANGELANGUAGE);
}

export function fireChangeLanguage(lang)
{
    PubSub.publish(CHANGELANGUAGE, lang);
}