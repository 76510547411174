import { restRequest } from "../RestRequest";

export function readCategories()
{
    return restRequest(
        {
            url: "/api/tags/categories",
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readAllTags()
{
    return restRequest(
        {
            url: "/api/tags/getall",
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readAllTilesFromTags(tags)
{
    return restRequest(
        {
            url: "/api/tags/tiles?tags="+tags,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}
