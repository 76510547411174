export const CACHE_CATEGORIES = "db-cache.categories";

export function getFromCache(cache, timeout, fkt, ...args) {
  var cached = sessionStorageGet(cache);
  if (cached && cached.data) {
    if (cached.timestamp + timeout > Date.now()) {
      return new Promise((resolve, reject) => {
        resolve(cached.data);
      });
    }
  }
  return new Promise((resolve, reject) => {
    fkt(args)
      .then((r) => {
        sessionStorageSet(cache, { timestamp: Date.now(), data: r });
        resolve(r);
      })
      .catch((err) => err);
  });
}

export function removeFromCache(cache) {
  sessionStorageSet(cache, undefined);
}

export function localStorageSet(cache, obj) {
  localStorage.setItem(cache, JSON.stringify(obj));
}

export function localStorageGet(cache) {
  try {
    return JSON.parse(localStorage.getItem(cache));
  } catch (e) {
    localStorage.removeItem(cache);
    return null;
  }
}

export function sessionStorageSet(cache, obj) {
  sessionStorage.setItem(cache, JSON.stringify(obj));
}

export function sessionStorageGet(cache) {
  try {
    return JSON.parse(sessionStorage.getItem(cache));
  } catch (e) {
    sessionStorage.removeItem(cache);
    return null;
  }
}

/*export function sessionStorageGet(key) {
  const item = sessionStorage.getItem(key);
  if (item !== null) {
    return JSON.parse(item);
  }
  sessionStorage.removeItem(key);
  return null;
} */
