import React, {useState, useEffect} from 'react'

import {readTile} from '../../app_gateway/dashboardtile/TileAG';
import DashboardTile from '../dashboard/DashboardTile';
import RGL, { WidthProvider } from "react-grid-layout";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "../../styles/dashboard.css";
import Layout from './Layout';

const ReactGridLayout = WidthProvider(RGL);

export default function TileOnlyDashboard(props) 
{
    const [cards, setCards] = useState(null);
    
    useEffect(() => {
        readMyTile();
        if(props.innerContent !== null)
          props.innerContent.scrollTop = 0;
    }, [props.location.pathname])
    
    const readMyTile = () =>
    {
        var tileToRead = props.match.params.id
    
        readTile(tileToRead).then(response => {
            createTile(response)
        });
    }
    
    const getValidValue = (value, defaultVal) =>
    {
        if(value > 0)
        return value;
        return defaultVal;
    }
    
    const createTile = (tile) => 
    {
        var c = <div className="fadeIn" key={tile.id}
        data-grid={{ 
        x: 0, 
        y: 0, 
        w: getValidValue(tile.width, 3), 
        h: getValidValue(tile.height, 3), 
        minW: getValidValue(tile.minWidth, undefined), 
        minH: getValidValue(tile.minHeight, undefined), 
        maxW: getValidValue(tile.maxWidth, undefined), 
        maxH: getValidValue(tile.maxHeight, undefined), 
        static: false }}
        style={{zIndex:'-1'}}>
            <DashboardTile id={tile.id} layout={tile.dashboardFormat} attachments={tile.attachments} date={tile.date} enableNotification/>
        </div>;
        setCards(c);
        
    }
    
    const onLayoutChange = (layout) => {
        window.dispatchEvent(new Event('resize'));
    }

    return (
        <ReactGridLayout className={"layout"} style={{zIndex:'0'}}
            onLayoutChange={onLayoutChange}
            rowHeight={Layout.rowHeight}
            cols={Layout.layout.lg}
            draggableHandle=".sticky-header"
            draggableCancel={[".card-title-functions", ".card-title-category-icon"]}

            {...props}
        >
            {cards}
        </ReactGridLayout>
        );
}