import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD_HOME } from '../main/Routing';
import { ONLY_WITH_LOGIN } from '../../app_gateway/Constants';

export default function Validation(props) {

    const [closeLogin, setCloseLogin] = useState(false);

    const closeHandler = () => {
        setCloseLogin(true);
    }

    useEffect(() => {
        var type = props.match.params.type;
        let split = type.split(":");
        if(!ONLY_WITH_LOGIN)
            props.onShowLogin(closeHandler, {[split[0]]:split[1]})
    }, [])

    return (
        closeLogin ? 
            <Redirect to={PATH_DASHBOARD_HOME} />
        :
        <div></div>
    )
}
