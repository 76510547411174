import { getFromCache, removeFromCache } from "../../components/util/Cache";
import { restRequest } from "../RestRequest";

const cachedCategories = "app.categories.cache";
export const eventCacheCategories = "cache.category";

export function deleteCachedCategories() {
  removeFromCache(cachedCategories);
}
export function getCachedCategories() {
  return getFromCache(cachedCategories, 600000, getAllCategories);
}

export function getAllCategories() {
  return restRequest({
    url: "/api/category/getall",
    method: "GET",
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export function saveAllCategories(categories) {
  removeFromCache(cachedCategories);
  return restRequest({
    url: "/api/category/saveall",
    method: "POST",
    body: JSON.stringify(categories),
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
}
