import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React, { useRef, useState } from "react";
import { Route, Switch, useLocation, withRouter } from "react-router-dom";
import { getUser } from "../../app_gateway/authentication/AuthenticationStore";
import { HOME_DASHBOARD, IS_FOR_COCKPIT_VERSION, ONLY_WITH_LOGIN } from "../../app_gateway/Constants";
import Category from "../category/Category";
import CategoryOverview from "../category/CategoryOverview";
import Dashboard from "../dashboard/Dashboard";
import DashboardAddTile from "../dashboard/DashboardAddTile";
import MyDashboards from "../dashboard/MyDashboards";
import DashboardSettings from "../dashboard/settings/DashboardSettings";
import TagsDashboard from "../dashboard/tags/TagsDashboard";
import TileOnlyDashboard from "../dashboard/TileOnlyDashboard";
import MyPDFViewer from "../fileviewer/MyPDFViewer";
import Login from "../login/Login";
import Validation from "../login/Validation";
import DashboardSearch from "../newsearch/DashboardSearch";
import PrintPreview from "../printing/PrintPreview";
import PrintSelection from "../printing/PrintSelection";
import Datenschutz from "../privacy/Datenschutz";
import Impressum from "../privacy/Impressum";
import Conditional from "../util/Conditional";
import Footer from "./Footer";
import Root from "./Root";
import Sidebar from "./sidebar/Sidebar";
import Topbar from "./Topbar";

export const PATHNAME_DASHBOARD = "dashboard";
export const PATHNAME_MYDASHBOARDS = "mydashboards";
export const PATHNAME_SEARCH = "search";
export const PATHNAME_NEW = "new";
export const PATHNAME_DASHBOARD_SETTINGS = "settings";
export const PATHNAME_TAGS = "tags";

export const PATH_ROOT = "/";
export const PATH_LOGIN = "/login";
export const PATH_DASHBOARD = "/" + PATHNAME_DASHBOARD;
export const PATH_MYDASHBOARDS = "/" + PATHNAME_MYDASHBOARDS;
export const PATH_DASHBOARD_HOME = "/" + PATHNAME_TAGS + "/" + HOME_DASHBOARD;
export const PATH_SEARCH_DASHBOARD = "/" + PATHNAME_SEARCH;
export const PATH_NEW = "/" + PATHNAME_NEW;
export const PATH_TAGS = "/" + PATHNAME_TAGS;
export const PATH_ADD_DASHBOARD_TILE = "/addTile";
export const PATH_DASHBOARD_SETTINGS = "/" + PATHNAME_DASHBOARD_SETTINGS;
export const PATH_CREATE_DASHBOARD = PATH_DASHBOARD_SETTINGS + "/new";
export const PATH_CREATE_EXISTING_DASHBOARD = PATH_DASHBOARD_SETTINGS + "/save";
export const PATH_ADMINIZE = "/adminize";

export const PATH_TILEONLY = "/tile";
export const PATH_PRINTVIEW = "/printview";
export const PATH_PRINTSELECTION = "/printselection";
export const PATH_PDFVIEWER = "/viewerpdf";
export const PATH_VALIDATEUSER = "/usr/v";
export const PATH_ADMINIZE_CATEGORY = PATH_ADMINIZE + "/category";
export const PATH_CATEGORY_OVERVIEW = "/categories";

const PubSub = require("pubsub-js");

const RouterTopbar = withRouter((props) => <Topbar {...props} />);
const RouterSidebar = withRouter((props) => <Sidebar {...props} />);
const RouterDashboard = withRouter((props) => <Dashboard {...props} />);
const RouterTileOnlyDashboard = withRouter((props) => <TileOnlyDashboard {...props} />);
const RouterDashboardSettings = withRouter((props) => <DashboardSettings {...props} />);
const RouterTagsDashboard = withRouter((props) => <TagsDashboard {...props} />);
const RouterPDFViewer = withRouter((props) => <MyPDFViewer {...props} />);
const RouterPrintPreview = withRouter((props) => <PrintPreview {...props} />);
const RouterPrintSelection = withRouter((props) => <PrintSelection {...props} />);
const RouterValidation = withRouter((props) => <Validation {...props} />);

/**
 * Diese Komponente führt das Routing durch.
 */
export default withRouter((props) => {
    const [layout, setLayout] = useState(null);
    const innerContent = useRef(null);
    const [user, setUser] = useState(getUser());
    const location = useLocation();
    const { trackPageView } = useMatomo();

    /**
     * Enable very basic tracking (just page events),
     * so that we have at least some way to see how much traffic we get.
     */
    React.useEffect(() => {
        trackPageView({
            documentTitle: location.pathname, // optional
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const hideSidebar = (e) => {
        if (props.mobileMenuActive) {
            props.onToggleSidebar();
        }
    };

    return (
        <Conditional
            if={ONLY_WITH_LOGIN && user === null}
            then={
                <Login
                    url={props.location.pathname}
                    onClose={() => {
                        setUser(getUser());
                    }}
                    onLogin={() => {
                        setUser(getUser());
                    }}
                />
            }
            else={
                <>
                    {!IS_FOR_COCKPIT_VERSION && <RouterTopbar onToggleSidebar={props.onToggleSidebar} />}
                    {!IS_FOR_COCKPIT_VERSION && (
                        <RouterSidebar
                            className={props.mobileMenuActive ? "sidebar-mobile-active " : ""}
                            mobileMenuActive={props.mobileMenuActive}
                            onToggleSidebar={(e) => hideSidebar(e)}
                            onShowLogin={() => props.onShowLogin()}
                            layout={layout}
                            onLogoff={() => setUser(getUser())}
                        />
                    )}
                    <div
                        className={
                            "content-full " +
                            (props.mobileMenuActive ? "content-sidebar-mobile-active" : "") +
                            (IS_FOR_COCKPIT_VERSION ? " cockpit-version" : "")
                        }
                    >
                        <div
                            className={props.mobileMenuActive ? "overlay-sidebar-mobile-active" : ""}
                            onClick={hideSidebar}
                        ></div>
                        <div
                            aria-label={"Inhalt"}
                            onScroll={() => PubSub.publish("scroll")}
                            ref={innerContent}
                            className={"inner-content" + (IS_FOR_COCKPIT_VERSION ? " cockpit-version" : "")}
                        >
                            <Switch>
                                <Route exact path={PATH_ROOT}>
                                    <Root />{" "}
                                </Route>
                                <Route exact path={PATH_DASHBOARD + "/:id"}>
                                    <RouterDashboard
                                        getLayout={(e) => setLayout(e)}
                                        innerContent={innerContent.current}
                                    />{" "}
                                </Route>
                                <Route exact path={PATH_TILEONLY + "/:id"}>
                                    <RouterTileOnlyDashboard innerContent={innerContent.current} />{" "}
                                </Route>
                                <Route exact path={PATH_DASHBOARD_SETTINGS + "/:id"}>
                                    <RouterDashboardSettings />{" "}
                                </Route>
                                <Route exact path={PATH_TAGS + "/:id"}>
                                    <RouterTagsDashboard
                                        getLayout={(e) => setLayout(e)}
                                        innerContent={innerContent.current}
                                    />{" "}
                                </Route>
                                <Route exact path={PATH_NEW}>
                                    <RouterTagsDashboard
                                        getLayout={(e) => setLayout(e)}
                                        innerContent={innerContent.current}
                                        mode_new
                                    />{" "}
                                </Route>
                                <Route exact path={PATH_PDFVIEWER + "/:id"}>
                                    <RouterPDFViewer />{" "}
                                </Route>
                                <Route exact path={PATH_TAGS}>
                                    <RouterTagsDashboard
                                        getLayout={(e) => setLayout(e)}
                                        innerContent={innerContent.current}
                                    />{" "}
                                </Route>
                                <Route exact path={PATH_SEARCH_DASHBOARD}>
                                    <DashboardSearch />{" "}
                                </Route>
                                <Route exact path={PATH_MYDASHBOARDS}>
                                    <MyDashboards />{" "}
                                </Route>
                                <Route exact path={PATH_ADD_DASHBOARD_TILE}>
                                    <DashboardAddTile />{" "}
                                </Route>
                                <Route exact path={PATH_PRINTVIEW + "/:id"}>
                                    <RouterPrintPreview />{" "}
                                </Route>
                                <Route exact path={PATH_PRINTSELECTION}>
                                    <RouterPrintSelection />{" "}
                                </Route>
                                <Route exact path={PATH_ADMINIZE_CATEGORY}>
                                    <Category />{" "}
                                </Route>
                                <Route exact path={PATH_CATEGORY_OVERVIEW}>
                                    <CategoryOverview />{" "}
                                </Route>
                                <Route exact path={PATH_VALIDATEUSER + "/:type"}>
                                    <RouterValidation
                                        onShowLogin={(closeHandler, uuid) => props.onShowLogin(closeHandler, uuid)}
                                    />{" "}
                                </Route>
                                <Route exact path={"/impressum"}>
                                    <Impressum
                                        onClickDatenschutz={() => (window.location = "#/datenschutz")}
                                        parentRef={innerContent}
                                    />
                                </Route>
                                <Route exact path={"/datenschutz"}>
                                    <Datenschutz parentRef={innerContent} />
                                </Route>
                            </Switch>

                            <Footer />
                        </div>
                    </div>
                </>
            }
        />
    );
    // eslint-disable-next-line
});
