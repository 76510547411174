import React from 'react';

import '../../styles/loading.css';

export default function Loading(props){

    var small = props.small ? "-small" : props.medium ?  "-medium" : ""
    var style = {};
    if(props.background)
        style = {...style, background: props.background }

    return (
        <div className={"destatis-loading-bar-container"+ (props.absoluteCenter ? " destatis-loading-bar-center" : "") + (props.horizontal ? " destatis-loading-bar-horizontal" : "")}>
            <div className={"destatis-loading-bar-anim"+small}>
                <div className={"destatis-loading-bar"+small}>
                    <div>
                        <div style={style}></div>
                        <div style={style}></div>
                        <div style={style}></div>
                    </div>
            </div>
        </div>
        {props.title === null ? null : <div className={"destatis-loading-bar-titel"+small + (props.horizontal ? " destatis-loading-bar-titel-horizontal" : "")}>{props.title ? props.title : "Daten werden geladen..."}</div>}
        </div>
    )
}