import React, {useEffect, useState, useRef, useLayoutEffect} from 'react'
import CardHeader from './cardheader/CardHeader'
import {ProgressSpinner} from 'primereact/progressspinner';

import {readData} from '../../app_gateway/dashboard/DataAG';
import UIConstants from '../util/UIConstants';
import {eventCacheCategories} from '../../app_gateway/category/CategoryAG';
import {readAuditData, readTile} from '../../app_gateway/dashboardtile/TileAG';
import ReactResizeDetector from 'react-resize-detector';
import '../../styles/dashboardtile.css';
import { DetailTile, TileHeader } from "@dd/components"
import DashboardTileSource from "./DashboardTileSource";

const PubSub = require("pubsub-js");

const cannotPrintComponents = ["iframe"]

/**
 * Diese Komponente repräsentiert die Startseite der DesignerApp
 */
export default (props) => {

    const [data, setData] = useState(null);
    const [cat, setCat] = useState(null);
    const [setTile] = useState(null);
    const [auditedData, setAuditedData] = useState(new Map());
    const [notAllowedLabel, setNotAllowedLabel] = useState(false);
    const [date, setDate] = useState(props.date);
    const [hideComponents, setHideComponents] = useState([])
    const [width, setWidth] = useState(0);
    const header = useRef(null);
    const rootRef = useRef(null);

    useEffect(() => {
        PubSub.subscribe(props.id.toLowerCase(), () => {
            setData(null)
            setTimeout(() => {
                readTile(props.id).then(tile => {
                    setTile(tile);
                    readData(props.id).then(d => {
                        checkPrintable(d);
                        setData(d)
                        doSetAuditedData("now", d);
                    });
                })
            }, 0);
        });
        PubSub.subscribe(eventCacheCategories, () => {
            readData(props.id).then(d => {
                UIConstants.getCategory(d.category).then(c => {
                    setCat(c)
                });
                checkPrintable(d);
                setData(d)
                doSetAuditedData("now", d);
            });
        });
        let token = PubSub.subscribe("scroll", () => {
            if (isInViewport(200)) {
                setTimeout(load, 0);
                PubSub.unsubscribe(token);
            }
        });

        if (isInViewport(200)) {
            setTimeout(load, 0);
            PubSub.unsubscribe(token);
        }

        return () => {
            PubSub.unsubscribe(props.id.toLowerCase());
            PubSub.unsubscribe(eventCacheCategories);
            PubSub.unsubscribe("scroll");
        }
    }, [])

    const doSetAuditedData = (date, data) => {
        let tmp = auditedData;
        tmp.set(date, data);
        setAuditedData(tmp);
    }

    /**
     * Overrides the fit-content for proper sizing on the print layout
     */
    function setParentDivHeight() {
        if(!props.isOnPrintView) {
            return
        }
        const parentDiv = document.getElementById(props.id)
        if(!parentDiv) {
            return
        }
        parentDiv.style.height = '100%'
    }

    useLayoutEffect(() => {
        setParentDivHeight()
    })

    useEffect(() => {
        setHideComponents(props.hideComponents != null ? props.hideComponents : []);
    }, [props.hideComponents])

    const isInViewport = (offset = 0) => {
        if (props.instantLoad) return true;
        if (!rootRef.current) return false;
        const top = rootRef.current.getBoundingClientRect().top;
        const height = rootRef.current.getBoundingClientRect().height;
        return (top + height) >= 0 && (top - offset) <= window.innerHeight;
    }

    const checkPrintable = (tile) => {
        if (props.onCannotPrintTile) {
            if (tile.components.filter(c => cannotPrintComponents.includes(c.type)).length > 0) {
                props.onCannotPrintTile();
            }
        }
    }

    const load = () => {
        if (props.layout === undefined || props.layout === null)
            readData(props.id).then(d => {
                UIConstants.getCategory(d.category).then(c => {
                    setCat(c)
                });

                checkPrintable(d);
                setData(d)
                doSetAuditedData("now", d);
                if (props.onLoad) props.onLoad(props.id);
            });
        else {
            let d = JSON.parse(props.layout);
            UIConstants.getCategory(d.category).then(c => {
                setCat(c)
            });

            checkPrintable(d);
            setData(d);
            doSetAuditedData("now", d);
            if (props.onLoad) props.onLoad(props.id);
        }
    }

    const showNotAllowedLabel = async () => {
        setNotAllowedLabel(true);
        setTimeout(() => {
            setNotAllowedLabel(false);
        }, 3000);
    }

    const showHideComponent = (component) => {
        if (!component.show && hideComponents.length + 1 === data.components.length) {
            showNotAllowedLabel();
            return false;
        }
        if (component.show) {
            const newhideComponents = hideComponents.filter(hc => hc.id !== component.id)
            setHideComponents(newhideComponents);
            if (props.onHideComponents != null) props.onHideComponents(newhideComponents);
        } else {
            let hc = [...hideComponents, component];
            setHideComponents(hc);
            if (props.onHideComponents != null) props.onHideComponents(hc);
        }
        return true;
    }

    const onlyTextComponents = () => {
        let found = data.components.find(c => c.type !== "text");
        return found == null;
    }

    const onChangeDate = (auditedTile) => {
        let dataToLoad = auditedData.get(auditedTile.id);
        if (dataToLoad == null) {
            readAuditData(auditedTile.id).then(response => {
                doSetAuditedData(auditedTile.id, response);
                setData(response);
            });
        } else {
            setData(dataToLoad);
        }
        setDate(auditedTile.id === "now" ? props.date : auditedTile.date);
    }

    useEffect(() => {
        if (props.hideAllTexts) {
            if (data != null && data.components != null && !onlyTextComponents()) {
                let components = data.components.map((c, i) => {
                    if (c.type === "text")
                        return {
                            show: false,
                            comp: c,
                            id: i
                        }
                    else return null;
                });
                components = components.filter(c => c != null);
                let hc = [...hideComponents, ...components]
                setHideComponents(hc)
                if (props.onHideComponents != null) props.onHideComponents(hc);
            }
        } else {
            let hc = hideComponents.filter(c => c.comp.type !== "text");
            setHideComponents(hc);
            if (props.onHideComponents != null) props.onHideComponents(hc);
        }
    }, [props.hideAllTexts, data])

    if (props.onlyPrintable && data != null && data.components.find(c => c.type === "iframe") != null) {
        return null;
    }
    if (data === undefined || data === null || data.components == null) {
        return (
            <div ref={rootRef} className="process-spinner innershadow fadeIn">
                <div><ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE"
                                      animationDuration="1.5s"/>
                    <div>Lade Daten...</div>
                </div>
            </div>
        );
    } else {

        let rows = new Array(data.rows);
        for (let i = 0; i < data.rows; i++) {
            for (let b = 0; b < data.components.length; b++) {
                let component = data.components[b];

                if (component.row === i) {
                    let row = rows[i];
                    if (row === undefined || row === null)
                        row = new Array(data.cols);
                    row[component.col] = component;
                    rows[i] = row;
                }
            }
        }

        return (
            <ReactResizeDetector handleHeight={false} handleWidth={true} onResize={(width, height) => {
                setWidth(width)
                window.dispatchEvent(new Event('resize'));
            }}>
                <div id={props.id} className="card fadeIn" style={{
                    padding: "0px",
                    display: 'flex',
                    flexDirection: 'column',
                    /**
                     * The fit content is required for calculating the correct size of the card.
                     * After it is rendered it is overwritten by the useLayoutEffect to streche it again to 100%
                     */
                    height: props.isOnPrintView ? 'fit-content' : '100%',
                }}>
                    <CardHeader ref={header} id={props.id + "-title"} title={data.title} audited={props.audited}
                                onChangeDate={date => onChangeDate(date)} tileid={data.id} source={data.source}
                                onClose={props.onClose ? () => props.onClose(props.id) : null}
                                link={data.link} attachments={props.attachments} category={cat}
                                enableNotification={props.enableNotification}
                                components={props.viewOptions === true ? data.components : null}
                                hideComponents={hideComponents} onShowHideComponent={(c) => {
                        return showHideComponent(c)
                    }}/>
                        <div ref={r => {if(props.gridRef != null) props.gridRef(r)}} className="tile-grid" style={{
                            gridTemplateColumns: `max(${width - 15}px)`,
                            gridTemplateRows: props.isOnPrintView? 'max-content': '100px',
                            gridAutoRows: props.isOnPrintView? '100%': '100px',
                            overflowY: 'auto',
                            flexGrow: '1',
                            height: props.isOnPrintView? 'fit-content': false
                        }}>
                            <DetailTile
                                style={{
                                    padding: 0,
                                    border: "none",
                                    height: props.isOnPrintView? 'fit-content': false
                                }}
                                embedSnippet="testString"
                                tileData={data}
                                customHeader={<TileHeader title="" subTitle={data.introtext} />}
                                customFooter={<></>}
                            />
                        </div>
                    <DashboardTileSource source={data.source} date={date} sources={data.sources} dataVersionDate={data.dataVersionDate}/>

                    {notAllowedLabel &&
                        <div className="notAllowedLabel fadeIn" onClick={() => setNotAllowedLabel(false)}>
                            <div className="notAllowedLabelText">
                                Es können nicht alle<br/>Komponenten ausgeblendet werden.
                            </div>
                        </div>}
                </div>
            </ReactResizeDetector>
        );
    }
}
