import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { Toast } from "primereact/toast";
import { useEffect, useState } from "react";
import { HashRouter } from "react-router-dom";
import { BASE_URL, SSE_PATH } from "../../app_gateway/Constants";
import "../../styles/animation.css";
import "../../styles/main.css";
import "../../styles/toast.css";
import Login from "../login/Login";
import TooltipComponent from "../util/TooltipComponent";
import Routing from "./Routing";
//import { useTranslation } from "react-i18next";

const PubSub = require("pubsub-js");

export const UPDATETILE = "UPDATETILE:";
export var growl;
let eventSource = undefined;

export var l;
export var lang;

const MATOMO_URL = process.env.REACT_APP_MATOMO_URL || "";
const MATOMO_SITE_ID = process.env.REACT_APP_MATOMO_SITE_ID || "";

const instance = createInstance({
    urlBase: MATOMO_URL,
    siteId: MATOMO_SITE_ID,
});

const Main = () => {
    const [loginVisible, setLoginVisible] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [rerender, setRerender] = useState(0);
    const [listening, setListening] = useState(false);
    const [loginCloseHandler, setLoginCloseHandler] = useState(null);
    const [loginParams, setLoginParams] = useState(false);
    const [tooltip, setTooltip] = useState(null);
    //const { t, i18n } = useTranslation();

    l = (t) => {
        return t;
    };

    const showLogin = (loginCloseHandler, loginParams) => {
        setLoginCloseHandler(loginCloseHandler ? loginCloseHandler : null);
        setLoginParams(loginParams ? loginParams : null);
        setLoginVisible(!loginVisible);
    };

    const toggleSidebar = () => {
        setMobileMenuActive(!mobileMenuActive);
    };

    useEffect(() => {
        // connectToEventSource(0);
        PubSub.subscribe("global.tooltip", (i, tt) => {
            setTooltip(tt);
        });
        return () => {
            // eventSource.close();
        };
    }, []);

    const connectToEventSource = () => {
        if (!listening) {
            try {
                eventSource = new EventSource(BASE_URL + SSE_PATH);
                eventSource.onmessage = (event) => {
                    PubSub.publish(event.data, null);
                };
                eventSource.onerror = (err) => {
                    eventSource.close();
                    console.warn("EventSource failed, Retry in 5s");
                    setTimeout(() => connectToEventSource(), 5000);
                };
                setListening(true);
            } catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <MatomoProvider value={instance}>
            <div aria-label={"Dashboard Deutschland"} className="app-root" key={rerender}>
                <div
                    aria-label={"Dashboard Deutschland"}
                    className={"main " + (loginVisible ? "blur" : "") + (mobileMenuActive ? "overflow-hidden " : "")}
                >
                    <Toast aria-label={"Benachrichtigung"} ref={(el) => (growl = el)} />

                    <HashRouter>
                        <Routing
                            onToggleSidebar={toggleSidebar}
                            mobileMenuActive={mobileMenuActive}
                            onShowLogin={showLogin}
                        />
                    </HashRouter>
                </div>
                <div className={"main-overlay " + (!loginVisible ? "hidden" : "")} />

                {loginVisible ? (
                    <div className={"login-parent " + (loginVisible ? "" : "hidden")}>
                        <Login
                            loginParams={loginParams}
                            onClose={() => {
                                setLoginVisible(false);
                                setRerender(rerender + 1);
                                if (loginCloseHandler) loginCloseHandler();
                            }}
                            onLogin={() => {
                                setLoginVisible(false);
                            }}
                        />
                    </div>
                ) : null}

                {tooltip ? <TooltipComponent {...tooltip} /> : null}
            </div>
        </MatomoProvider>
    );
};

/**
 * Zeigt eine Growl-Success Nachricht an.
 *
 * @param {String} title Der Titel.
 * @param {String} msg Die Nachricht.
 */
export const showGrowlSuccess = (title, msg) => {
    growl.show({ severity: "success", summary: title, detail: msg, life: 5000 });
};

/**
 * Zeigt eine Growl-Info Nachricht an.
 *
 * @param {String} title Der Titel.
 * @param {String} msg Die Nachricht.
 */
export const showGrowlInfo = (title, msg) => {
    growl.show({ severity: "info", summary: title, detail: msg, life: 5000 });
};

/**
 * Zeigt eine Growl-Warning Nachricht an.
 *
 * @param {String} title Der Titel.
 * @param {String} msg Die Nachricht.
 */
export const showGrowlWarn = (title, msg) => {
    growl.show({ severity: "warn", summary: title, detail: msg, life: 5000 });
};

/**
 * Zeigt eine Growl-Error Nachricht an.
 *
 * @param {String} title Der Titel.
 * @param {String} msg Die Nachricht.
 */
export const showGrowlError = (title, msg) => {
    growl.show({ severity: "error", summary: title, detail: msg, life: 5000 });
};

/**
 * Zeigt eine Growl Nachricht an.
 *
 * @param {*} obj Die Nachricht.
 * @example
 * severity	string	"Severity of the message".
 * summary	element	"Summary content of the message".
 * detail	element	"Detail content of the message".
 * closable	boolean	"Whether the message can be closed manually using the close icon".
 * sticky	element	"When enabled, message is not removed automatically".
 * life	number	"Delay in milliseconds to close the message automatically".
 */
export const showGrowl = (obj) => {
    growl.show(obj);
};

export default Main;
