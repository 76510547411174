import React from 'react'
import { VERSION } from '../../app_gateway/Constants'
//import { lang, l } from './Main';
//import { fireChangeLanguage } from '../util/OnChangeLanguage';
//import Tooltip from '../util/Tooltip';
//import GermanyIcon from '../util/localeicons/GermanyIcon';
//import EnglishIcon from '../util/localeicons/EnglishIcon';

//const supporedLanguages = ['de', 'en'];

export default function Footer() {


    /*const changeLanguage = () => {
        var index = supporedLanguages.indexOf(lang.language);
        index = index+1;
        if(index > supporedLanguages.length - 1)
            index = 0;
        lang.changeLanguage(supporedLanguages[index]);
        window.location = "#";
        fireChangeLanguage(supporedLanguages[index]);
    };*/

    return (
        <div aria-label={"Dashboard Deutschland v." + VERSION + " Statistisches Bundesamt"} className="version-number">
            <div style={{width: '100px'}} />
            <div className="impressumdatenschutz">
                <button className="link-button" onClick={() => window.location="#/impressum"}>Impressum</button><button className="link-button" onClick={() => window.location="#/datenschutz"}>Datenschutzerklärung</button>
            </div>
            <div>Dashboard Deutschland v.{VERSION} &copy; Statistisches Bundesamt</div>
            {/*<Tooltip label={l("Sprache ändern")} top>
                <div className="lang-switcher" onClick={() => {
                    changeLanguage();
                    }} tabIndex={0} aria-label="Sprache">
                {
                    lang.language === "de" ? <GermanyIcon /> : lang.language === "en" ? <EnglishIcon  /> : <GermanyIcon  />
                } 
                </div>
            </Tooltip>*/} <div />
        </div>
    )
}
