import { restRequest, restRequestToBlob } from "../RestRequest";

export function readFile(id)
{
    return restRequestToBlob(
        {
            url: "/api/attachments/get?id="+id,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}

export function readMeta(id)
{
    return restRequest(
        {
            url: "/api/attachments/getMeta?id="+id,
            method: 'GET'
        }).then(response => {
            return response;
        }).catch(error => {
            return false;
        })
}